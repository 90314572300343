import { Ref, computed, ref, watch } from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import { useCartAttributes, useCartItemCount, useUpdateProductStepItem } from '../cart';
import { useAlerts } from '../alerts';
import { useAddProductToCartContext } from '../productAddToCart';
import { MaybeReactive } from '~/types/utils';
import { EnteredOptionInput, CustomizableOptionInput } from '~/graphql-types.gen';

export function useQuantityControl(
  sku: MaybeReactive<string>,
  max: Ref<number>,
  min: Ref<number>,
  stock: Ref<number>,
  parentSku?: MaybeReactive<string | null>,
  options?: MaybeReactive<{
    entered_options?: Array<EnteredOptionInput>;
    customizable_options?: Array<CustomizableOptionInput>;
  }>
) {
  const { cartItemCount } = useCartItemCount(sku);
  const { items } = useCartAttributes();
  const { error } = useAlerts();

  const { updateCartQty } = useUpdateProductStepItem(sku, parentSku, options);
  const { addToCart } = useAddProductToCartContext();

  const count = ref(cartItemCount.value);

  function resolveCountValue(value: number, min: number): number {
    const isIncrease: boolean = value > 0;
    const parsedValue = Number(Math.abs(value));

    if (isIncrease) {
      return parsedValue < min ? min : parsedValue;
    }

    return parsedValue < min ? 0 : parsedValue;
  }

  async function onCountChange(value: number) {
    const oldValue = count.value;

    try {
      count.value = resolveCountValue(value, min.value);

      if (!oldValue) {
        addToCart();
        return;
      }

      await updateCartQty(count.value);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);

      if (/The requested sku is not assigned to given stock./.test((e as Error)?.message))
        error('STOCK', 'The requested sku is not assigned to given stock.');
      count.value = oldValue;
    }
  }

  const maxQuantity = computed(() => {
    return Math.min(max.value, stock.value);
  });

  watch(cartItemCount, value => {
    count.value = value;
  });

  const debouncedOnCountChange = debounce(onCountChange, 1000);

  return {
    resolveCountValue,
    requestChangeCount: debouncedOnCountChange,
    count,
    items,
    maxQuantity,
  };
}
