import { useContext, useMeta, unref } from '@nuxtjs/composition-api';
import { useI18n } from './i18n';
import { MaybeReactive } from '~/types/utils';
import { keysOf } from '~/utils/collections';
import { useStoreConfig } from '~/features/storeConfig';
import { hash } from '~/utils/text';

interface SeoComposableOptions {
  title?: string | null;
  description?: string | null;
  image?: string | null;
  keywords?: string | null;
  type?: string | null;
}

/**
 * Generates common meta tags for any page.
 */
export function useSeoMeta(opts?: MaybeReactive<SeoComposableOptions>) {
  const { $config, route, app } = useContext();
  const { t } = useI18n();
  const { defaultTitle, defaultDescription, defaultKeywords, title_prefix, title_suffix } = useStoreConfig();
  const currentLocale = (app.i18n as any).locale || 'en';
  const alternateLocale = currentLocale === 'en' ? 'ar-EG' : 'en-US';

  useMeta(() => {
    const { title, description, image, keywords, type = 'website' } = unref(opts || ({} as any));

    const properties = {
      description: description || defaultDescription?.value || t('seo.description').toString(),
      'og:title': title || defaultTitle?.value || t('seo.title').toString(),
      'og:description': description || defaultDescription.value,
      'og:site_name': currentLocale === 'en-US' ? 'Site Name' : 'الاسم',
      'og:type': `og:${type}`,
      'og:locale': currentLocale === 'en' ? 'en-US' : 'ar-EG',
      'og:locale:alternate': alternateLocale,
      'og:url': `${$config.appURL}${route.value.path}`,
      'og:image': image,
      'twitter:card': 'summary_large_image',
      'twitter:title': title || defaultTitle.value,
      'twitter:description': description || defaultDescription.value,
      'twitter:image': image || '/icons/manifest-icon-512.maskable.png',
      keywords: keywords || defaultKeywords.value,
    };

    const meta = keysOf(properties)
      .filter(prop => properties[prop])
      .map(prop => {
        return {
          hid: prop,
          name: prop,
          property: prop,
          content: properties[prop] as string,
        };
      });

    return {
      title: title || defaultTitle.value,
      titleTemplate: `${title_prefix.value || ''} %s ${title_suffix.value || ''}`.trim(),
      meta,
    };
  });
}

/**
 * Generates common SEO links for pages.
 */
export function useSeoLinks() {
  const { $config, route, app } = useContext();
  const currentLocale = (app.i18n as any).locale || 'en';

  const seoLinks = [
    // Informs crawlers that this page is original page that holds this content
    {
      hid: 'canonical',
      rel: 'canonical',
      href: `${$config.appURL}${route.value.path}`,
    },
    // Tells the crawler that there is an alternate `ar` locale mirror page for this page
    {
      hid: 'alternate-hreflang-ar',
      rel: 'alternate',
      hreflang: 'ar',
      // adds the `ar` prefix only if the current locale is English so we don't end up with double `ar/ar`
      href: `${$config.appURL}${currentLocale === 'en' ? '/ar' : ''}${route.value.path}`,
    },
    // Tells the crawler that there is an alternate `en` locale mirror page for this page
    {
      hid: 'alternate-hreflang-en',
      rel: 'alternate',
      hreflang: 'en',
      // removes the `ar` prefix so it correctly links to the English version
      href: `${$config.appURL}${route.value.path.replace(/^\/ar\//, '/')}`,
    },
  ];

  useMeta({
    link: seoLinks,
    htmlAttrs: { dir: currentLocale === 'en' ? 'ltr' : 'rtl', lang: currentLocale },
  });
}

export function useJsonLd(schema: MaybeReactive<Record<string, any>>) {
  useMeta(() => {
    const minifiedString = JSON.stringify(unref(schema), null, process.env.NODE_ENV === 'production' ? '' : 2);
    const innerHTML = `\n${minifiedString}\n`;
    const hid = `jsonld-${hash(minifiedString)}`;

    return {
      script: [
        {
          innerHTML,
          type: 'application/ld+json',
          hid,
        },
      ],
      __dangerouslyDisableSanitizersByTagID: {
        [hid]: ['innerHTML'],
      },
    };
  });
}
