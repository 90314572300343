
































































































































































import {
  defineComponent,
  watchEffect,
  onBeforeUnmount,
  ref,
  watch,
  computed,
  onMounted,
} from '@nuxtjs/composition-api';
import { useRouter } from '~/features/router';
import { useSearchProduct } from '~/features/searchProduct';
import { purify } from '~/directives/purify-html';

const gradientClasses = ['gradient-primary-800', 'gradient-primary-700', 'gradient-primary-600'];

export default defineComponent({
  name: 'SearchDialog',
  directives: {
    purify,
  },
  setup() {
    const {
      execute,
      searchBy: searchByProduct,
      result: productsResult,
      isFetching,
      categoriesResult,
      confirmedSearchWord,
      fetchBrands,
      brands,
      isFetchingBrands,
    } = useSearchProduct('');
    const searchBy = ref('');

    const isTouched = ref(false);

    const { route } = useRouter();

    const isOpen = ref(false);

    watchEffect(() => {
      if (process.server) {
        return;
      }

      window.document.body.classList.toggle('overflow-hidden', isOpen.value);
    });

    watch(searchBy, value => {
      if (!value) {
        isTouched.value = false;
      }
      searchByProduct.value = value;
    });

    watch(isOpen, (value, oldValue) => {
      if (value && !oldValue) {
        execute();
        fetchBrands();
      }
    });

    watch([categoriesResult, productsResult], ([newCategoryResult, newProductResult]) => {
      if (newCategoryResult || newProductResult) {
        isTouched.value = true;
      }
    });

    onBeforeUnmount(() => window.document.body.classList.toggle('overflow-hidden', false));

    function close() {
      isOpen.value = false;
    }

    // close menu when navigating
    watch(route, () => {
      searchBy.value = '';
      close();
    });

    onMounted(() => {
      searchBy.value = route.value?.query?.q?.toString() || '';
    });

    const noResults = computed(() => {
      return (
        isTouched.value &&
        searchBy.value &&
        !(
          isFetching.value ||
          isFetchingBrands.value ||
          productsResult.value?.length ||
          categoriesResult.value?.length ||
          brands.value.length
        )
      );
    });

    return {
      searchBy,
      close,
      productsResult,
      isOpen,
      isFetching,
      categoriesResult,
      confirmedSearchWord,
      noResults,
      isTouched,
      brands,
      isFetchingBrands,
      gradientClasses,
    };
  },
});
