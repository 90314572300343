















































































































































































import { defineComponent, onMounted, computed, ref, watch } from '@nuxtjs/composition-api';
import { useRouter } from '~/features/router';
import { useSearchProduct } from '~/features/searchProduct';

const gradientClasses = ['gradient-primary-800', 'gradient-primary-700', 'gradient-primary-600'];

/**
 * A Feature Flag to determine to trigger the search upon hovering or not
 */
const IS_PASSIVE = false;

export default defineComponent({
  setup() {
    const { route } = useRouter();
    const touched = ref(false);
    const isFocusing = ref(false);

    const {
      searchBy: search,
      result: productsResult,
      categoriesResult,
      execute,
      isFetching,
      fetchBrands,
      brands,
      isFetchingBrands,
    } = useSearchProduct(route.value?.query?.q?.toString() || '', {
      passive: true,
    });
    const show = computed<Boolean>(() => {
      if (IS_PASSIVE && isFocusing.value) {
        execute();
        fetchBrands();
        return true;
      }

      if (search.value && touched.value) {
        return true;
      }

      return !!(
        isFocusing.value &&
        (productsResult.value?.length || categoriesResult.value?.length || isFetching.value)
      );
    });

    onMounted(() => {
      document.addEventListener('click', e => {
        if (e.target instanceof Element && document.getElementById('search-bar')?.contains(e.target)) return;
        isFocusing.value = false;
      });
    });

    // clear search to force close menu when navigating
    watch(route, () => {
      search.value = '';
    });

    const noResults = computed(
      () => !productsResult.value?.length && !categoriesResult.value?.length && !isFetching.value
    );

    function forceTriggerInputBlur() {
      isFocusing.value = false;
    }

    return {
      search,
      show,
      productsResult,
      execute,
      isFetching,
      categoriesResult,
      isFocusing,
      noResults,
      forceTriggerInputBlur,
      gradientClasses,
      touched,
      brands,
      isFetchingBrands,
    };
  },
});
