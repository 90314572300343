import { defineNuxtPlugin, onGlobalSetup, useContext } from '@nuxtjs/composition-api';
import { cache, dedup, useClient } from 'villus';
import { varnishPlugin } from './varnish';
import { userExpiration } from './user-experation.villus';
import { useAuth, sourceMetaDataPlugin, useCleanAuth } from '~/features/auth';
import { useSetupCart, clearCart } from '~/features/cart';
import { localePlugin, useI18n } from '~/features/i18n';
import { useStoreConfig } from '~/features/storeConfig';
import { useCategories } from '~/features/categories';
import { useTrackingHandlers } from '~/features/trackingHandlers';
import { fetch } from '~/utils/villus/fetch';

import useCookies from '~/features/cookies';

export default defineNuxtPlugin(() => {
  onGlobalSetup(() => {
    const { $config } = useContext();
    const { cookies } = useCookies();
    const { getLocale } = useI18n();

    useClient({
      url: $config.apiURL,
      use: [
        sourceMetaDataPlugin(),
        process.env.NODE_ENV === 'test' ? () => {} : varnishPlugin(getLocale()), // disable varnish in test mode
        localePlugin(),
        userExpiration(),
        ...[cache(), dedup(), fetch()],
      ],
    });
    useCleanAuth();
    clearCart();
    useAuth();
    useSetupCart(cookies);
    useStoreConfig();
    useCategories();
    useTrackingHandlers();
  });
});
