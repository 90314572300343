import type { Route } from 'vue-router';
import { isBefore } from 'date-fns';
import { getFeatureFlags } from '../storeConfig';
import { CartDataFragment, CartItemsFragment, ProductInterface } from './../../graphql/fragments';
import CartConf, { routeOptionsMapper, Flags, Operation } from './conf';
import { CartItem, cartState, mapCartItem } from './cart';
import { PartialRecord, toNonNullable } from '~/utils/collections';
import { CartAddressInput, CartTierPromotion, PickupLocation } from '~/graphql-types.gen';

export interface TierGift {
  name: string;
  thumbnail: string;
  stock: number;
  sku: string;
}

export interface PromotionOption {
  is_eligible: boolean;
  tier_minimum_subtotal: number;
  gift: TierGift | TierGift[];
}

export interface SpinTheWheelData {
  eligibleToSpin: boolean;
  eligibleToRedeemGift: boolean;
  isGiftRedeemed: boolean;
  gift: TierGift | null;
  endDate: string;
}

export function resolveCartOptions(operation: Operation): PartialRecord<Flags, boolean> {
  return CartConf[operation] ?? {};
}

export function resolveCartRouteOptions(operation: Operation, route: Route): PartialRecord<Flags, boolean> {
  return routeOptionsMapper[route.name?.split('___')[0] || '']?.[operation] ?? {};
}

export function mapStoreLocatorToCartAddressInput(storeLocator: Partial<PickupLocation>): CartAddressInput {
  return {
    street: [storeLocator.street],
    city: storeLocator.city || '',
    // district: storeLocator.district?.code as string,
    region: storeLocator.region_id?.toString() || '',
    country_code: 'EG',
    firstname: 'Store pickup',
    lastname: storeLocator.name || '',
    save_in_address_book: false,
    postcode: storeLocator.postcode || '',
  };
}

export function resolveCartItemPrice(apiProduct: CartItemsFragment['product']) {
  // configurable products may not have stock associated so `null` is expected
  if (['BundleProduct', 'ConfigurableProduct', 'GroupedProduct'].includes(apiProduct.__typename)) {
    return apiProduct.only_x_left_in_stock === null ? 10000 : apiProduct.only_x_left_in_stock;
  }

  return apiProduct.only_x_left_in_stock || 0;
}

export function resolveGiftOptionId(apiProduct: CartItemsFragment) {
  if (apiProduct.__typename === 'SimpleCartItem' && apiProduct.product.__typename === 'SimpleProduct') {
    return apiProduct.product?.options?.find(option => option?.__typename === 'CustomizableFieldOption')?.option_id;
  }

  if (apiProduct.__typename === 'ConfigurableCartItem' && apiProduct.product.__typename === 'ConfigurableProduct') {
    return apiProduct.product?.options?.find(option => option?.__typename === 'CustomizableFieldOption')?.option_id;
  }

  return null;
}

export function resolveGiftMessage(apiProduct: CartItemsFragment) {
  if (
    (apiProduct.__typename === 'SimpleCartItem' && apiProduct.product.__typename === 'SimpleProduct') ||
    (apiProduct.__typename === 'ConfigurableCartItem' && apiProduct.product.__typename === 'ConfigurableProduct')
  ) {
    return apiProduct.customizable_options[0]?.values[0]?.value;
  }

  return null;
}

export const resolveCartGifts = (cart: Partial<CartDataFragment>, productSupportedGifts = false) =>
  toNonNullable(cart.items)
    .filter(item => item?.is_gift && (productSupportedGifts ? item?.gifting_item_uid : !item?.gifting_item_uid))
    .map(item => mapCartItem(item)) ?? [];

export const resolveCartTotal = (cart: Partial<CartDataFragment>, state: typeof cartState) => {
  return cart.prices?.grand_total?.value ?? state.total ?? 0;
};

export const resolveCartSubtotal = (cart: Partial<CartDataFragment>, state: typeof cartState) => {
  return cart.prices?.subtotal_including_tax?.value ?? state.subtotal ?? 0;
};

export const resolveCartItems = (cart: Partial<CartDataFragment>, state: typeof cartState) => {
  // These are gifts offered by product
  const productGifts = resolveCartGifts(cart, true);

  // These are gifts offered by cart
  const cartGifts = resolveCartGifts(cart, false);

  return {
    items:
      (cart.items
        ? toNonNullable(cart.items)
            .filter(item => !item.is_gift)
            .map(item => mapCartItem(item, productGifts as Array<CartItem>))
        : state.items) ?? [],
    productGifts,
    cartGifts,
  };
};

const resolveRewardPoints = (cart: Partial<CartDataFragment>, state: typeof cartState) => {
  return cart?.applied_reward_points
    ? {
        money: cart?.applied_reward_points?.money?.value || 0,
        points: cart?.applied_reward_points?.points || 0,
      }
    : state?.appliedRewardPoints;
};

export const resolveAppliedRewardPoints = (cart: Partial<CartDataFragment>, state: typeof cartState) => {
  return cart?.applied_reward_points === null ? null : resolveRewardPoints(cart, state);
};

export const resolveCartPromotions = (cart: Partial<CartDataFragment>, state: typeof cartState) => {
  const { spinTheWheel } = getFeatureFlags();
  const isSpinTheWheel: boolean = spinTheWheel;

  return cart.cart_promotions
    ? {
        options:
          cart.cart_promotions.map(option => {
            return {
              is_eligible: option?.is_eligible,
              tier_minimum_subtotal: option?.tier_minimum_subtotal || 0,
              gift: mapTierGift(option as CartTierPromotion, isSpinTheWheel),
            };
          }) || [],
        eligiblePromotionIdx: cart?.cart_promotions?.findIndex(promotion => promotion?.is_eligible) || 0,
        isSpinTheWheel,
      }
    : state.cartPromotions;
};

export const resolveCarPriceRuleDiscounts = (cart: Partial<CartDataFragment>, state: typeof cartState) => {
  return cart.cartPriceRuleDiscounts
    ? {
        tiers:
          cart.cartPriceRuleDiscounts.map(tier => {
            return {
              discountValue: tier?.discount_amount || 0,
              isApplied: tier?.is_applied || false,
              subtotalValue: tier?.conditions[0]?.value || 0,
            };
          }) || [],
        eligibleTierIdx: cart?.cartPriceRuleDiscounts?.findIndex(tier => tier?.is_applied) || 0,
      }
    : state.cartPriceRuleDiscounts;
};

/**
 * If spin the wheel than each tier will have multiple options for the gift
 * If normal cart promotion then there's only one option per tier
 */
function mapTierGift(cartPromotionsTier: Partial<CartTierPromotion>, isSpinTheWheel: boolean): TierGift | TierGift[] {
  if (isSpinTheWheel) {
    return (cartPromotionsTier.gifts?.items as ProductInterface[]).map((item: ProductInterface) => {
      return {
        name: item.name as string,
        thumbnail: item.thumbnail?.url as string,
        stock: item.only_x_left_in_stock as number,
        sku: item.sku as string,
      };
    });
  } else {
    return {
      name: cartPromotionsTier.gifts?.items?.[0]?.name as string,
      thumbnail: cartPromotionsTier.gifts?.items?.[0]?.thumbnail?.url as string,
      stock: cartPromotionsTier.gifts?.items?.[0]?.only_x_left_in_stock as number,
      sku: cartPromotionsTier.gifts?.items?.[0]?.sku as string,
    };
  }
}

export function resolveSpinTheWheelData(cart: Partial<CartDataFragment>, state: typeof cartState) {
  return cart.spinTheWheelData
    ? {
        eligibleToSpin: cart.spinTheWheelData?.eligible_to_spin as boolean,
        /**
         Eligible to redeem gift if:
          1. Not eligible to spin again
          2. Won a gift
          3. Gift end time has not passed  
        */
        eligibleToRedeemGift: (!cart.spinTheWheelData?.eligible_to_spin &&
          cart.spinTheWheelData?.product_won &&
          isBefore(new Date(), new Date(cart.spinTheWheelData?.end_time as string))) as boolean,
        isGiftRedeemed: !cart.spinTheWheelData?.eligible_to_spin && !cart.spinTheWheelData?.product_won,
        gift: cart.spinTheWheelData?.product_won
          ? {
              name: cart.spinTheWheelData?.product_won.name as string,
              thumbnail: cart.spinTheWheelData?.product_won.thumbnail?.url as string,
              stock: cart.spinTheWheelData?.product_won.only_x_left_in_stock as number,
              sku: cart.spinTheWheelData?.product_won.sku as string,
            }
          : null,
        endDate: cart.spinTheWheelData?.end_time as string,
      }
    : state.spinTheWheelData;
}
