
























import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isExpanded = computed(() => {
      if (props.name) {
        return props.value === props.name;
      }

      return props.value === true;
    });

    function toggle(force?: boolean) {
      if (typeof force === 'boolean') {
        emit('input', props.name || true);
        return;
      }

      emit('input', isExpanded.value ? null : props.name || true);
    }

    return {
      toggle,
      isExpanded,
    };
  },
});
