import { render, staticRenderFns } from "./AddToCartAlert.vue?vue&type=template&id=21369ae4&scoped=true&"
import script from "./AddToCartAlert.vue?vue&type=script&lang=ts&"
export * from "./AddToCartAlert.vue?vue&type=script&lang=ts&"
import style0 from "./AddToCartAlert.vue?vue&type=style&index=0&id=21369ae4&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21369ae4",
  null
  
)

/* custom blocks */
import block0 from "./AddToCartAlert.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppButton: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AppButton.vue').default})
