


































































































/**
 * Gift wrapping component
 *
 * emits input event when the gift wrapping is changed
 * input = '' if the gift wrapping is deleted or deactivated ( action remove)
 * input = placeholder if the gift wrapping is added in the first time  ( action add )
 * input = message if the gift wrapping is edited ( action edit )
 *
 * */
import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const emptyPlaceHolder = 'N/A';
export default defineComponent({
  props: {
    value: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const activeGiftWrapping = ref(!!(props.value && props.value !== emptyPlaceHolder));
    const giftWrappingInfo = ref(false);

    const messageInput = ref(props.value);
    const isSubmited = ref(!!props.value);

    watch(
      () => props.value,
      value => {
        messageInput.value = value;
      }
    );

    watch(activeGiftWrapping, (newValue, oldValue) => {
      if (oldValue && !newValue) {
        isSubmited.value = false;
        emit('remove', '');
      }

      if (!oldValue && newValue) {
        isSubmited.value = false;
        // emit('add', emptyPlaceHolder);
      }
    });

    function edit() {
      isSubmited.value = false;
    }

    function deleteMessage() {
      isSubmited.value = false;
      activeGiftWrapping.value = false;
      emit('remove', emptyPlaceHolder);
    }

    function submitMessage() {
      if (messageInput.value) {
        emit('update', messageInput.value);
        isSubmited.value = true;
        return;
      }

      activeGiftWrapping.value = false;
    }

    return {
      activeGiftWrapping,
      messageInput,
      submitMessage,
      isSubmited,
      edit,
      deleteMessage,
      giftWrappingInfo,
    };
  },
});
