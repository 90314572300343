export default function globalListeners() {
  document.addEventListener('DOMContentLoaded', () => {
    const body = document.body;
    const html = document.documentElement;

    document.documentElement.style.setProperty(
      '--scrollbar-width',
      window.innerWidth - document.documentElement.clientWidth + 'px'
    );

    document.documentElement.style.setProperty('--window-height', window.innerHeight + 'px');

    document.documentElement.style.setProperty(
      '--page-height',
      Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) + 'px'
    );

    document.documentElement.style.setProperty(
      '--screen-height',
      Math.max(body.offsetHeight, html.clientHeight, html.offsetHeight) + 'px'
    );
  });
}
