





























































































































































import { computed, defineComponent, onMounted, onBeforeUnmount, ref, PropType } from '@nuxtjs/composition-api';
import { clone } from 'lodash-es';
import { useIsInComparison, removeItem, compareItem } from '~/features/compare';
import { useEventBus } from '~/features/events';
import { ProductNodes } from '~/features/products';
import { Options } from '~/features/products/utils';
import { useStoreConfig } from '~/features/storeConfig';
import { TRACKING_EVENTS } from '~/features/trackingHandlers';
import { Unpacked } from '~/types/utils';

export default defineComponent({
  props: {
    product: {
      type: Object as PropType<Unpacked<ProductNodes>>,
      default: null,
    },
  },
  setup(props) {
    const { limited_qty_threshold } = useStoreConfig();
    const { emit } = useEventBus();
    const el = ref<Element>();
    const observer = ref<IntersectionObserver>();

    /**
     * component state
     */

    const isLimitedStock = computed(() => {
      return props.product?.stock && limited_qty_threshold.value >= props.product?.stock;
    });

    const discount = computed(() => {
      if (!props.product?.priceBefore) {
        return 0;
      }

      return (props.product?.priceBefore - Number(props.product?.price)) / props.product?.priceBefore;
    });

    const isGrouped = computed(() => {
      return props.product?.type === 'GroupedProduct';
    });

    const isConfigurable = computed(() => {
      return props.product?.type === 'ConfigurableProduct';
    });

    const isBundle = computed(() => {
      return props.product?.type === 'BundleProduct';
    });

    const isInComparison = useIsInComparison(props.product?.sku || '');

    function toggleComparison() {
      if (isInComparison.value) {
        removeItem(props.product?.sku || '');
        return;
      }

      compareItem({
        sku: props.product?.sku || '',
        categoryIds: props.product?.categories?.map(c => c?.id || 0) || [],
      });
    }
    const productInternal = ref(clone(props.product));

    onMounted(() => {
      // Send product impression event only if card is above the fold
      observer.value = new IntersectionObserver(
        entries => {
          if (entries[0].intersectionRatio === 1) {
            emit(TRACKING_EVENTS.ON_PRODUCT, { ...props });
            observer.value?.disconnect();
          }
        },
        {
          threshold: [1.0],
        }
      );
      if (el.value) observer.value.observe(el.value as Element);
    });

    onBeforeUnmount(() => {
      observer.value?.disconnect();
    });

    /**
     * Function that handles triggering product_click event for Enhanced Ecommerce
     */
    function productClickEvent() {
      emit(TRACKING_EVENTS.PRODUCT_CLICK, { ...props });
    }
    function updateImageOptionDisplay(option?: Unpacked<Options>) {
      if (!option) {
        productInternal.value = { ...props.product };

        return;
      }

      productInternal.value = { ...props.product, ...option };
    }

    /**
     *
     * A Computed Property to determine whether we are able to deal with add item to the cart or not from here
     */
    const ableToAddToCart = computed<Boolean>(() => {
      // return productInternal.value.type === 'SimpleProduct';
      // Disable add to cart for all product types
      return false;
    });

    return {
      productInternal,
      isLimitedStock,
      discount,
      isInComparison,
      toggleComparison,
      productClickEvent,
      isGrouped,
      isBundle,
      isConfigurable,
      el,
      updateImageOptionDisplay,
      ableToAddToCart,
    };
  },
});
