












import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { useAlerts } from '~/features/alerts';
import { useCartItemCustomizationOption } from '~/features/cart';

export default defineComponent({
  props: {
    itemId: {
      type: String,
      required: true,
    },
    optionId: {
      type: [String, Number],
      required: true,
    },
    giftBoxMessage: {
      type: String,
      default: '',
      required: false,
    },
  },
  setup({ itemId, optionId }) {
    const { updateCustomizationOption, addCustomizationOption } = useCartItemCustomizationOption(
      computed(() => itemId)
    );

    const { success, error } = useAlerts();
    const isUpdating = ref(false);

    async function updateBoxMessage(message: string) {
      try {
        isUpdating.value = true;
        await updateCustomizationOption(optionId as number, message);

        success('Gift wrapping message updated');
        isUpdating.value = false;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        error('Error Updating Setting Gift Wrapping Message');
        isUpdating.value = false;
      }
    }

    async function addBoxMessage(message: string) {
      try {
        isUpdating.value = true;
        await addCustomizationOption(optionId as number, message);

        success('Gift wrapping message updated');
        isUpdating.value = false;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        error('Error Adding Setting Gift Wrapping Message');
        isUpdating.value = false;
      }
    }

    async function removeBoxMessage() {
      try {
        isUpdating.value = true;
        await updateBoxMessage('N/A');

        success('Gift wrapping message updated');
        isUpdating.value = false;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        error('Error Removing Setting Gift Wrapping Message');
        isUpdating.value = false;
      }
    }

    return {
      updateBoxMessage,
      removeBoxMessage,
      addBoxMessage,
      isUpdating,
    };
  },
});
