//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider } from 'vee-validate';

export default {
  name: 'Checkbox',
  components: {
    ValidationProvider,
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    messages: {
      type: Object,
      default: null,
    },
    value: {
      type: null,
      default: true,
    },
    // Customizes the value set on the model when the checkbox is checked
    checkedValue: {
      type: null,
      default: true,
    },
    // Customizes the value set on the model when the checkbox is unchecked.
    // Has no effect when the input is bound to an array.
    uncheckedValue: {
      type: null,
      default: false,
    },
    // the attribute name of the current item being changed (if we're passing an object of values)
    propName: {
      type: String,
      default: undefined,
    },
    variant: {
      type: String,
      default: '',
    },

    // UI

    align: {
      type: String,
      /** * @type {string} */
      validator: v => ['reversed', 'normal'].includes(v),
      default: 'reversed',
    },
  },
  computed: {
    /**
     * handles checking or unchecking checkbox
     * returns true to check and false to uncheck
     */
    isChecked() {
      // if we have an array of selected values ex: categories filters
      if (Array.isArray(this.value)) {
        // return true if the selected values' array contains the checkbox's checkedValue
        return this.value.includes(this.checkedValue);
      }
      // if we have an object of all values ex: more filters && the value is not null
      if (typeof this.value === 'object' && this.value) {
        // compare the current item's value in the object with the checkbox's checkedValue
        return this.value[this.propName] === this.checkedValue;
      }
      // otherwise we're passing a single value
      return this.value === this.checkedValue;
    },
  },
  watch: {
    value(value) {
      this.$refs.provider.syncValue(value);
    },
  },
  methods: {
    /**
     * handles toggling the checkbox's state
     */
    toggle() {
      // if have an object of values
      if (typeof this.value === 'object' && this.value && !Array.isArray(this.value)) {
        // then shallow clone the object to handle mutability
        // and toggle the value of the current selected item
        // and emit the event with the new object
        return this.$emit('input', {
          ...this.value,
          [this.propName]: this.isChecked ? this.uncheckedValue : this.checkedValue,
        });
      }
      // if we have a single value and current item is checked
      if (this.isChecked && !Array.isArray(this.value)) {
        return this.$emit('input', this.uncheckedValue);
      }
      // if we have an array of values and checkbox is currently checked
      if (Array.isArray(this.value) && this.isChecked) {
        // filter out the current value from the array
        // and emit the input event with the filtered array
        return this.$emit(
          'input',
          this.value.filter(val => val !== this.checkedValue)
        );
      }

      const newVal = Array.isArray(this.value) ? [...this.value, this.checkedValue] : this.checkedValue;
      this.$emit('input', newVal);
    },
  },
};
