import { computed, ssrRef, useAsync, useContext } from '@nuxtjs/composition-api';
import LRUCache from 'lru-cache';
import { RedisClientType } from 'redis';

// Default TTL of 15 minutes
const CACHE_TTL = 1000 * 60 * 15;

/**
 * Creates a cache-able SSR ref, the caching only occurs on server-side, client side is unaffected.
 * Helps speed up expensive operations of resources that can be stale for some time like categories and store config.
 * @param key
 * @param value
 * @param ttl
 */
export function useCachedSsrRef<T>(key: string, value: T, ttl: number = CACHE_TTL) {
  const ctx = useContext();
  const ssrContext = ctx.ssrContext as typeof ctx['ssrContext'] & {
    $serverCache: LRUCache<string, any>;
    $redisCache: RedisClientType;
  };
  const ref = ssrRef(value, key);
  const serverCache = ssrContext?.$serverCache;

  const redisCache = ssrContext?.$redisCache;

  if (!serverCache) {
    return ref;
  }

  if (!redisCache && serverCache.has(key)) {
    ref.value = serverCache.get(key);
  }

  useAsync(async () => {
    if (redisCache && (await redisCache.exists(key))) {
      ref.value = JSON.parse((await redisCache.get(key)) || '{}') as T;
    }
  });

  // wrap the SSR ref with computed prop that updates the cache value
  const wrapper = computed<T>({
    get() {
      return ref.value;
    },
    set(value) {
      if (process.server) {
        ssrContext.$serverCache.set(key, value, ttl);
      }

      if (process.server && ssrContext.$redisCache) {
        ssrContext.$redisCache.set(key, JSON.stringify(value));
      }

      ref.value = value;
    },
  });

  return wrapper;
}
