

























import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Alert',
  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup({ type, id }, { emit }) {
    function dismiss() {
      emit('dismiss', id);
    }

    const icon = computed(() => {
      return ({
        danger: 'error',
        success: 'check',
        info: 'check',
        warning: 'warn',
      } as any)[type];
    });

    return {
      dismiss,
      icon,
    };
  },
});
