import { computed, provide, ref, useAsync, useContext } from '@nuxtjs/composition-api';
import { CombinedError, useMutation, useQuery } from 'villus';
import type { ClientPlugin } from 'villus';
import { clearCart } from './cart';
import { useRouter } from './router';
import { useAlerts } from './alerts';
import { useI18n } from './i18n';
import useCookies from './cookies';
import { useEventBus } from './events';
import { TRACKING_EVENTS } from './trackingHandlers';
import { useExceptions } from '~/features/exceptions';
import {
  IdentityDocument,
  IdentityQuery,
  LoginDocument,
  RegisterDocument,
  RevokeTokenDocument,
  RequestResetPasswordEmailDocument,
  ResetPasswordDocument,
  ResetPasswordMutationVariables,
  CustomerCreateInput,
  RequestResetPasswordByPhoneNumberDocument,
  GetPhoneNumberDocument,
} from '~/graphql/Auth';
import { AUTH_IS_LOGGED_IN, AUTH_USER } from '~/utils/provides';
import { topics, useChannels } from '~/features/channels';

import {
  DeleteUserDocument,
  UpdateCustomerDocument,
  UpdateCustomerMutationVariables,
  UpdatePasswordDocument,
  UpdatePasswordMutationVariables,
} from '~/graphql/Customer';
import { keysOf } from '~/utils/collections';
import { extractQueryName } from '~/utils/gql';
import { VerificationPayload } from '~/types/verifyIdentity';
import { isEmail } from '~/utils/text';

export type Customer = ReturnType<typeof mapUserData>;

export const isPendingAuth = ref<Promise<IdentityQuery['customer']> | undefined>(undefined);

const user = ref<Customer | null | undefined>(undefined);

export function setUser(data: IdentityQuery['customer'] | undefined | null) {
  user.value = mapUserData(data);
}

export type AuthEvents = 'ENTER_OTP' | 'REGISTER_NEW_USER' | 'OTP_AUTHENTICATED' | 'OTP_CLOSED';

export async function getUser() {
  if (isPendingAuth.value) {
    await isPendingAuth.value;
  }

  return user.value || null;
}

export function useAuth() {
  const { cookies } = useCookies();

  if (!user.value && cookies['is-secured']) {
    useAsync(async () => {
      if (isPendingAuth.value && !user.value) {
        await isPendingAuth.value;
        return;
      }

      isPendingAuth.value = useIdentity().identify();
      const fetchedUser = await isPendingAuth.value;

      setUser(fetchedUser);
      isPendingAuth.value = undefined;
    });
  }

  const isLoggedIn = computed(() => {
    return !!user.value;
  });

  provide(AUTH_USER, user);

  provide(AUTH_IS_LOGGED_IN, isLoggedIn);

  return {
    isLoggedIn,
    user,
    setUser,
    isPendingAuth,
  };
}

export function useLogin() {
  const { execute, isFetching } = useMutation(LoginDocument);
  const { execute: getPhoneNumber, isFetching: isGettingPhoneNumber } = useQuery({
    query: GetPhoneNumberDocument,
    fetchOnMount: false,
  });

  const { identify } = useIdentity();
  const { emit } = useEventBus();

  async function login(input: { phone: string; password: string }) {
    try {
      const { data, error } = await execute({
        email: input.phone,
        password: input.password,
      });

      if (error) {
        throw new Error(error.message);
      }

      if (!data?.response?.token) {
        throw new Error('No token was generated');
      }

      const user = await identify();
      if (!user) {
        throw new Error('No user was authenticated with token');
      }

      return user;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log((err as CombinedError)?.message);
      if (
        /\[GraphQL\] This account isn't verified\. Verify by OTP and try again\./.test((err as CombinedError)?.message)
      ) {
        const phoneNumber = isEmail(input.phone /** It can be phone or email */)
          ? (
              await getPhoneNumber({
                variables: {
                  email: input.phone,
                },
              })
            ).data?.getPhoneNumberByEmail?.phone_number || ''
          : input.phone;

        await emit('ENTER_OTP', {
          identityString: input.phone,
          readonly: true,
          validationOnly: true,
          mask: phoneNumber.slice(-4),
        } as VerificationPayload);
      }
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    }
  }

  return {
    login,
    isLoggingIn: computed(() => isFetching.value || isGettingPhoneNumber.value),
  };
}

export function useIdentity() {
  const { push } = useChannels();

  const { execute, isFetching: isFetchingIdentity } = useQuery({
    query: IdentityDocument,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  async function identify() {
    try {
      const { data, error } = await execute();

      if (error) {
        throw new Error(error.message);
      }

      setUser(data?.customer ?? undefined);
      push(topics.auth.identify, data?.customer);
      return data?.customer;
    } catch (err: any) {
      // Token expired or invalid, no need to report the error
      if (/The current customer isn't authorized/.test((err as any)?.message)) {
        return;
      }
      // eslint-disable-next-line
      console.log(err);
    }
  }

  return {
    identify,
    isFetchingIdentity,
    refetch: execute,
  };
}

export function useLogout() {
  const { execute: revokeToken } = useMutation(RevokeTokenDocument);
  const { redirect } = useRouter();
  const { push } = useChannels();
  const { removeCookie } = useCookies();
  const { route } = useContext();
  const { success } = useAlerts();
  const { t } = useI18n();

  function resetUserData() {
    removeCookie('cart');
    user.value = undefined;
    clearCart();
  }

  function logout() {
    push(topics.auth.logout);
    revokeToken({}); // Don't wait for this, revoke token in background
    success(t('loggedOut') as string);
    if (
      /cart/.test(route.value.fullPath) ||
      /account/.test(route.value.fullPath) ||
      /order-placed/.test(route.value.fullPath) ||
      /success/.test(route.value.fullPath)
    ) {
      redirect('/', {
        // FIXME: using callbacks for now because cannot return promises at the moment
        onComplete: () => {
          resetUserData();
        },
      });

      return;
    }

    resetUserData();
  }

  return {
    logout,
  };
}

export function useHeadlessLogout() {
  const { redirect } = useRouter();
  const { push } = useChannels();
  const { removeCookie } = useCookies();
  const { route } = useContext();

  function resetUserData() {
    removeCookie('cart');
    user.value = undefined;
    clearCart();
  }

  function logout() {
    push(topics.auth.logout);

    if (
      /cart/.test(route.value.fullPath) ||
      /account/.test(route.value.fullPath) ||
      /order-placed/.test(route.value.fullPath) ||
      /success/.test(route.value.fullPath) ||
      /checkout/.test(route.value.fullPath)
    ) {
      redirect('/login', {
        // FIXME: using callbacks for now because cannot return promises at the moment
        onComplete: () => {
          resetUserData();
        },
      });

      return;
    }

    resetUserData();
  }

  return {
    logout,
  };
}

/**
 *
 * Removes current customer
 */
export function useRemoveCustomer() {
  const { logout } = useHeadlessLogout();
  const { execute } = useMutation(DeleteUserDocument);
  const { resolveException } = useExceptions('customer');

  async function deleteUser() {
    try {
      const { error } = await execute();
      if (error && resolveException(error)?.level === 'DANGER') {
        throw new Error(resolveException(error)?.message);
      }
      await logout();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }

  return {
    deleteUser,
  };
}

type CustomerInput = {
  email: string;
  phone: string;
  fullName: string;
  password: string;
};

/**
 * Creates a new customer
 */
export function useCreateCustomer() {
  const { execute, isFetching } = useMutation(RegisterDocument);
  const { login } = useLogin();
  const customerInput = ref<CustomerInput>({
    fullName: '',
    password: '',
    phone: '',
    email: '',
  });
  const { emit } = useEventBus();

  async function createCustomer() {
    try {
      const input = fromCustomerInputToVariable(customerInput.value);

      const { data, error } = await execute({ input });

      emit(TRACKING_EVENTS.COMPLETE_REGISTRATION, {
        firstname: customerInput.value.fullName.split(' ')[0],
        lastname: customerInput.value.fullName.split(' ').slice(1).join(' ') || '',
        email: customerInput.value.email,
      });

      if (error) {
        throw new Error(error.message);
      }

      if (!data?.response?.customer?.phone_number) {
        throw new Error('No user was created');
      }

      const user = await login({
        phone: data?.response?.customer?.phone_number || '',
        password: customerInput.value.password || '',
      });
      if (!user) {
        throw new Error('No user was authenticated with token');
      }

      return user;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    }
  }

  return {
    createCustomer,
    customerInput,
    isCreatingCustomer: isFetching,
  };
}

export function useUpdateCustomer() {
  const { execute, isFetching } = useMutation(UpdateCustomerDocument);

  async function updateCustomer(input: UpdateCustomerMutationVariables['input']) {
    try {
      const { data, error } = await execute({ input });

      if (error) {
        throw error;
      }
      const updatedUser = mapUserData(data.response?.customer);

      keysOf(user.value).forEach(key => {
        const old = user.value?.[key] || '';
        const newVal = updatedUser[key] || old;
        (user as any).value[key] = newVal;
      });

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    updateCustomer,
    isFetching,
  };
}

export function useUpdateCustomerPassword() {
  const { execute, isFetching } = useMutation(UpdatePasswordDocument);

  async function updatePassword(input: UpdatePasswordMutationVariables) {
    try {
      const { data, error } = await execute(input);

      if (error) {
        throw error;
      }

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    updatePassword,
    isFetching,
  };
}

export function useRequestPasswordResetEmail() {
  const { execute, isFetching } = useMutation(RequestResetPasswordEmailDocument);

  async function requestPasswordResetEmail(email: string) {
    try {
      const { data, error } = await execute({ email });

      if (error) {
        throw error;
      }

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    requestPasswordResetEmail,
    isFetching,
  };
}

// export function useResetPassword() {
//   const { execute, isFetching } = useMutation(ResetPasswordDocument);

//   async function resetPassword(input: ResetPasswordMutationVariables) {
//     try {
//       const { data, error } = await execute(input);

//       if (error) {
//         throw error;
//       }

//       return data;
//     } catch (err) {
//       // eslint-disable-next-line no-console
//       console.error(err);

//       throw err;
//     }
//   }

//   return {
//     resetPassword,
//     isFetching,
//   };
// }

export function useRequestPasswordResetPhoneNumber() {
  const { execute, isFetching: isFetchingResetPassword } = useMutation(RequestResetPasswordByPhoneNumberDocument);

  async function requestPasswordResetPhoneNumber(phoneNumber: string) {
    try {
      const { data, error } = await execute({ phoneNumber });

      if (error) {
        throw error;
      }

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    requestPasswordResetPhoneNumber,
    isFetching: computed(() => isFetchingResetPassword.value),
  };
}

export function useResetPassword() {
  const { execute, isFetching } = useMutation(ResetPasswordDocument);

  async function resetPassword(input: ResetPasswordMutationVariables) {
    try {
      const { data, error } = await execute(input);

      if (error) {
        throw error;
      }

      if (!data.response) {
        throw new Error('User is not existed');
      }

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    resetPassword,
    isFetching,
  };
}

function mapUserData(user: IdentityQuery['customer']) {
  return {
    // ...user,
    id: user?.id,
    firstname: user?.firstname,
    lastname: user?.lastname,
    email: user?.email,
    wishlist: {
      id: user?.wishlist?.id,
    },
    totalWishlist: user?.wishlist?.items_count || 0,
    totalOrders: user?.orders?.total_count || 0,
    phoneNumber: user?.phone_number || '',
    // user reward points
    ...rewardPointsInfo(user),
  };
}

function rewardPointsInfo(user: IdentityQuery['customer']) {
  return {
    points: user?.reward_points?.balance?.points,
    // exchange rate (  ) the value of money that you can get from the amount of points
    moneyPoints:
      ((user?.reward_points?.exchange_rates?.redemption?.currency_amount || 1) /
        (user?.reward_points?.exchange_rates?.redemption?.points || 1)) *
      (user?.reward_points?.balance?.points || 0),
  };
}
/**
 * villus client source header plugin
 */
export function sourceMetaDataPlugin(): ClientPlugin {
  // const { $config } = useContext();
  return function sourceMetaDataPlugin({ opContext, operation }) {
    (opContext.headers as Record<string, any>).source = `${process.server ? 'server' : 'browser'}`;

    const queryName = extractQueryName(operation);

    /**
     * Change API URL BASED On the name of the query
     */

    // if (
    //   queryName &&
    //   [].includes(queryName)
    // ) {
    //   opContext.url = $config.originalApiUrl;
    // }

    // if (process.server) {
    // opContext.url = $config.originalApiUrl;
    // }
    (opContext.headers as Record<string, any>).queryType = operation.type;
    (opContext.headers as Record<string, any>).queryName = queryName;
  };
}

export function useCleanAuth() {
  user.value = undefined;
}

function fromCustomerInputToVariable(input: CustomerInput): CustomerCreateInput {
  return {
    email: input.email,
    firstname: input.fullName.trim().split(' ')[0],
    lastname: input.fullName.trim().split(' ').slice(1).join(' '),
    phone_number: input.phone,
    password: input.password,
  };
}
