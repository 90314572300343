import mitt from 'mitt';
import type { Handler } from 'mitt';
import { onUnmounted, onMounted } from '@nuxtjs/composition-api';
import { AuthEvents } from './auth';
import { TRACKING_EVENTS } from './trackingHandlers';

let emitter!: ReturnType<typeof mitt>;

type AppEvent =
  | 'ITEM_ADDED'
  | 'TOGGLE_ASIDE_CART'
  | 'TOGGLE_SIDE_MENU'
  | 'AUTH_REQUIRED'
  | 'TOGGLE_CATEGORY_MENU'
  | 'DEACTIVATE_CATEGORY_ICON'
  | 'TOGGLE_ACCOUNT_MENU'
  | 'DEACTIVATE_ACCOUNT_ICON'
  | 'LOGOUT_CONFIRM'
  | AuthEvents
  | TRACKING_EVENTS;

export function useEventBus() {
  if (!emitter && !process.server) {
    emitter = mitt();
  }

  function emit(evt: AppEvent, data?: any) {
    if (!emitter || process.server) return;

    return emitter.emit(evt, data);
  }

  function on(evt: AppEvent, cb: Handler<any>) {
    if (!emitter || process.server) return;

    return emitter.on(evt, cb);
  }

  function off(evt: AppEvent, cb: Handler<any>) {
    if (!emitter || process.server) return;

    return emitter.off(evt, cb);
  }

  return {
    emit,
    on,
    off,
  };
}

export function useEvent(evt: AppEvent, callback: Handler<any>) {
  const { on, off } = useEventBus();

  onMounted(() => {
    on(evt, callback);
  });

  onUnmounted(() => {
    off(evt, callback);
  });
}
