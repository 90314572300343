









































import { defineComponent, watch, useContext } from '@nuxtjs/composition-api';
import { useDropdown } from '~/features/dropdown';
import { useAuth } from '~/features/auth';
import { useEventBus } from '~/features/events';

export default defineComponent({
  name: 'ProfileDropdown',
  setup() {
    const { isOpen, toggle } = useDropdown();
    const { isLoggedIn, user } = useAuth();
    const { route } = useContext();
    const { emit } = useEventBus();

    // close menu when navigating
    watch(route, () => {
      toggle(false);
    });

    /**
     * Used to logout and go back to the home page
     */
    function loggingOut() {
      emit('LOGOUT_CONFIRM');
      toggle(false);
    }

    return {
      isOpen,
      toggle,
      isLoggedIn,
      user,
      loggingOut,
    };
  },
});
