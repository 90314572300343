














import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ProductData } from '~/features/product';

export default defineComponent({
  name: 'AddToCartConfirmation',
  props: {
    product: {
      type: Object as PropType<ProductData>,
      default: () => {},
    },
  },
  setup(_, { emit }) {
    function close() {
      emit('close');
    }

    return {
      close,
    };
  },
});
