import { render, staticRenderFns } from "./ProductCard.vue?vue&type=template&id=07dbff74&scoped=true&"
import script from "./ProductCard.vue?vue&type=script&lang=ts&"
export * from "./ProductCard.vue?vue&type=script&lang=ts&"
import style0 from "./ProductCard.vue?vue&type=style&index=0&id=07dbff74&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07dbff74",
  null
  
)

/* custom blocks */
import block0 from "./ProductCard.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FavoriteButton: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/FavoriteButton.vue').default,ProductCardThumb: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/ProductCard/Thumb.vue').default,ProductCardOptionsDisplay: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/ProductCard/OptionsDisplay.vue').default,ProductPriceSection: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/Product/PriceSection.vue').default,AppLink: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AppLink.js').default,ProductCardQtyControl: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/ProductCard/QtyControl.vue').default})
