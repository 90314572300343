









import { defineComponent } from '@nuxtjs/composition-api';
import { useCarousel } from '~/features/Carousels';
import { useRouter } from '~/features/router';

export default defineComponent({
  setup() {
    const { route } = useRouter();
    const { carouselInfo } = useCarousel(route);

    return {
      carouselInfo,
    };
  },
});
