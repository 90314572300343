


























import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { useI18n } from '~/features/i18n';
import { ProductNodes } from '~/features/products';
import { Unpacked } from '~/types/utils';

function resolvePricePerStep(price: number, step: number) {
  if (price === 0) {
    return null;
  }
  return price * step;
}
export default defineComponent({
  props: {
    product: {
      type: Object as PropType<Unpacked<ProductNodes>>,
      default: null,
    },
    isBold: {
      type: Boolean,
      default: true,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // const { product } = useProductContext();
    const { t } = useI18n();

    const pricePerStep = computed(() =>
      resolvePricePerStep(Number(props.product.price), Number(props.product?.cartControl.step))
    );
    const beforePricePerStep = computed(() =>
      resolvePricePerStep(props.product.priceBefore || 0, Number(props.product?.cartControl.step))
    );
    return {
      isRangedProduct: computed(() => ['GroupedProduct', 'ConfigurableProduct'].includes(props.product.type)),

      // price attributes
      unit: computed(() => props.product.cartControl.unit || t('piece').toString()),

      price: computed(() => {
        if (['GroupedProduct', 'ConfigurableProduct'].includes(props.product.type)) {
          // get the minimum price of all options
          return props.product.productsVariants?.reduce(
            (accu, option) => (option.price < accu ? option.price : accu),
            Number.MAX_SAFE_INTEGER
          );
        }
        if (pricePerStep.value && pricePerStep.value !== props.product.price) {
          return pricePerStep.value;
        }

        return props.product.price;
      }),
      priceBefore: computed(() => {
        if (['GroupedProduct', 'ConfigurableProduct'].includes(props.product.type)) {
          // get the minimum price of all options
          return props.product.productsVariants?.reduce(
            (accu, option) =>
              option?.price < accu?.price
                ? {
                    price: option.price || 0,
                    priceBefore: option.priceBefore || 0,
                  }
                : accu,
            {
              price: Number.MAX_SAFE_INTEGER,
              priceBefore: 0,
            }
          )?.priceBefore;
        }

        if (['BundleProduct'].includes(props.product.type)) {
          // If any of the options is out of stock, return bundle product price
          if (props.product.bundleItems.some((item: any) => item.options.length === 0)) {
            return props.product.priceBefore;
          }

          // Otherwise get original price of all options on bundle
          return (props.product as any).bundleItems?.reduce((accu: any, item: any) => {
            const optionPriceBefore = item.options.length ? item.options[0].priceBefore * item.options[0].qty : 0;
            return optionPriceBefore + accu;
          }, 0);
        }

        if (beforePricePerStep.value && beforePricePerStep.value !== props.product?.priceBefore) {
          return pricePerStep.value;
        }
        return props.product.priceBefore;
      }),
    };
  },
});
