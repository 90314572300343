import { computed, reactive, ref, watch } from '@nuxtjs/composition-api';
import { useProductContext } from './productContext';
import { useRouter } from './router';
// for bundled products
const selectedBundleOptions = ref<string[] | []>([]);

export function useBundleProductContext() {
  const { product, appendProductValues } = useProductContext();

  const items = computed(() => {
    return product.value?.bundleItems;
  });

  const selectedOptions = reactive(
    items.value?.reduce((acc, curr) => {
      acc[String(curr.id)] = {
        isRequired: curr.isRequired as boolean,
        value: (curr.options.find(opt => opt.isDefault) || { id: '' }).id,
        price: curr.options.find(opt => opt.price)?.price ?? 0,
        stock: curr.options?.[0]?.stock ?? 0,
        quantity: curr.options?.[0]?.qty ?? 1,
      };

      return acc;
    }, {} as Record<string, { isRequired: boolean; value: string; price: number; stock: number; quantity: number }>) ||
      {}
  );

  const mapOptionIds = () => {
    return Object.keys(selectedOptions).map(itemId => selectedOptions[itemId].value);
  };

  function onSelect(itemId: number, optionId: string) {
    const targetItem = items.value?.find(item => item.id === itemId);
    const targetOption = targetItem?.options.find(item => item.id === optionId);

    if (!(targetItem && targetOption)) return;

    // clear selection
    const item = selectedOptions[itemId];

    if (item.value === optionId && !item.isRequired) {
      delete selectedOptions[itemId];
      return mapOptionIds();
    }

    item.value = optionId;

    selectedOptions[itemId] = {
      isRequired: targetItem?.isRequired as boolean,
      value: String(targetOption.id) || '',
      price: targetOption.price ?? 0,
      stock: targetOption.stock ?? 0,
      quantity: targetOption.qty ?? 1,
    };

    return mapOptionIds();
  }

  /**
   * Disable Bundle Options when all bundle options contain only 1 single option.
   */
  const disableBundleOptions = computed(() => {
    return items.value?.every(item => item.options.length === 1);
  });

  const { route } = useRouter();

  watch(
    selectedOptions,
    newOptions => {
      try {
        if (product.value.dynamicPrice) {
          appendProductValues({
            price: Object.values(newOptions).reduce((accu, option) => {
              return accu + option.price * option.quantity;
            }, 0),
            stock: Object.values(newOptions).reduce(
              (accu, option) => (accu <= option.stock ? accu : option.stock),
              Number.MAX_VALUE
            ),
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('trying to get unknown product ', route.value.fullPath);
      }

      selectedBundleOptions.value = mapOptionIds();
    },
    {
      immediate: true,
    }
  );

  return {
    onSelect,
    mapOptionIds,
    selectedOptions,
    items,
    disableBundleOptions,
    selectedBundleOptions,
  };
}
