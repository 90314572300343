

















































































import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'QtyControls',
  props: {
    max: {
      type: Number,
      required: false,
      default: Number.MAX_SAFE_INTEGER,
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    step: {
      type: Number,
      required: false,
      default: 1,
    },
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    strict: {
      type: Boolean,
      default: false,
    },

    // UI props

    showStepCount: {
      type: Boolean,
      default: true,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const count = ref(props.value);

    function increment() {
      if (!count.value) {
        count.value = props.min;
        emit('input', props.min);

        return;
      }
      count.value = Math.min(count.value + Number(props.step), Number(props.max));
      emit('input', count.value * 1);
    }

    function decrement() {
      if (count.value - Number(props.step) < props.min) {
        count.value = 0;
        emit('input', 0);
        return;
      }
      count.value = count.value - Number(props.step);
      emit('input', count.value);
    }

    const minReached = computed(() => {
      return count.value <= props.min;
    });

    watch(
      () => props.value,
      value => (count.value = value)
    );

    return {
      count,
      increment,
      decrement,
      minReached,
    };
  },
});
