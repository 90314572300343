var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"after-header-fade"}},[(_vm.value)?_c('div',{key:1,staticClass:"bg-black fixed inset-0 w-full h-full opacity-50 z-40",class:{
        'md:hidden': _vm.disableDesktop,
      },on:{"click":_vm.close}}):_vm._e()]),_vm._v(" "),_c('portal',{attrs:{"to":"after-header-slide"}},[(_vm.value)?_c('div',{key:2,staticClass:"bubble [ fixed bottom-0 ] [ w-full lg:w-auto z-40 ] [ flex items-end lg:items-center justify-center ]",class:{
        'lg:left-1/2 lg:top-1/2 [ lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2 ]':
          _vm.variant !== 'bottom' && _vm.$i18n.locale === 'en',
        'lg:left-1/2 lg:top-1/2 [ lg:transform lg:translate-x-1/2 lg:-translate-y-1/2 ]':
          _vm.variant !== 'bottom' && _vm.$i18n.locale === 'ar',
        'md:hidden': _vm.disableDesktop,
      }},[_c('div',{staticClass:"dialog__body w-full lg:w-auto lg:overflow-hidden [ md:py-4 md:px-5 ] bg-page-bg [ flex flex-col ] relative",class:{
          'dialog__body--primary': _vm.variant === 'primary',
          'dialog__body--bottom': _vm.variant === 'bottom',
          'dialog__body--secondary': _vm.variant === 'secondary',
          'dialog__body--warn': _vm.variant === 'warn',
          'dialog__body--small': _vm.variant === 'small',
        }},[(_vm.closeButton)?_c('button',{staticClass:"text-sm [ bg-gray-100 rounded-sm ] [ flex items-center justify-center ] [ w-8 h-8 ] [ absolute top-5 right-5 ] z-50",attrs:{"title":_vm.$t('close'),"aria-label":"close current open modal"},on:{"click":_vm.close}},[_c('svg-icon',{staticClass:"text-primary-700",attrs:{"name":"close","width":"21","height":"21"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"relative z-10"},[_vm._t("default")],2)])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }