import { isBefore, isAfter, endOfDay } from 'date-fns';
import { ProductData, ProductNode } from '~/features/product';
import { BundleItem, BundleItemOption, PriceRange, ProductInterface } from '~/graphql-types.gen';
import {
  ProductCardFragment,
  ProductCard_BundleProduct_Fragment,
  ProductCard_ConfigurableProduct_Fragment,
} from '~/graphql/fragments';

/**
 * Resolves the current price of the item.
 */
export function resolveProductPrice(
  product: Pick<ProductInterface, 'special_price' | 'special_from_date' | 'special_to_date'> & {
    price_range?: Partial<PriceRange>;
  }
): number {
  const specialPrice = product.special_price;
  const basePrice = product?.price_range?.maximum_price?.final_price.value ?? 0;
  if ((!product.special_from_date || !product.special_to_date) && specialPrice) {
    return specialPrice;
  }

  const offerFrom = product.special_from_date ? new Date(product.special_from_date) : null;
  const offerTo = product.special_to_date ? endOfDay(new Date(product.special_to_date)) : null;
  const now = new Date();
  const isValidOffer =
    specialPrice && specialPrice !== null && offerTo && offerFrom && isAfter(offerTo, now) && isBefore(offerFrom, now);
  if (isValidOffer) {
    return specialPrice ?? basePrice;
  }

  return basePrice;
}

/**
 * Resolves a product stock value
 */
export function resolveProductStock(apiProduct: Pick<ProductCardFragment, 'only_x_left_in_stock' | '__typename'>) {
  // Handle bundle product stock
  // if all items have enough stock for the specified quantity then show the product in stock
  // otherwise show it as out of stock
  if (apiProduct.__typename === 'BundleProduct') {
    const hasEnoughStock = ((apiProduct as ProductCard_BundleProduct_Fragment)
      .items as Array<BundleItem>)?.every((item: BundleItem) =>
      (item?.options as Array<BundleItemOption>).some(
        (option: BundleItemOption) => Number(option?.product?.only_x_left_in_stock) >= Number(option?.quantity)
      )
    );

    return hasEnoughStock ? 10000 : 0;
  }

  // Handle configurable product stock
  // Check if any of the variants has stock available
  if (apiProduct.__typename === 'ConfigurableProduct') {
    return (apiProduct as ProductCard_ConfigurableProduct_Fragment).variants?.some(
      variant => Number(variant?.product?.only_x_left_in_stock) > 0
    )
      ? 10000
      : 0;
  }

  // grouped products may not have stock associated so `null` is expected
  if (['GroupedProduct'].includes(apiProduct.__typename)) {
    return apiProduct.only_x_left_in_stock === null ? 10000 : apiProduct.only_x_left_in_stock;
  }

  return apiProduct.only_x_left_in_stock || 0;
}

/**
 * Resolves whether the product has a gift
 * @param {ProductNode} apiProduct
 * @returns {boolean}
 */
export function resolveProductHasGift(apiProduct: Pick<ProductNode, 'potential_gift_promotions'>): boolean {
  return Boolean(
    apiProduct.potential_gift_promotions?.length &&
      apiProduct.potential_gift_promotions?.[0]?.gifts?.items?.[0]?.only_x_left_in_stock
  );
}

export function isWeightedItem(product: ProductData) {
  return product.cartControl.step % 1 !== 0;
}

export function resolvePricePerStep(price: number, step: number) {
  if (price === 0) {
    return null;
  }
  return price * step;
}
