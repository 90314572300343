/* eslint-disable @typescript-eslint/no-unused-vars */
import { getCurrentInstance, onMounted, useContext } from '@nuxtjs/composition-api';
import { IVueI18n } from 'vue-i18n/types';
import useCookies from './cookies';
import { mapStoreLocale } from '~/utils/network';

interface I18nApi {
  $t: IVueI18n['t'];
  $tc: IVueI18n['tc'];
  $d: IVueI18n['d'];
  $n: IVueI18n['n'];
  $i18n: IVueI18n;
}

export function useI18n() {
  const vm = getCurrentInstance()?.proxy as I18nApi;

  function t(keyPath: string, values?: Record<string, any> | any[]) {
    return vm.$t(keyPath, values);
  }

  function tc(keyPath: string, choice: number, values?: Record<string, any> | any[]) {
    return vm.$tc(keyPath, choice, values);
  }

  /**
   * Formats a date value to a specific format
   * Available formats are declared in i18n `dateTimeFormats` options in nuxt.config.js
   */
  function d(value: number | Date, format: 'short') {
    return vm.$d(value, format);
  }

  /**
   * Formats a number value to a specific format
   * Available formats are declared in i18n `numberFormats` options in nuxt.config.js
   */
  function n(value: number, format: 'currency' | 'decimal' | 'percent' | 'plain') {
    return vm.$n(value, format);
  }

  function getLocale() {
    return vm.$i18n.locale;
  }

  return {
    t,
    tc,
    d,
    n,
    getLocale,
  };
}

/**
 * Stores an I18n cookie for the current active locale, useful for server-side redirection
 */
export function useI18nCookie() {
  const { setCookie } = useCookies();
  const vm = getCurrentInstance()?.proxy as I18nApi;

  onMounted(() => {
    setCookie('i18n_locale', vm.$i18n.locale, {
      expires: 365,
    });
  });
}

/**
 *
 */
export function useSetLocaleToCacheParams() {
  const { app } = useContext();
  function cacheParam(id: string) {
    return (app.i18n as any).locale === 'ar' ? 'ar_' + id : 'en_' + id;
  }

  return {
    cacheParam,
  };
}

/**
 * villus client locale plugin
 */
export function localePlugin() {
  const { app, $config } = useContext();
  const appTarget: string | null = $config.defaultStore || '';

  return function localePlugin({ opContext }: any) {
    const locale = (app.i18n as any).locale;

    (opContext.headers as Record<string, any>)['Content-Language'] = locale;

    (opContext.headers as Record<string, any>).Store = `${appTarget}${mapStoreLocale(locale)}`;
  };
}
