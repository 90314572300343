import { render, staticRenderFns } from "./SideMenu.vue?vue&type=template&id=79259ba4&scoped=true&"
import script from "./SideMenu.vue?vue&type=script&lang=ts&"
export * from "./SideMenu.vue?vue&type=script&lang=ts&"
import style0 from "./SideMenu.vue?vue&type=style&index=0&id=79259ba4&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79259ba4",
  null
  
)

/* custom blocks */
import block0 from "./SideMenu.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AppLink.js').default,AppAccordion: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AppAccordion.vue').default})
