import { Context } from '@nuxt/types';
/* eslint-disable camelcase */
import { extend, configure } from 'vee-validate';
import {
  required,
  email,
  min,
  max_value,
  confirmed,
  alpha_spaces,
  min_value,
  digits,
  image,
  ext,
} from 'vee-validate/dist/rules';

extend('required', required);
extend('email', email);
extend('image', image);
extend('ext', ext);
extend('min', min);
extend('confirmed', confirmed);
extend('max_value', max_value);
extend('min_value', min_value);
extend('alpha_spaces', alpha_spaces);
extend('phone', value => {
  const sanitized = value.replace(/-/g, '');

  return sanitized.length === 11;
});

configure({
  useConstraintAttrs: false,
});

extend('no_numbers', (value: string) => {
  return !value.match(/[0-9\u06F0-\u06F9]/g);
});
extend('only_numbers', (value: string) => {
  return !!value.match(/[0-9\u06F0-\u06F9]/g);
});
extend('full_name', (value: string) => {
  return !!value.trim().match(/^([^\s\d]+)\s+(\D+)$/g);
});
extend('phone_length', (value: string) => {
  const sanitized = value.replace(/[- ]/g, '');
  return sanitized.length === 11;
});
extend('phone_proper', (value: string) => {
  const ACCEPTED_PREFIX = ['010', '011', '012', '015'];
  const sanitized = value.replace(/[- ]/g, '');
  const numericalRe = /^\d+$/g;
  const firstThree = sanitized.substring(0, 3);
  return !!sanitized.match(numericalRe) && ACCEPTED_PREFIX.includes(firstThree);
});

extend('email_phone', value => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$|^\+?[0-9]{10,}$/.test(
    value
  );
});

extend('cc_expiry_date', value => {
  return /^(0[1-9]|1[0-2])\/([0-9]{2})$/.test(value);
});

extend('gift_card_code', (value: string) => {
  return !!value.match(/^[0-9\u06F0-\u06F9]{13}$/);
});

extend('digits', digits);

export default function VeeValidatePlugin({ app }: Context) {
  configure({
    defaultMessage: (_, values) => {
      return (app.i18n as any).t(`validation.${values._rule_}`, values);
    },
  });
}
