//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppButton',
  props: {
    as: {
      type: String,
      default: 'button',
    },
    white: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'default',
      validator: value => ['default', 'danger', 'gray', 'secondary'].includes(value),
    },
    ariaLabel: {
      type: String,
      default: null,
    },
    withArrow: {
      type: Boolean,
      default: false,
    },
    disableHover: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },
};
