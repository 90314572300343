














































































































































































































































import { defineComponent, onBeforeUnmount, ref, watch, watchEffect } from '@nuxtjs/composition-api';
import { useBrands } from '~/features/brands';
import { Category, useMegaMenuCategories } from '~/features/categories';
import { useEvent, useEventBus } from '~/features/events';
import { useRouter } from '~/features/router';
import { useStoreConfig } from '~/features/storeConfig';
import { Brand } from '~/graphql-types.gen';

type MenuItemSelection = {
  type: 'Category' | 'Brand';
  payload: Brand | Category;
};

export default defineComponent({
  name: 'CategoryDialog',
  setup() {
    const isOpen = ref(false);
    const { megaMenuCategories } = useMegaMenuCategories();
    const { hasProductDeals } = useStoreConfig();
    const { redirect, route } = useRouter();
    const selectedMenuItem = ref<MenuItemSelection | null>(null);
    const { brands } = useBrands({ pageSize: 200 });
    const { emit } = useEventBus();

    useEvent('TOGGLE_CATEGORY_MENU', () => {
      emit('DEACTIVATE_ACCOUNT_ICON');
      isOpen.value = !isOpen.value;
    });

    function close() {
      emit('DEACTIVATE_CATEGORY_ICON');
      isOpen.value = false;
    }

    function navigateTo(url: string) {
      close();
      redirect(url);
    }
    watch(route, () => {
      close();
    });

    watchEffect(() => {
      if (process.server) {
        return;
      }

      window.document.body.classList.toggle('overflow-hidden', isOpen.value);
    });

    onBeforeUnmount(() => window.document.body.classList.toggle('overflow-hidden', false));

    return {
      isOpen,
      close,
      selectedMenuItem,
      navigateTo,
      brands,
      categories: megaMenuCategories.value.map(category => {
        // Filter out children that should not be included in menu
        return {
          ...category,
          children: category.children?.filter(child => child.includeInMenu),
        };
      }),
      hasProductDeals,
    };
  },
});
