import PubSub from 'pubsub-js';

export const topics = {
  auth: {
    identify: 'auth:identify',
    logout: 'auth:logout',
  },
  cart: {
    fetchCustomerCart: 'cart:CustomerCart',
    cartMerged: 'cart:CartMerged',
    placeOrder: 'cart:placeOrder',
    isPlacingOrder: 'cart:isPlacingOrder',
    placingOrderError: 'cart:placingOrderError',
  },
};
export function useChannels() {
  return {
    subscribe: PubSub.subscribe,
    push: PubSub.publish,
    unsubscribe: PubSub.unsubscribe,
  };
}
