




















import { defineComponent, nextTick, onMounted, ref, toRef, watch } from '@nuxtjs/composition-api';
// eslint-disable-next-line import/no-named-as-default
import Swiper from 'swiper';

export default defineComponent({
  name: 'CompareMenuItemSlider',
  props: {
    items: {
      type: Array,
      required: true,
    },
    arrows: {
      type: Boolean,
      default: true,
    },
    itemsPerRowOnDesktop: {
      type: Number,
      default: 4,
    },
    itemsPerRowOnMobile: {
      type: Number,
      default: 2.2,
    },
    alignment: {
      type: String,
      default: 'start',
      validator: (value: string) => ['start', 'center', 'end'].includes(value),
    },
  },
  setup(props) {
    const swiperRef = ref(null);
    const isSliderLoaded = ref(false);
    const items = toRef(props, 'items');
    const swiperInstance = ref<Swiper>();

    onMounted(async () => {
      const { default: Swiper, Navigation } = await import('swiper');
      Swiper.use([Navigation]);
      // @ts-ignore
      await import('swiper/swiper-bundle.css');

      isSliderLoaded.value = true;
      await nextTick();

      swiperInstance.value = new Swiper(swiperRef.value || '', {
        slidesPerView: props.itemsPerRowOnMobile,
        spaceBetween: 10,
        slidesOffsetBefore: 20,
        breakpoints: {
          '768': {
            slidesPerView: 3,
            spaceBetween: 20,
            centeredSlidesBounds: false,
            centerInsufficientSlides: false,
            slidesPerGroup: 4.4,
            slidesOffsetBefore: 0,
          },
          '1024': {
            slidesPerView: props.itemsPerRowOnDesktop,
            spaceBetween: 20,
            centeredSlidesBounds: true,
            centerInsufficientSlides: !!(props.alignment === 'center'),
            slidesPerGroup: 5,
            slidesOffsetBefore: 0,
          },
        },
      });

      watch(items, () => {
        swiperInstance?.value?.updateSlides();
      });
    });

    return {
      swiperRef,
      isSliderLoaded,
    };
  },
});
