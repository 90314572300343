import { reactive, computed, toRefs, ref, ComputedRef, watch, onMounted, onUnmounted } from '@nuxtjs/composition-api';
import { CombinedError, useMutation, useQuery } from 'villus';
import { isEmpty, isNumber, uniqBy } from 'lodash-es';
import { useRouter } from '../router';
import { mapProductListing } from '../products';
import { useExceptions } from '../exceptions';
import { useAuth } from '../auth';
import { useSsrCoreApp } from '../ssrControl';
import { isIdenticalAddresses } from '../addressBook';
import { useEventBus } from '../events';
import { TRACKING_EVENTS } from '../trackingHandlers';
import { CartControl } from '../products/utils';
import {
  resolveCartItemPrice,
  resolveCartOptions,
  resolveCartRouteOptions,
  resolveGiftOptionId,
  resolveGiftMessage,
  resolveCartTotal,
  resolveCartSubtotal,
  resolveCartItems,
  resolveAppliedRewardPoints,
  resolveCartPromotions,
  resolveCarPriceRuleDiscounts,
  PromotionOption,
  SpinTheWheelData,
  resolveSpinTheWheelData,
} from './utils';
import { Flags } from './conf';
import { sleep } from '~/utils/async';

import { useAlerts } from '~/features/alerts';
import { useI18n } from '~/features/i18n';
import { topics, useChannels } from '~/features/channels';
import { MaybeReactive, Unpacked } from '~/types/utils';
import { resolveProductPrice } from '~/utils/product';
import { toNonNullable, PartialRecord, extractValue } from '~/utils/collections';
import useCookies from '~/features/cookies';
import {
  AddConfigurableItemDocument,
  AddItemDocument,
  ApplyCouponToCartDocument,
  ApplyCouponToCartMutation,
  ApplyRewardPointsToCartDocument,
  CreateCartDocument,
  CustomerCartDocument,
  GuestCartDocument,
  MergeCartsDocument,
  PaymentMethodsDocument,
  RemoveCouponFromCartDocument,
  RemoveItemDocument,
  SetBillingAddressDocument,
  SetPaymentMethodOnCartDocument,
  SetShippingAddressDocument,
  SetShippingMethodDocument,
  UpdateItemDocument,
  SetGuestEmailOnCartDocument,
  GuestPaymentMethodsDocument,
  RemoveRewardPointsFromCartDocument,
  AddCustomOptionsToCartItemsDocument,
  RemoveCartItemsCustomOptionsDocument,
  UpdateCartItemsCustomOptionsDocument,
  AddNoteToCartDocument,
  SetWalletPaymentMethodDocument,
  SetOrderSourceOnCartDocument,
  GuestCartCrossSellProductsDocument,
  CustomerCartCrossSellProductsDocument,
  SpinTheWheelDocument,
  ApplyGiftCardToCartDocument,
  RemoveGiftCardFromCartDocument,
  AddGiftCardToCartDocument,
  RecoverAbandonedCartDocument,
  UnsubcribeEmailFromAbandonedDocument,
} from '@/graphql/Cart';
import {
  AvailableShippingMethod,
  CartAddressInput,
  CartItemInput,
  ConfigurableProductCartItemInput,
  CustomizableOptionInput,
  EnteredOptionInput,
  GiftCardProductCartItemInput,
  Money,
  SelectedPaymentMethod,
  ShippingCartAddress,
  ShippingMethodInput,
  AppliedMwGiftCards,
} from '~/graphql-types.gen';
import { CartDataFragment, CartItems_ConfigurableCartItem_Fragment } from '~/graphql/fragments';
import errorsObj from '~/exceptions';

import type { BillingAddressInput, PickupLocation } from '@/graphql-types.gen';

type EligibleGift = {
  quantity: number;
  sku: string;
  uid: string;
};

export type CartPriceRuleTier = {
  discountValue: number;
  isApplied: boolean;
  subtotalValue: number;
};

export type CartItem = {
  id: string;
  quantity: number;
  sku: string;
  name?: string;
  brand?: string;
  price?: number;
  unitPrice?: number;
  oldPrice?: number;
  isPendingRemoval?: boolean;
  stock?: number;
  image?: {
    src: string;
    alt: string;
  };
  isNew?: boolean;
  mainCategory?: string;
  totalPrice?: number;
  isGift: boolean;
  giftingItemUid?: string;
  eligibleGift?: EligibleGift;
  productGift?: CartItem;
  isInvalidItem?: boolean;
  mageWorxFormData?: {
    mail_to_email?: string | null;
    mail_from?: string | null;
    mail_to?: string | null;
    mail_message?: string | null;
  };
};

type CartRewardPoint = {
  money: number;
  points: number;
};

export const cartState: {
  cartId: string;
  items: CartItem[] | [];
  productGifts: CartItem[] | [];
  cartGifts: CartItem[] | [];
  total: number;
  subtotal: number;
  address: Partial<ShippingCartAddress> | undefined | null;
  storeLocation: undefined | null | PickupLocation;
  shippingFees: undefined | number;
  itemCount: number;
  discounts: [] | { label: string; value: number }[];
  appliedCoupon: string | null | undefined;
  discount: Money | null;
  appliedRewardPoints: CartRewardPoint | undefined | null;
  iframeUrl: String | null;
  selectedPaymentMethod: SelectedPaymentMethod | undefined;
  cartPromotions: {
    options: Array<Partial<PromotionOption> | undefined | null> | undefined | null;
    eligiblePromotionIdx: number;
    isSpinTheWheel: boolean;
  };
  cartPriceRuleDiscounts: {
    tiers: Array<CartPriceRuleTier>;
    eligibleTierIdx: number;
  };
  spinTheWheelData: SpinTheWheelData | null;
  appliedGiftCard: AppliedMwGiftCards | undefined | null;
  is_virtual: boolean | undefined;
  isCartVerified: boolean | undefined;
  email: string | undefined | null;
  paymentFee: number;
} = reactive({
  cartId: '',
  items: [],
  productGifts: [],
  cartGifts: [],
  total: 0,
  subtotal: 0,
  shippingFees: undefined,
  address: null,
  storeLocation: undefined,
  itemCount: 0,
  discounts: [],
  appliedCoupon: null,
  appliedRewardPoints: undefined,
  discount: null,
  iframeUrl: null,
  selectedPaymentMethod: undefined,
  cartPromotions: {
    options: undefined,
    eligiblePromotionIdx: 0,
    isSpinTheWheel: false,
  },
  cartPriceRuleDiscounts: {
    tiers: [],
    eligibleTierIdx: 0,
  },
  spinTheWheelData: null,
  appliedGiftCard: undefined,
  is_virtual: false,
  isCartVerified: false,
  email: '',
  paymentFee: 0,
});

export const MemoryGameModal = ref();
export const memoryGameOpen = ref(false);
export const isFetchingCart = ref<boolean>(false);
export const cartFetchingState = reactive({
  paymentFetching: false,
});

const recoverCartToken = ref<string>('');
const paymentMethodFees = ref<number>(0);

type CartSetupOptions = PartialRecord<Flags, boolean>;

function useCustomerCart() {
  const { execute, isFetching } = useQuery({
    query: CustomerCartDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });
  const { route } = useRouter();
  const { subscribe, unsubscribe } = useChannels();

  async function getCart(opts: CartSetupOptions = {}) {
    const { data, error } = await execute({
      variables: {
        ...resolveCartOptions('customerCart'),
        ...opts,
      },
    });

    if (error) {
      throw new Error(error.message);
    }

    if (data?.cart) {
      patchCartState(mapCartState(data?.cart));
    }

    const unwatch = watch(
      () => route.value,
      async newRoute => {
        const option = resolveCartRouteOptions('customerCart', newRoute);
        if (!isEmpty(option)) {
          await getCart(option);
          unwatch();
        }
      }
    );

    subscribe(topics.auth.logout, () => {
      if (process.browser) {
        unwatch();
        unsubscribe(topics.auth.logout);
      }
    });
  }

  watch(isFetching, value => {
    isFetchingCart.value = value;
  });

  return {
    getCart,
  };
}

function useGuestCart() {
  const { execute, isFetching } = useQuery({
    query: GuestCartDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });
  const { route } = useRouter();
  const { resetCart } = useResetCart();
  const { subscribe } = useChannels();
  const { resolveException } = useExceptions('cart');

  async function getCart(opts: CartSetupOptions = {}) {
    const { data, error } = await execute({
      variables: {
        cartId: cartState.cartId,
        ...resolveCartOptions('guestCart'),
        ...opts,
      },
    });

    if (error && resolveException(error).message === errorsObj.cart.invalidCartStore.key) {
      resetCart();
    }

    if (error && resolveException(error).level === 'DANGER') {
      throw new Error(error.message);
    }

    if (data?.cart) {
      patchCartState(mapCartState(data?.cart));
    }

    const unwatch = watch(
      () => route.value,
      async newRoute => {
        const option = resolveCartRouteOptions('guestCart', newRoute);
        if (!isEmpty(option)) {
          await getCart(option);
          unwatch();
        }
      }
    );

    subscribe(topics.auth.identify, () => {
      unwatch();
      // unsubscribe(topics.auth.identify);
    });
  }
  watch(isFetching, value => {
    isFetchingCart.value = value;
  });

  return {
    getCart,
  };
}

/**
 * Gets verification status of guest cart
 */
export function useGuestCartVerification() {
  const { execute } = useQuery({
    query: GuestCartDocument,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  async function updateVerificationState() {
    const { data, error } = await execute({
      variables: {
        cartId: cartState.cartId,
      },
    });

    if (error) {
      throw new Error(error.message);
    }

    if (data?.cart) {
      patchCartState(mapCartState(data?.cart));
    }
  }

  return {
    updateVerificationState,
  };
}

/**
 * @description Refreshes cart state when needed
 */
export function useCartRefresh() {
  const { isLoggedIn } = useAuth();
  const { getCart: getGuestCart } = useGuestCart();
  const { getCart: getCustomerCart } = useCustomerCart();

  function refresh() {
    if (isLoggedIn.value) {
      getCustomerCart(resolveCartOptions('customerCart'));
    } else {
      getGuestCart(resolveCartOptions('guestCart'));
    }
  }

  return {
    refresh,
  };
}

/**
 *
 * @description use cart set up function
 */
export function useSetupCart(cookies?: Record<string, any>) {
  const { push, subscribe, unsubscribe } = useChannels();
  const { getCart: getGuestCart } = useGuestCart();
  const { getCart: getCustomerCart } = useCustomerCart();
  const { mergeCartItems } = useMergeCartItems();
  const { route } = useRouter();

  if (cookies && cookies.cart && !cookies['is-secured']) {
    cartState.cartId = cookies.cart;
    getGuestCart(resolveCartRouteOptions('guestCart', route.value));
  }

  subscribe(topics.auth.identify, async () => {
    if (process.browser) {
      await getCustomerCart(resolveCartRouteOptions('customerCart', route.value));

      push(topics.cart.fetchCustomerCart);

      const { cookies } = useCookies();

      if (cookies && cookies.cart) {
        await mergeCartItems(cookies.cart);
        push(topics.cart.cartMerged);
      }
    }
  });

  function destroy() {
    unsubscribe(topics.auth.identify);
  }

  return { destroy };
}

/**
 *
 * @description get cart promotions for spin the wheel
 */
export function useCartWheelGifts() {
  const wheelPromotions = ref<Object>();
  const { prepareCart } = usePrepareCart();

  const { isLoggedIn } = useAuth();
  const { waitForPageToBeLoaded } = useSsrCoreApp();

  const { execute: guestCartPromotions, isFetching: isFetchingGuestGifts } = useQuery({
    query: GuestCartDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  const { execute: customerCartPromotions, isFetching: isFetchingCustomerGifts } = useQuery({
    query: CustomerCartDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  onMounted(async () => {
    await waitForPageToBeLoaded();
    await prepareCart();

    if (isLoggedIn.value) {
      const { data } = await customerCartPromotions({
        variables: {
          ...resolveCartOptions('wheelCartGifts'),
        },
      });

      const mappedCart = mapCartState(data?.cart);
      patchCartState(mappedCart);
      wheelPromotions.value = mappedCart.cartPromotions;
    }

    if (!isLoggedIn.value) {
      const { data } = await guestCartPromotions({
        variables: {
          cartId: cartState.cartId,
          ...resolveCartOptions('wheelCartGifts'),
        },
      });

      const mappedCart = mapCartState(data?.cart);
      patchCartState(mappedCart);
      wheelPromotions.value = mappedCart.cartPromotions;
    }
  });

  return {
    wheelPromotions,
    isFetchingGifts: computed(() => isFetchingGuestGifts.value || isFetchingCustomerGifts.value),
  };
}

/**
 *
 * Merge Cart Items
 * ----------------
 */

const isMergingCart = ref<Boolean>(false);

// merge carts when user successfully login
export function useMergeCartItems() {
  const { execute } = useMutation(MergeCartsDocument);
  const { removeCookie } = useCookies();
  const { success, error: alertError } = useAlerts();
  const { t } = useI18n();

  /**
   * In order to merge items in the cart the user must be logged in
   */
  async function mergeCartItems(sourceId: string) {
    try {
      if (isMergingCart.value) {
        throw new Error('cartIsMerging');
      }

      isMergingCart.value = true;

      const { data, error } = await execute({
        dest: cartState.cartId,
        source: sourceId,
        ...resolveCartOptions('mergeCart'),
      });

      if (error) {
        throw new Error(error.message);
      }

      if (data.response) {
        removeCookie('cart');
        patchCartState(mapCartState(data.response));
      }

      success('success', 'Cart items merged successfully');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);

      if (/\[GraphQL\] The cart isn't active\./.test((e as CombinedError).message)) {
        alertError('error', t('cartIsNotActive').toString());
        // removeCookie('cart');
      }

      if (/\[GraphQL\] Could not find a cart with ID /.test((e as CombinedError).message)) {
        removeCookie('cart');
      }
    } finally {
      isMergingCart.value = false;
    }
  }

  return {
    mergeCartItems,
  };
}

/**
 *
 * Cart Items functions
 * ---------------------------
 */

/**
 * Manages adding items to cart
 */
export function useAddCartItem() {
  const { execute: executeAddingSimpleProduct, isFetching: isFetchingSimplePrduct } = useMutation(AddItemDocument);
  const { execute: executeAddingConfigurableProduct, isFetching: isFetchingConfigurableProduct } = useMutation(
    AddConfigurableItemDocument
  );
  const { execute: executeAddingGiftCardProduct, isFetching: isFetchingGiftCardProduct } = useMutation(
    AddGiftCardToCartDocument
  );
  type SimpleCartInput = CartItemInput | CartItemInput[];
  type ConfigurableCartInput = ConfigurableProductCartItemInput | ConfigurableProductCartItemInput[];

  const { prepareCart } = usePrepareCart();
  const { resetCart } = useResetCart();
  const { emit } = useEventBus();
  const { error: errorAlert } = useAlerts();
  const { t } = useI18n();

  async function addItem(item: SimpleCartInput) {
    // isAddingItem.value = true;
    await prepareCart();
    const items = Array.isArray(item) ? item : [item];

    const result = await executeAddingSimpleProduct({
      cartId: cartState.cartId,
      items,
      ...resolveCartOptions('addCartItem'),
    });

    if (
      result.error &&
      (/\[GraphQL\] Current user does not have an active cart\./i.test(result.error.message) ||
        /The current user cannot perform operations on cart "(.*)"/gm.test(result.error.message) ||
        /\[GraphQL\] Could not find a cart with ID "(.*)"/gm.test(result.error.message) ||
        /\[GraphQL\] The cart isn't active\./i.test(result.error.message))
    ) {
      resetCart();
      await addItem(item);
      return;
    }

    if (result.error) {
      throw new Error(result.error.message);
    }

    if (result.data?.response) {
      if (result.data?.response?.user_errors?.length > 0) {
        result.data?.response?.user_errors.forEach(error => {
          errorAlert(t('stockError').toString(), error?.message || '');
        });
        throw new Error('Cart error');
      }
      patchCartState(mapCartState(result.data.response.cart));

      for (const item of items) {
        const cartItem = getCartItem(item.sku);

        if (!cartItem) {
          return;
        }

        emit(TRACKING_EVENTS.ADD_TO_CART, { ...cartItem, currency: 'EGP' });
      }
    }
  }

  async function addConfigurableProductItem(item: ConfigurableCartInput) {
    // isAddingItem.value = true;
    await prepareCart();
    const items = Array.isArray(item) ? item : [item];

    const result = await executeAddingConfigurableProduct({
      cartId: cartState.cartId,
      items,
      ...resolveCartOptions('addCartItem'),
    });

    if (
      result.error &&
      (/\[GraphQL\] Current user does not have an active cart\./i.test(result.error.message) ||
        /The current user cannot perform operations on cart "(.*)"/gm.test(result.error.message) ||
        /\[GraphQL\] Could not find a cart with ID "(.*)"/gm.test(result.error.message) ||
        /\[GraphQL\] The cart isn't active\./i.test(result.error.message))
    ) {
      resetCart();
      await addConfigurableProductItem(item);
      return;
    }

    if (result.error) {
      throw new Error(result.error.message);
    }

    if (result.data?.response) {
      patchCartState(mapCartState(result.data.response.cart));

      for (const item of items) {
        const cartItem = getCartItem(item.data.sku);

        if (!cartItem) {
          return;
        }

        emit(TRACKING_EVENTS.ADD_TO_CART, { ...cartItem, currency: 'EGP' });
      }
    }
  }

  async function addGiftCardItem(item: GiftCardProductCartItemInput) {
    await prepareCart();
    const items = Array.isArray(item) ? item : [item];

    const result = await executeAddingGiftCardProduct({
      cartId: cartState.cartId,
      items,
      ...resolveCartOptions('addCartItem'),
    });

    if (
      result.error &&
      (/\[GraphQL\] Current user does not have an active cart\./i.test(result.error.message) ||
        /The current user cannot perform operations on cart "(.*)"/gm.test(result.error.message) ||
        /\[GraphQL\] Could not find a cart with ID "(.*)"/gm.test(result.error.message) ||
        /\[GraphQL\] The cart isn't active\./i.test(result.error.message))
    ) {
      resetCart();
      await addGiftCardItem(item);
      return;
    }

    if (result.error) {
      throw new Error(result.error.message);
    }

    if (result.data?.response) {
      patchCartState(mapCartState(result.data.response.cart));

      for (const item of items) {
        const cartItem = getCartItem(item.data.sku);

        if (!cartItem) {
          return;
        }

        emit(TRACKING_EVENTS.ADD_TO_CART, { ...cartItem, currency: 'EGP' });
      }
    }
  }

  type AddItem = {
    sku: string;
    quantity: number;
    parentSku?: string | null;
    entered_options?: Array<EnteredOptionInput>;
    customizable_options?: Array<CustomizableOptionInput>;
  };

  async function add(items: AddItem | AddItem[]) {
    const input = (Array.isArray(items) ? items : [items]).filter(item => !!item.quantity);
    if (!input.length) return;

    if (input[0].parentSku) {
      // handle configurable product item adding to cart
      await addConfigurableProductItem(
        input.map<ConfigurableProductCartItemInput>(i => {
          return {
            data: {
              quantity: i.quantity,
              sku: i.sku,
              parent_sku: i.parentSku,
            },
            parent_sku: i.parentSku,
            customizable_options: i.customizable_options,
          };
        })
      );

      return;
    }

    // handle simple product item adding to cart

    await addItem(
      input.map<CartItemInput>(i => {
        return {
          quantity: i.quantity,
          sku: i.sku,
          entered_options: i.entered_options,
        };
      })
    );
  }

  return {
    addItem,
    addConfigurableProductItem,
    addGiftCardItem,
    add,
    isFetching: computed(
      () => isFetchingConfigurableProduct.value || isFetchingSimplePrduct.value || isFetchingGiftCardProduct.value
    ),
  };
}

/**
 * Updates a Cart Item quantity
 */
export function useUpdateCartItem(id: MaybeReactive<string>) {
  const { execute, isFetching } = useMutation(UpdateItemDocument);

  const { route } = useRouter();

  async function updateItem(quantity: number) {
    const idValue = (id as ComputedRef<string>).value ? (id as ComputedRef<string>).value : id;

    const item = cartState.items.find(i => idValue === i.id);

    if (!item) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn(`Could not find product with ${idValue} in cart`);
      }

      return;
    }

    const { data, error } = await execute({
      input: {
        cart_id: cartState.cartId,
        cart_items: [
          {
            cart_item_uid: (id as ComputedRef<string>).value ? (id as ComputedRef<string>).value : (id as string),
            quantity,
          },
        ],
      },
      ...resolveCartOptions('updateCartItem'),
      ...resolveCartRouteOptions('updateCartItem', route.value),
    });

    if (data.response?.cart) {
      patchCartState(mapCartState(data.response.cart));
    }
    if (error) {
      throw error;
    }
  }

  return {
    updateItem,
    isFetching,
  };
}

/**
 * Removes a cart item
 */
export function useRemoveCartItem(uid: string) {
  const { execute, isFetching: isRemoving } = useMutation(RemoveItemDocument);
  const { route } = useRouter();
  const { emit } = useEventBus();

  async function removeItem() {
    const item = cartState.items.find(i => String(i.id) === uid);
    if (!item) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn(`Could not find product with ${uid} in cart`);
      }
    }

    // Remove gift item first if any
    if (item?.productGift) {
      await execute({
        input: {
          cart_id: cartState.cartId,
          cart_item_uid: item.productGift.id,
        },
        ...resolveCartOptions('removeCartItem'),
        ...resolveCartRouteOptions('removeCartItem', route.value),
      });
    }

    const result = await execute({
      input: {
        cart_id: cartState.cartId,
        cart_item_uid: uid,
      },
      ...resolveCartOptions('removeCartItem'),
      ...resolveCartRouteOptions('removeCartItem', route.value),
    });

    if (result.data.response?.cart) {
      patchCartState(mapCartState(result.data.response.cart));

      // Fire GTM event for cart item removal
      emit(TRACKING_EVENTS.PRODUCT_REMOVE_FROM_CART, item);
    }

    if (result.error) {
      throw new Error(result.error.message);
    }
  }

  return { removeItem, isRemoving };
}

export function useUpdateProductStepItem(
  sku: MaybeReactive<string>,
  parentSku?: MaybeReactive<string | null>,
  options?: MaybeReactive<{
    entered_options?: Array<EnteredOptionInput>;
    customizable_options?: Array<CustomizableOptionInput>;
  }>
) {
  const { cartItemCount, itemId } = useCartItemCount(sku);
  const { updateItem } = useUpdateCartItem(itemId);
  const { add } = useAddCartItem();

  const { warn } = useAlerts();
  const { t } = useI18n();

  const isUpdatingQuantity = ref(false);

  async function updateCartQty(qty: number) {
    try {
      if (!cartItemCount.value && qty < 0) throw new Error(t('qtyUnavailable').toString());
      isUpdatingQuantity.value = true;
      !cartItemCount.value
        ? await add({
            quantity: qty,
            sku: extractValue(sku),
            parentSku: extractValue(parentSku),
            entered_options: extractValue(options)?.entered_options,
            customizable_options: extractValue(options)?.customizable_options,
          })
        : await updateItem(qty);
    } catch (e) {
      if ((e as CombinedError).message.includes('requested qty is not available')) {
        warn('stock', t('outOfStock').toString());
      }
      throw e;
    } finally {
      isUpdatingQuantity.value = false;
    }
  }

  return {
    updateCartQty,
    isUpdatingQuantity,
  };
}

/**
 *
 * Cart Attributes getter functions
 * ---------------------------------
 *
 */
export function useCartAttributes() {
  const count = computed(() => {
    return cartState.itemCount;
  });
  return {
    ...toRefs(cartState),
    ...toRefs(cartFetchingState),
    cartSkus: computed(() => cartState.items.map(({ sku }: any) => sku)),
    isFetchingCart,
    count,
    paymentMethodFees,
    isStorePickup: computed(
      () =>
        cartState.storeLocation && cartState.address && isIdenticalAddresses(cartState.address, cartState.storeLocation)
    ),
    hasInvalidItems: computed(() => cartState.items.some(item => item.isInvalidItem)),
  };
}

export function getCartItem(sku: string) {
  return cartState.items.find(i => sku === i.sku);
}

export function getCartItems() {
  return cartState.items;
}

export function useCartItemCount(sku: MaybeReactive<string>) {
  return {
    cartItemCount: computed(() => cartState.items.find(item => item.sku === extractValue(sku))?.quantity),
    cartItemTotalPrice: computed(() => cartState.items.find(item => item.sku === extractValue(sku))?.totalPrice),
    itemId: computed(() => cartState.items.find(item => item.sku === extractValue(sku))?.id || ''),
    item: computed(() => cartState.items.find(item => item.sku === extractValue(sku)) || ''),
  };
}

/**
 *
 * Cart Creation Api functions
 * ----------------------------
 */

/**
 * Creates a new cart id if its not already created
 */
function usePrepareCart() {
  const { execute } = useMutation(CreateCartDocument);
  const { setCookie } = useCookies();

  async function prepareCart() {
    if (cartState.cartId) {
      return;
    }

    const { data, error } = await execute();
    if (error) {
      throw new Error(error.message);
    }

    if (data.response) {
      patchCartState({ cartId: data.response || '' });
      setCookie('cart', data.response, { expires: 2 });
    }
  }

  return {
    prepareCart,
  };
}

export function useResetCart() {
  const { removeCookie } = useCookies();
  function resetCart() {
    clearCart();
    removeCookie('cart');
  }

  return {
    resetCart,
  };
}

/**
 * Cart Coupons
 */

export function useApplyPromocode() {
  const { execute, isFetching } = useMutation(ApplyCouponToCartDocument);
  const { cookies } = useCookies();
  const { resolveException } = useExceptions('cart');

  const promocodeData = ref<ApplyCouponToCartMutation | null>(null);
  const promocodeError = ref<CombinedError | null>(null);

  if (cookies.cart) {
    cartState.cartId = cookies.cart;
  }

  async function applyCoupon(code: string) {
    const { data, error } = await execute({
      input: { cart_id: cartState.cartId, coupon_code: code },
      ...resolveCartOptions('applyCouponToCart'),
    });

    if (error && resolveException(error).level === 'DANGER') {
      throw new Error(resolveException(error).message);
    }

    patchCartState(mapCartState(data?.response?.cart));

    promocodeData.value = data;
    promocodeError.value = error;
    return {
      promocodeData,
      promocodeError,
    };
  }
  return {
    isFetching,
    applyCoupon,
  };
}

export function useRemovePromocode() {
  const { execute, isFetching } = useMutation(RemoveCouponFromCartDocument);
  const { resolveException } = useExceptions('cart');

  async function removeCode() {
    const { data, error } = await execute({
      input: { cart_id: cartState.cartId },
      ...resolveCartOptions('removeCouponToCart'),
    });

    if (error && resolveException(error).level === 'DANGER') {
      throw new Error(resolveException(error).message);
    }
    patchCartState(mapCartState(data?.response?.cart));

    return { data, error };
  }

  return {
    removeCode,
    isFetching,
  };
}

/**
 * Cart Gift Cards
 */

export function useApplyGiftCard() {
  const { execute, isFetching } = useMutation(ApplyGiftCardToCartDocument);

  async function applyGiftCard(code: string) {
    const { data, error } = await execute({
      input: { cart_id: cartState.cartId, gift_card_code: code },
      ...resolveCartOptions('applyGiftCardToCart'),
    });

    if (error && /\[GraphQL\] Requested Gift Card doesn't exist/gm.test(error.message)) {
      throw new Error('invalidGiftCard');
    }

    if (
      error &&
      new RegExp(`\\[GraphQL\\] Gift Card ${code} is not enabled. Current status: Used`, 'gm').test(error.message)
    ) {
      throw new Error('giftCardNoBalance');
    }

    if (error && /\[GraphQL\] This Gift Card is already in the Quote./gm.test(error.message)) {
      throw new Error('usedGiftCard');
    }

    if (
      error &&
      new RegExp(`\\[GraphQL\\] Gift Card ${code} is not enabled. Current status: Inactive`, 'gm').test(error.message)
    ) {
      throw new Error('giftCardNotActive');
    }

    patchCartState(mapCartState(data?.response?.cart));

    const giftCardPaymentMethods = computed(() => data.response?.cart?.available_payment_methods);

    return { data, error, giftCardPaymentMethods };
  }
  return {
    isFetching,
    applyGiftCard,
  };
}

export function useRemoveGiftCard() {
  const { execute, isFetching } = useMutation(RemoveGiftCardFromCartDocument);
  const { resolveException } = useExceptions('cart');

  async function removeGiftCard(code: string) {
    const { data, error } = await execute({
      input: { cart_id: cartState.cartId, gift_card_code: code },
      ...resolveCartOptions('removeGiftCardFromCart'),
    });

    if (error && resolveException(error).level === 'DANGER') {
      throw new Error(resolveException(error).message);
    }
    patchCartState(mapCartState(data?.response?.cart));

    const giftCardPaymentMethods = computed(() => data.response?.cart?.available_payment_methods);
    return { data, error, giftCardPaymentMethods };
  }
  return {
    isFetching,
    removeGiftCard,
  };
}

/**
 * Reward Points
 */
export function useApplyRewardPoints() {
  const { execute, isFetching } = useMutation(ApplyRewardPointsToCartDocument);
  const { resolveException } = useExceptions('cart');

  /**
   *
   * @param  points
   * @returns
   */
  async function applyRewardPoints() {
    const { data, error } = await execute({
      cartId: cartState.cartId,
      ...resolveCartOptions('applyRewardToCart'),
    });
    if (error && resolveException(error).level === 'DANGER') {
      throw new Error(resolveException(error).message);
    }

    patchCartState(mapCartState(data?.response?.cart));

    return {
      data,
      error,
    };
  }
  return {
    isFetching,
    applyRewardPoints,
  };
}

export function useRemoveRewardPoints() {
  const { execute, isFetching } = useMutation(RemoveRewardPointsFromCartDocument);
  const { resolveException } = useExceptions('cart');

  /**
   *
   * @param  points
   * @returns
   */
  async function removeRewardPoints() {
    const { data, error } = await execute({
      cartId: cartState.cartId,
      ...resolveCartOptions('removeRewardFromCard'),
    });
    if (error && resolveException(error).level === 'DANGER') {
      throw new Error(resolveException(error).message);
    }

    patchCartState(mapCartState(data?.response?.cart));

    return {
      data,
      error,
    };
  }
  return {
    isFetching,
    removeRewardPoints,
  };
}

/**
 * Clears the cart
 */
export function clearCart() {
  patchCartState({
    cartId: '',
    items: [],
    productGifts: [],
    cartGifts: [],
    total: 0,
    subtotal: 0,
    shippingFees: undefined,
    address: null,
    storeLocation: undefined,
    itemCount: 0,
    discounts: [],
    appliedCoupon: null,
    appliedRewardPoints: undefined,
    discount: null,
    iframeUrl: null,
    selectedPaymentMethod: undefined,
    cartPromotions: {
      options: undefined,
      eligiblePromotionIdx: 0,
      isSpinTheWheel: false,
    },
    cartPriceRuleDiscounts: {
      tiers: [],
      eligibleTierIdx: 0,
    },
    spinTheWheelData: null,
    appliedGiftCard: undefined,
    is_virtual: false,
    isCartVerified: false,
  });
}

/**
 *
 * Checkout Cart Operations
 * -------------------------
 */

export function usePaymentMethods(
  options = computed(() => ({
    withIframeEnabled: false,
  }))
) {
  const { isLoggedIn } = useAuth();
  const { isPageLoading } = useSsrCoreApp();

  const { route } = useRouter();

  const {
    execute: executeGuestPaymentMethods,
    data: guestPaymentMethods,
    error: guestPaymentError,
    isFetching: isFetchingGuestPaymentMethod,
  } = useQuery({
    query: GuestPaymentMethodsDocument,
    variables: {
      cartId: cartState.cartId,
    },
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  const {
    execute: executeCustomerPaymentMethods,
    data: customerPaymentMethods,
    error: customerPaymentError,
    isFetching: isFetchingCustomerPaymentMethods,
  } = useQuery({
    query: PaymentMethodsDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  const { execute, isFetching: isSettingPaymentMethod } = useMutation(SetPaymentMethodOnCartDocument);

  async function setPaymentMethod(code: string) {
    const { data } = await execute({
      input: {
        cart_id: cartState.cartId,
        payment_method: {
          code,
        },
      },
      ...resolveCartOptions('setPaymentMethod'),
      ...resolveCartRouteOptions('setPaymentMethod', route.value),
      // TODO refactor this one.
      withExternalParameters: options.value.withIframeEnabled,
    });

    patchCartState(mapCartState(data?.response?.cart));

    return data.response;
  }

  const paymentMethods = computed(
    () => customerPaymentMethods.value?.cart?.paymentMethods || guestPaymentMethods.value?.cart?.paymentMethods || []
  );

  watch(
    isSettingPaymentMethod,
    value => {
      cartFetchingState.paymentFetching = value;
    },
    {
      immediate: true,
    }
  );

  onMounted(async () => {
    /**
     * Finish until page loaded
     */

    while (isPageLoading.value) {
      await sleep(200);
    }

    if (isLoggedIn.value) {
      await executeCustomerPaymentMethods();
      return;
    }

    await executeGuestPaymentMethods();
  });

  const isFetchingPaymentMethod = computed(
    () => isFetchingCustomerPaymentMethods.value || isFetchingGuestPaymentMethod.value
  );

  return {
    paymentMethods,
    error: computed(() => customerPaymentError.value || guestPaymentError.value),
    isFetching: computed(() => isPageLoading.value || isFetchingPaymentMethod.value || isSettingPaymentMethod.value),
    setPaymentMethod,
  };
}

export function useEwalletPaymentMethods() {
  const { execute, isFetching: isSettingPaymentMethod } = useMutation(SetWalletPaymentMethodDocument);

  async function setPaymentMethod(phoneNumber: string) {
    const { data } = await execute({
      cartId: cartState.cartId,
      phoneNumber,
    });

    // We fetch only the acceptIframeUrl, so we don't need to mutate other fields on the cart state
    patchCartState({ iframeUrl: data?.response?.cart?.acceptIframeUrl });

    return data.response;
  }

  watch(
    isSettingPaymentMethod,
    value => {
      cartFetchingState.paymentFetching = value;
    },
    {
      immediate: true,
    }
  );

  return {
    isFetching: computed(() => isSettingPaymentMethod.value),
    setPaymentMethod,
  };
}

export function useShippingMethods() {
  const { execute, isFetching } = useMutation(SetShippingMethodDocument);

  const { route } = useRouter();

  const shippingMethods = computed(() => {
    return uniqBy(
      toNonNullable(cartState.address?.available_shipping_methods).map(mapShippingMethod) || [],
      'method_code'
    );
  });

  async function setShippingMethod(input: ShippingMethodInput) {
    const { data, error } = await execute({
      input: {
        cart_id: cartState.cartId,
        shipping_methods: [input],
      },
      ...resolveCartOptions('setShippingMethod'),
      ...resolveCartRouteOptions('setShippingMethod', route.value),
    });

    if (data.response?.cart) {
      patchCartState(mapCartState(data.response?.cart));
    }

    if (error) {
      throw new Error(error.message);
    }

    return {
      data,
      error,
    };
  }

  return {
    setShippingMethod,
    shippingMethods,
    isFetching,
  };
}

export function useSetShippingAddressOnCart() {
  const { execute, isFetching } = useMutation(SetShippingAddressDocument);
  const { route } = useRouter();

  async function setShippingAddressOnCart(address: number | CartAddressInput, storeCode: string = '') {
    const { error } = await execute({
      input: {
        cart_id: cartState.cartId,
        shipping_addresses: [
          isNumber(address)
            ? { customer_address_id: address }
            : { address, ...(storeCode && { pickup_location_code: storeCode }) },
        ],
      },
      ...resolveCartOptions('setCartAddress'),
      ...resolveCartRouteOptions('setCartAddress', route.value),
    });

    if (error) {
      throw new Error(error.message);
    }
  }

  return {
    setShippingAddressOnCart,
    isFetching,
  };
}

export function useSetGuestEmailOnCart() {
  const { execute } = useMutation(SetGuestEmailOnCartDocument);

  async function setGuestEmailOnCart(email: string) {
    const { error, data } = await execute({
      cartId: cartState.cartId,
      email,
    });

    if (error) {
      throw new Error(error.message);
    }

    patchCartState(mapCartState(data?.response?.cart));

    return data;
  }

  return {
    setGuestEmailOnCart,
  };
}

export function useSetBillingAddressOnCart() {
  const { execute, isFetching } = useMutation(SetBillingAddressDocument);
  const { route } = useRouter();

  async function setBillingAddressOnCart(address: CartAddressInput | number | undefined = undefined) {
    /**
     * if this function got an address, that mean the cart is_virtual
     * - if cart virtual no shipping address will be added and add only billing address
     * - if cart is not virtual then set billing address as shipping address
     */
    let billing_address: BillingAddressInput = {
      same_as_shipping: true,
    };

    if (address) {
      billing_address = isNumber(address) ? { customer_address_id: address } : { address };
    }
    const { error, data } = await execute({
      input: {
        cart_id: cartState.cartId,
        billing_address,
      },
      ...resolveCartOptions('setCartAddress'),
      ...resolveCartRouteOptions('setCartAddress', route.value),
    });

    if (error) {
      throw new Error(error.message);
    }

    return data;
  }

  return {
    setBillingAddressOnCart,
    isFetching,
  };
}

export function useSetCartAddress() {
  const { setShippingAddressOnCart, isFetching: shippingAddressLoading } = useSetShippingAddressOnCart();
  const { setBillingAddressOnCart, isFetching: billingAddressLoading } = useSetBillingAddressOnCart();

  async function _setCartAddressById(addressId: number) {
    await setShippingAddressOnCart(addressId);
    const data = await setBillingAddressOnCart();

    if (data.response?.cart) {
      patchCartState(mapCartState(data.response?.cart));
    }

    return data.response;
  }

  async function _setCartAddress(address: CartAddressInput, storeCode: string = '') {
    await setShippingAddressOnCart(address, storeCode);
    const data = await setBillingAddressOnCart();

    if (data.response?.cart) {
      patchCartState(mapCartState(data.response?.cart));
    }

    return data.response;
  }

  async function setCartAddress(address: CartAddressInput | number, storeCode: string = '') {
    if (cartState.is_virtual) {
      await setBillingAddressOnCart(address);
      return;
    }

    if (isNumber(address)) {
      await _setCartAddressById(address);
      return;
    }

    await _setCartAddress(address, storeCode);
  }

  return {
    setCartAddress,
    addressLoading: computed(() => shippingAddressLoading.value || billingAddressLoading.value),
  };
}

/**
 * Updates a Cart Item Customizable Option
 */
export function useCartItemCustomizationOption(id: MaybeReactive<string>) {
  const { execute: executeAddCustomOption } = useMutation(AddCustomOptionsToCartItemsDocument);
  const { execute: executeUpdateCustomOption } = useMutation(UpdateCartItemsCustomOptionsDocument);
  const { execute: executeRemoveCustomOption } = useMutation(RemoveCartItemsCustomOptionsDocument);
  const { route } = useRouter();

  async function addCustomizationOption(optionId: number, message: string) {
    const idValue: string = (id as ComputedRef<string>).value ?? id;

    const result = await executeAddCustomOption({
      cartId: cartState.cartId,
      items: [
        {
          cart_item_uid: idValue,
          customizable_options: [
            {
              value_string: message,
              id: optionId,
            },
          ],
        },
      ],
    });

    if (result.data?.response) {
      patchCartState(mapCartState(result?.data?.response.cart));
    }
  }

  async function updateCustomizationOption(optionId: number, message: string) {
    const idValue: string = (id as ComputedRef<string>).value ?? id;

    const result = await executeUpdateCustomOption({
      cartId: cartState.cartId,
      items: [
        {
          cart_item_uid: idValue,
          customizable_options: [
            {
              value_string: message,
              id: optionId,
            },
          ],
        },
      ],
      ...resolveCartOptions('UpdateCartItemsCustomOptions'),
      ...resolveCartRouteOptions('UpdateCartItemsCustomOptions', route.value),
    });

    if (result.data?.response) {
      patchCartState(mapCartState(result?.data?.response.cart));
    }
  }

  async function removeCustomizationOption(optionId: number) {
    const idValue: string = (id as ComputedRef<string>).value ?? id;

    const result = await executeRemoveCustomOption({
      cartId: cartState.cartId,
      items: [
        {
          cart_item_uid: idValue,
          customizable_options: [
            {
              id: optionId,
              value_string: 'N/A',
            },
          ],
        },
      ],
      ...resolveCartOptions('RemoveCartItemsCustomOptions'),
      ...resolveCartRouteOptions('RemoveCartItemsCustomOptions', route.value),
    });

    if (result.data?.response) {
      patchCartState(mapCartState(result?.data?.response.cart));
    }
  }

  return {
    updateCustomizationOption,
    addCustomizationOption,
    removeCustomizationOption,
  };
}

export function useIsGuestCart() {
  const { isPageLoading } = useSsrCoreApp();
  const { user } = useAuth();

  const { cookies } = useCookies();

  return {
    isGuestCart: computed(() => !isPageLoading.value && cookies.cart && !user.value),
  };
}

export function useAddNoteToCart() {
  const { execute } = useMutation(AddNoteToCartDocument);

  async function addNoteToCart(note: string) {
    const { data, error } = await execute({
      cartId: cartState.cartId,
      note,
    });

    if (error) {
      throw new Error(error.message);
    }

    return {
      data,
      error,
    };
  }

  return {
    addNoteToCart,
  };
}

export function useSetOrderSourceOnCart() {
  const { execute, isFetching: isSettingSource } = useMutation(SetOrderSourceOnCartDocument);

  async function setOrderSourceOnCart(source: string) {
    const { data, error } = await execute({
      input: {
        cart_id: cartState.cartId,
        order_source: source,
      },
      ...resolveCartOptions('SetOrderSourceOnCart'),
    });

    patchCartState(mapCartState(data.response.cart));

    return {
      data,
      error,
    };
  }

  return {
    setOrderSourceOnCart,
    isSettingSource,
  };
}

export function useSpinTheWheel() {
  const { execute, isFetching: isSpinning } = useMutation(SpinTheWheelDocument);

  async function spinTheWheel() {
    const { data, error } = await execute({
      cartId: cartState.cartId,
      ...resolveCartOptions('spinTheWheel'),
    });

    patchCartState(mapCartState(data?.response?.cart));

    return {
      data,
      error,
    };
  }

  return {
    spinTheWheel,
    isSpinning,
  };
}

/**
 *
 * Mapper functions
 * ----------------
 *
 */

function mapCartState(
  cart?: Partial<
    CartDataFragment & { acceptIframeUrl: string | null; is_cart_verified: boolean; email: string | null }
  > | null
): typeof cartState {
  if (!cart) {
    return cartState;
  }

  const { items, cartGifts, productGifts } = resolveCartItems(cart, cartState);

  return {
    cartId: cart.id?.toString() || '',
    total: resolveCartTotal(cart, cartState),
    subtotal: resolveCartSubtotal(cart, cartState),
    // discounts: toNonNullable(cart.prices?.discounts).map(d => ({ value: d.amount.value ?? 0, label: d.label })) ?? [],
    items,
    productGifts: cart.items ? productGifts : cartState.productGifts ?? [],
    cartGifts: cart.items ? cartGifts : cartState.cartGifts ?? [],
    address: (cart.addresses?.[0] as any) || cartState.address || null,
    storeLocation:
      cart.addresses?.[0]?.selected_pickup_location_details === undefined
        ? cartState.storeLocation
        : cart.addresses[0]?.selected_pickup_location_details,
    shippingFees:
      cart.addresses?.[0]?.selected_shipping_method?.amount.value ??
      (typeof cartState.shippingFees === 'undefined' ? undefined : cartState.shippingFees),
    itemCount: cart.total_quantity ?? cartState.itemCount ?? 0,
    discounts: toNonNullable(cart.prices?.discounts).map(d => ({ value: d.amount.value ?? 0, label: d.label })) ?? [],
    /** the promocode applied to the cart */
    appliedCoupon:
      cart?.applied_coupons === null ? null : cart?.applied_coupons?.[0]?.code || cartState.appliedCoupon || undefined,
    /** the discount value after applying the promocode */
    discount: cart.prices?.discounts ? (cart.prices.discounts[0]?.amount as Money) : null,

    appliedRewardPoints: resolveAppliedRewardPoints(cart, cartState),

    iframeUrl: cart?.acceptIframeUrl ?? null,
    selectedPaymentMethod: cart?.selected_payment_method ?? undefined,
    // Get the cart promotion tiers
    cartPromotions: resolveCartPromotions(cart, cartState),
    cartPriceRuleDiscounts: resolveCarPriceRuleDiscounts(cart, cartState),
    spinTheWheelData: resolveSpinTheWheelData(cart, cartState),
    appliedGiftCard:
      cart?.applied_mw_gift_cards === null
        ? null
        : cart?.applied_mw_gift_cards?.[0] || cartState.appliedGiftCard || undefined,
    is_virtual: cart.is_virtual,
    isCartVerified: cart.is_cart_verified || cartState.isCartVerified || undefined,
    email: cart.email || cartState.email || '',
    paymentFee: cart.payment_fee ?? cartState.paymentFee,
  };
}

function patchCartState(newState: Partial<typeof cartState>) {
  Object.keys(newState).forEach(key => {
    (cartState as any)[key] = (newState as any)[key];
  });
}
/**
 * Maps the cart item data returned from the API to be displayed in the cart pages.
 */
export function mapCartItem(apiItem: Unpacked<CartDataFragment['items']>, productGifts?: CartItem[]) {
  const baseProductPrice =
    apiItem && apiItem?.product && apiItem?.product?.special_price && apiItem?.product?.special_to_date
      ? resolveProductPrice(apiItem.product)
      : 0;
  const unitPrice = apiItem?.prices?.price.value || baseProductPrice;
  const totalPrice = apiItem?.prices?.row_total.value || baseProductPrice * (apiItem?.quantity ?? 1);
  // This discount is from cart price rules, not to be mistaken with the discount from the product itself (catalog price rules)
  const totalDiscount = apiItem?.prices?.total_item_discount?.value ?? 0;
  const oldPrice = apiItem?.product?.price_range?.maximum_price?.regular_price.value ?? 0;
  const giftBoxOptionId = apiItem && resolveGiftOptionId(apiItem);
  const giftBoxMessage = apiItem && resolveGiftMessage(apiItem);
  const configurationOptions =
    apiItem?.__typename === 'ConfigurableCartItem'
      ? apiItem?.configurable_options.map(option => {
          return { label: option?.option_label, value: option?.value_label };
        })
      : [];

  const mageWorxFormData =
    apiItem?.__typename === 'MageWorxGiftCardsCartItem'
      ? {
          mail_to_email: apiItem.mail_to_email ?? '',
          mail_from: apiItem?.mail_from ?? '',
          mail_to: apiItem?.mail_to ?? '',
          mail_message: apiItem?.mail_message ?? '',
        }
      : {};

  return {
    type: apiItem?.product.__typename,
    id: apiItem?.uid || '',
    quantity: apiItem?.quantity || 1,
    name: apiItem?.product?.name || '',
    unitPrice,
    totalPrice,
    totalDiscount,
    oldPrice,
    sku: apiItem?.product?.sku || '',
    isPendingRemoval: false,
    slug: apiItem?.product.url_key,
    brand: apiItem?.product?.brand?.name ?? '',
    stock: apiItem?.product && resolveCartItemPrice(apiItem?.product),
    image: {
      src: apiItem?.product.thumbnail?.url || '',
      alt: apiItem?.product.thumbnail?.label ?? (apiItem?.product.name || ''),
    },
    cartControl: {
      max: Number(apiItem?.product?.cart_control?.max_amount) ?? Number.MAX_VALUE, // maximum quantity allowed in the cart
      min: Number(apiItem?.product?.cart_control?.min_amount) ?? 0, // minimum quantity allowed in the cart
      step: Number(apiItem?.product?.cart_control?.increment_step) ?? 1, // quantity step
    } as CartControl,
    url: apiItem?.product.url_key,
    configurationOptions,
    // Use Configurable Cart Item keys to overwrite apiItem?.product keys
    ...(apiItem?.__typename === 'ConfigurableCartItem' ? mapConfiguredVariant(apiItem?.configured_variant) : {}),

    /**
     * Property to check if the cart item is able to be change item quantity
     */
    allowChangeQuantity: checkIfItemIsAbleToChange(apiItem),
    /**
     * Property to show that if the product is available or not
     */
    isInvalidItem: isInvalidItem(apiItem),
    giftBoxOptionId,
    giftBoxMessage,
    isGift: apiItem?.is_gift || false,
    giftingItemUid: apiItem?.gifting_item_uid || '',
    // To display the added gift associated with the product
    productGift: productGifts?.find(gift => gift.giftingItemUid === apiItem?.uid),
    mageWorxFormData,
  };
}

export const GLOBAL_CONFIGURATION = {
  fetchItems: true,
};

function mapConfiguredVariant<T extends CartItems_ConfigurableCartItem_Fragment['configured_variant']>(product: T) {
  return {
    name: product.name || '',
    image: {
      src: product.thumbnail?.url || '',
      alt: product.thumbnail?.label ?? (product.name || ''),
    },
    stock: product.only_x_left_in_stock || 0,
    sku: product.sku || '',
    unitPrice: product?.price_range?.maximum_price?.final_price.value ?? 0,
    oldPrice: product?.price_range?.maximum_price?.regular_price.value ?? 0,
  };
}

/**
 * New Shipping Module Feature Mapper
 */
export type ShippingMethod = {
  name: string; // name of te shipping method
  isActive: boolean; // to show but disable the selection  of the shipping module
  minDays: string | null | undefined; // the min number of days to be delivered within
  maxDays: string | null | undefined; // the max number of days to be delivered
  cutoffTime: string | null | undefined; // the min-max days to serve the order before this value of time !Example: {minDays} {maxDays} for orders before {cutoffTime}
  minSubTotalFreeShipping: string | null | undefined; // the min amount of sub-total to get this shipping method for free
};

export function mapShippingMethod(shippingMethod: AvailableShippingMethod): ShippingMethod & AvailableShippingMethod {
  return {
    ...shippingMethod,
    name: shippingMethod?.method_title ?? '',
    isActive: !shippingMethod?.is_after_cutoff_time,
    minDays: shippingMethod?.min_days ?? null,
    maxDays: shippingMethod?.max_days ?? null,
    cutoffTime: shippingMethod?.cutoff_time ?? null,
    minSubTotalFreeShipping: shippingMethod?.min_subtotal_for_free_shipping ?? null,
  };
}

/**
 *  show or hide item quantity control toggle s
 * @returns
 */
function checkIfItemIsAbleToChange(item: Unpacked<CartDataFragment['items']>): boolean {
  return item?.__typename !== 'BundleCartItem';
}

/**
 *  to display that the item is invalid cart item
 * @returns
 */
function isInvalidItem(item: Unpacked<CartDataFragment['items']>): boolean {
  if (item?.__typename === 'BundleCartItem') {
    return false;
  }

  const stock =
    item?.__typename === 'ConfigurableCartItem'
      ? item?.configured_variant.only_x_left_in_stock || 0
      : item?.product.only_x_left_in_stock || 0;

  return stock < (item?.quantity || 1);
}

export function useCartCrossProducts() {
  const { isLoggedIn } = useAuth();
  const { waitForPageToBeLoaded } = useSsrCoreApp();
  const items = ref();

  const { execute: customerProducts } = useQuery({
    query: CustomerCartCrossSellProductsDocument,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });
  const { execute: guestProducts } = useQuery({
    query: GuestCartCrossSellProductsDocument,
    variables: { cartId: cartState?.cartId },
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  onMounted(async () => {
    await waitForPageToBeLoaded();

    if (isLoggedIn.value) {
      const { data } = await customerProducts();
      items.value = data?.cart?.crossSell?.items?.map(mapProductListing as any);
    }

    if (!isLoggedIn.value && cartState.cartId) {
      const { data } = await guestProducts();
      items.value = data?.cart?.crossSell?.items?.map(mapProductListing as any);
    }
  });

  return { items: computed(() => items.value) };
}

export function useAbandonedCart() {
  const { execute, isFetching } = useMutation(RecoverAbandonedCartDocument);
  const { error } = useAlerts();
  const { subscribe, unsubscribe } = useChannels();
  const { getCart: getGuestCart } = useGuestCart();
  const { isLoggedIn } = useAuth();
  const { t } = useI18n();
  const { route } = useRouter();
  const isRecoverd = ref<boolean>(false);

  recoverCartToken.value = route.value.query.token ? (route.value.query.token as string) : recoverCartToken.value;
  cartState.cartId = route.value.query.id ? (route.value.query.id as string) : cartState.cartId;

  async function recoverAbandonedCart(cartId: string, token: string) {
    try {
      const { data, error } = await execute({
        cartId,
        token,
      });

      if (error) {
        throw new Error(error.message);
      }

      if (data.response) {
        recoverCartToken.value = '';
        isRecoverd.value = data.response;
      }
    } catch (e) {
      if (/\[GraphQL\] The cart isn't active\./.test((e as CombinedError).message)) {
        error(t('cartError').toString(), t('cartIsNotActive').toString());
      }

      if (/\[GraphQL\] Could not find a cart with ID /.test((e as CombinedError).message)) {
        error(t('cartError').toString(), t('noCartFound').toString());
      }
    }
  }

  onMounted(async () => {
    if (!recoverCartToken.value) return;

    if (route.value.query.id && !isLoggedIn.value) {
      await recoverAbandonedCart(route.value.query.id as string, recoverCartToken.value);
      getGuestCart(resolveCartRouteOptions('guestCart', route.value));
      return;
    }

    subscribe(topics.cart.fetchCustomerCart, () => {
      recoverAbandonedCart(cartState.cartId, recoverCartToken.value);
    });
  });

  onUnmounted(() => {
    unsubscribe(topics.cart.fetchCustomerCart);
  });

  return {
    recoverAbandonedCart,
    recoverCartToken,
    isFetching,
    isRecoverd,
  };
}

export function useUnsubscribeFromAbandoned() {
  const { execute, isFetching } = useMutation(UnsubcribeEmailFromAbandonedDocument);
  const { success, error } = useAlerts();
  const { t } = useI18n();

  async function unsubscribeFromAbandoned(emailHash: string) {
    try {
      const { data, error } = await execute({ emailHash });

      if (error) {
        throw new Error(error.message);
      }

      if (data.response) {
        success(t('cartSuccess').toString(), t('unsubscribeSuccess').toString());
      }
    } catch (e) {
      if (/\[GraphQL\] The subscriber has been unsubscribed before\./.test((e as CombinedError).message)) {
        error(t('cartError').toString(), t('unsubscribeError').toString());
      }
    }
  }

  return {
    unsubscribeFromAbandoned,
    isFetching,
  };
}

export function useCartShippingMethods() {
  const { setCartAddress, addressLoading: fetchingShippingMethods } = useSetCartAddress();
  const { isStorePickup } = useCartAttributes();

  function checkCartShippingMethods() {
    if (cartState.is_virtual) return;

    // set a dummy address to get the available shipping methods
    if (!cartState.address?.available_shipping_methods?.length || isStorePickup.value) {
      setCartAddress({
        city: 'SHEIKH_ZAYED',
        firstname: 'Mazaya',
        lastname: 'Mazaya',
        street: ['Mazaya'],
        country_code: 'EG',
        region: 'GZ',
        apartment: '',
        building: '',
        floor: '',
        landmark: '',
        telephone: '01242424242',
        title: '',
      });
    }
  }

  return {
    checkCartShippingMethods,
    fetchingShippingMethods,
  };
}
