export default {
  egp: 'EGP',
  appName: 'Mazaya',
  comparisonExceeded: 'You cannot compare more than 4 items',
  comparisonExceededDescription: 'If you add this item the first one will be removed',
  invalidComparison: 'Products must be of the same sub-category',
  invalidComparisonDescription:
    'You cannot compare products from different categories, adding this item will clear the other comparison items',
  addProduct: 'Add Product',
  validation: {
    required: 'This field is required',
    phone: 'Phone number must contain 11 numbers',
    email: 'This field must be a valid email',
    min: 'This field must be at least {length} characters',
    confirmed: "This field does not match it's confirmation",
    alpha_spaces: 'This field can only contain characters and spaces',
    max_value: 'This field cannot exceed {max}',
    min_value: 'This field cannot exceed {min}',
    full_name: 'This field should contain two parts ',
    phone_length: 'This field should contain only 11 number',
    phone_proper: 'This field must start with 010, 011, 012,015',
    email_phone: 'This field cannot be identified as phone nor email ',
    walletNumberError: 'Invalid wallet number , Enter new valid one ',
    ext: 'Accepted formats: pdf/png/jpg/jpeg',
    gift_card_code: 'Gift card number must contain 13 numbers',
  },
  seo: {
    title: 'Mazaya: Buy Products online',
    description:
      'Welcome to Mazaya in Egypt. ✓ Buy now online & Discover a wide range of home appliances of brands Toshiba، Sharp، Tornado، Sony، Seiko and More. ',
  },
  all: 'All',
  shippingMethods: {
    deliverWithin: 'Delivery within {minDays} - {maxDays} day(s)',
    forOrders: 'for orders before {cutoffTime}',
    freeOver: ' Free Over  ',
  },
  invalidStore: 'This store you trying to pick from is not valid',
  cartSuccess: 'Success',
  cartError: 'Error',
  invalidCoupons: 'This Code is invalid please check and try again later',
  inactiveCoupons: 'This Code is not active',
  'rating&reviews': 'Rating & Reviews',
  partnerBrand: 'Partner Brand',
  home: 'HOMEPAGE',
  stockError: 'Stock error',
  insufficientStock: 'Some of the products in the cart are out of stock',
  loginFirst: 'Oops! You need to log in.',
  money: {
    toBeCalculated: 'to be calculated',
    free: 'Free',
  },
  otpCodeSent: 'OTP code sent successfully',
  loggedOut: 'You are now logged out',
  cartIsNotActive: 'Inactive cart',
  noCartFound: 'No cart found with that ID',
  unsubscribeSuccess: 'Unsubscribed successfully',
  unsubscribeError: 'Already Unsubscribed',
  wrongAddress: 'The shipping address is not correct',
};
