import { ssrRef, UnwrapRef, useContext, useFetch, ref } from '@nuxtjs/composition-api';
import { useMutation, useQuery } from 'villus';
import { isAfter, isBefore } from 'date-fns';
import { useSsrCoreApp } from './ssrControl';
import { mapProductListing } from './products';
import { useAuth } from './auth';
import { CartControl } from './products/utils';
import { ProductDocument, ProductQuery, ViewProductPageDocument } from '~/graphql/Product';
import { RecentlyViewedProductsDocument } from '~/graphql/Customer';
import { Unpacked } from '~/types/utils';
import { resolveProductHasGift, resolveProductPrice, resolveProductStock } from '~/utils/product';
import { sortByPositionAsc, toNonNullable } from '~/utils/collections';
import {
  BundleItemFragment,
  GroupedProductItemFragment,
  MageWorxGiftCards,
  ProductVariantsFragment,
} from '~/graphql/fragments';

export type ProductData = NonNullable<UnwrapRef<ReturnType<typeof useProduct>['product']>>;

export function useProduct(slug: string) {
  const product = ssrRef<ReturnType<typeof mapProduct> | null>(null, `product-${slug}`);
  const { error: redirectToError } = useContext();
  const { execute } = useQuery({
    query: ProductDocument,
    variables: { slug },
    fetchOnMount: false,
  });

  useFetch(async () => {
    const { data, error } = await execute();

    if (error) {
      redirectToError({
        message: error.message,
      });
      return;
    }

    if (!data?.product) {
      redirectToError({
        message: 'Product not found',
      });
      return;
    }

    product.value = mapProduct(data?.product);
  });

  return {
    product,
  };
}

export function useVisitedProduct() {
  const { execute } = useMutation(ViewProductPageDocument);

  async function onVisitProduct(productId: number) {
    return await execute({
      product_id: productId.toString(),
    });
  }

  return {
    onVisitProduct,
  };
}

export function useRecentlyViewedProducts() {
  const { waitForPageToBeLoaded } = useSsrCoreApp();
  const { isLoggedIn } = useAuth();

  const { execute, isFetching } = useQuery({
    query: RecentlyViewedProductsDocument,
    variables: {
      pageSize: 20,
    },
    fetchOnMount: false,
  });

  const items = ref<any[]>([]);
  const isFetchingProducts = ref(true);

  async function fetchProducts() {
    if (!isLoggedIn.value) {
      return;
    }

    isFetchingProducts.value = true;
    await waitForPageToBeLoaded();

    try {
      const { data, error } = await execute();
      if (error) {
        throw new Error(error.message);
      }

      items.value = toNonNullable(data?.customer?.connection?.nodes).map(mapProductListing);

      return items.value;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      throw e;
    } finally {
      isFetchingProducts.value = false;
    }
  }

  return {
    fetchProducts,
    isFetching,
    items,
  };
}

export type ProductNode = NonNullable<Unpacked<NonNullable<ProductQuery['product']>>>;

function mapProduct(apiProduct: ProductNode) {
  if (!apiProduct) {
    throw new Error('Cannot map an empty product');
  }

  const mediaGallery = apiProduct?.media_gallery?.filter(i => i?.url) || undefined;
  // Add the main image to the list if non exists
  if (!mediaGallery?.length && apiProduct.image) {
    mediaGallery?.unshift({ url: apiProduct.image.url, label: apiProduct.image.label });
  }

  const isNew = apiProduct.attributes?.find(attribute => attribute?.key === 'is_new')?.value === 'Yes' ?? false;
  const currentPrice = resolveProductPrice(apiProduct);
  const oldPrice = apiProduct.price_range.maximum_price?.regular_price.value ?? currentPrice;
  const dynamicPrice = (apiProduct.__typename === 'BundleProduct' && apiProduct.dynamic_price) ?? false;

  const configurableGiftBoxOptionId =
    apiProduct.__typename === 'ConfigurableProduct'
      ? apiProduct.options?.find(option => option?.__typename === 'CustomizableFieldOption')?.configurableItemId
      : null;

  const simpleGiftBoxOptionId =
    apiProduct.__typename === 'SimpleProduct'
      ? apiProduct.options?.find(option => option?.__typename === 'CustomizableFieldOption')?.simpleItemUid
      : null;

  const product = {
    // ...apiProduct,
    isNew,
    id: apiProduct.id,
    stock: resolveProductStock(apiProduct) || 0,
    price: currentPrice,
    priceBefore: currentPrice < oldPrice ? oldPrice : undefined,
    media_gallery: mediaGallery,
    special_from_date: undefined,
    special_to_date: undefined,
    special_price: undefined,
    price_range: undefined,
    rating_summary: apiProduct.rating_summary,
    review_count: apiProduct.review_count,
    thumbnail: apiProduct.thumbnail,
    sku: apiProduct.sku,
    image: apiProduct.image,
    categories: apiProduct.categories,
    attributes: apiProduct.attributes,
    short_description: apiProduct.short_description,
    meta_title: apiProduct.meta_title,
    meta_description: apiProduct.meta_description,
    meta_keywords: apiProduct.meta_keywords,
    brand: apiProduct.brand,
    url_key: apiProduct.url_key,
    name: apiProduct.name,
    description: apiProduct.description,
    type: apiProduct.__typename,
    variants: undefined as ReturnType<typeof mapProductVariant>[] | undefined,
    groupItems: undefined as ReturnType<typeof mapProductGroupItem>[] | undefined,
    bundleItems: undefined as ReturnType<typeof mapBundleProductItem>[] | undefined,
    mainCategory: apiProduct.categories?.[0]?.name || '',
    configurationOptions: undefined as NonNullable<ProductVariantsFragment['configurable_options']> | undefined | null,
    dynamicPrice,
    cartControl: {
      max: Number(apiProduct.cart_control.max_amount) ?? Number.MAX_VALUE, // maximum quantity allowed in the cart
      min: Number(apiProduct.cart_control.min_amount) ?? 0, // minimum quantity allowed in the cart
      step: Number(apiProduct.cart_control.increment_step) ?? 1, // quantity step
      unit: apiProduct.cart_control.unit ?? '', // quantity step
    } as CartControl,
    configurableGiftBoxOptionId,
    simpleGiftBoxOptionId,
    heroImage: apiProduct.hero_image,
    hasGift: resolveProductHasGift(apiProduct),
    gift: apiProduct.potential_gift_promotions?.length
      ? {
          name: apiProduct?.potential_gift_promotions?.[0]?.gifts?.items?.[0]?.name,
          image: {
            src: apiProduct?.potential_gift_promotions?.[0]?.gifts?.items?.[0]?.thumbnail?.url,
            alt: apiProduct?.potential_gift_promotions?.[0]?.gifts?.items?.[0]?.thumbnail?.label,
          },
        }
      : null,
    giftCardPriceOptions: undefined as MageWorxGiftCards['mageworx_gc_additional_price'] | undefined,
    giftCardType: undefined as MageWorxGiftCards['mageworx_gc_type'] | undefined,
    giftCardFromName: '' as string | undefined,
    giftCardToName: '' as string | undefined,
    giftCardToEmail: '' as string | undefined,
    giftCardMessage: '' as string | undefined,
  };

  if (apiProduct.__typename === 'ConfigurableProduct' && apiProduct.variants?.length) {
    product.variants = toNonNullable(apiProduct.variants).map(variant => mapProductVariant(variant, product as any));

    product.configurationOptions = apiProduct?.configurable_options;
  }

  if (apiProduct.__typename === 'GroupedProduct' && apiProduct.items?.length) {
    product.groupItems = toNonNullable(apiProduct.items.sort(sortByPositionAsc)).map(mapProductGroupItem);
  }

  if (apiProduct.__typename === 'BundleProduct' && apiProduct.items?.length) {
    product.bundleItems = toNonNullable(apiProduct.items.sort(sortByPositionAsc)).map(mapBundleProductItem);
  }

  if (apiProduct.__typename === 'MageWorxGiftCards') {
    // Handle attributes specific to MageWorxGiftCards
    product.giftCardPriceOptions = apiProduct.mageworx_gc_additional_price?.map(priceOption => ({
      label: priceOption?.value?.toLocaleString() + ' ' + 'EGP',
      value: priceOption?.value,
    }));

    product.giftCardType = apiProduct.mageworx_gc_type;
  }

  delete product.special_from_date;
  delete product.special_from_date;
  delete product.special_price;
  delete product.price_range;

  return product;
}

function mapProductVariant(apiProduct: NonNullable<ProductVariantsFragment['variants']>[number], parent: ProductNode) {
  const newFrom = new Date(apiProduct?.product?.new_from_date || 0);
  const newTo = new Date(apiProduct?.product?.new_to_date || 0);
  const now = new Date();
  const isNew = isAfter(newTo, now) && isBefore(newFrom, now);
  const currentPrice = (apiProduct?.product && resolveProductPrice(apiProduct?.product)) || 0;
  const oldPrice = apiProduct?.product?.price_range.maximum_price?.regular_price.value ?? currentPrice;

  const mediaGallery = apiProduct?.product?.media_gallery?.filter(i => i?.url) || [];
  // Add the main image to the list if non exists
  if (!mediaGallery.length && apiProduct?.product?.image) {
    mediaGallery.unshift({ url: apiProduct?.product?.image.url, label: apiProduct?.product.image.label });
  }

  const product = {
    id: apiProduct?.product?.id,
    name: apiProduct?.product?.name,
    type: apiProduct?.product?.__typename,
    isNew,
    stock: (apiProduct?.product && resolveProductStock(apiProduct?.product)) || 0,
    price: currentPrice,
    parentSku: parent.sku,

    parentSlug: parent.url_key,
    slug: apiProduct?.product?.url_key,
    sku: apiProduct?.product?.sku,
    priceBefore: currentPrice < oldPrice ? oldPrice : undefined,
    media_gallery: mediaGallery,
    special_from_date: undefined,
    special_to_date: undefined,
    special_price: undefined,
    price_range: undefined,
    thumb: apiProduct?.product?.image,
    attributes: apiProduct?.attributes,
    hasGift: apiProduct?.product && resolveProductHasGift(apiProduct?.product),
    gift: apiProduct?.product?.potential_gift_promotions?.length
      ? {
          name: apiProduct?.product?.potential_gift_promotions?.[0]?.gifts?.items?.[0]?.name,
          image: {
            src: apiProduct?.product?.potential_gift_promotions?.[0]?.gifts?.items?.[0]?.thumbnail?.url,
            alt: apiProduct?.product?.potential_gift_promotions?.[0]?.gifts?.items?.[0]?.thumbnail?.label,
          },
        }
      : null,
  };

  return product;
}

function mapProductGroupItem(item: GroupedProductItemFragment) {
  return {
    id: item.product?.id,
    qty: item.qty || 0,
    name: item.product?.name,
    stock: item.product ? resolveProductStock(item.product) : 0,
    price: item.product ? resolveProductPrice(item.product) : 0,
    sku: item.product?.sku,
    thumb: item.product?.thumbnail,
  };
}

export function mapBundleProductItem(item: BundleItemFragment) {
  return {
    id: item.option_id,
    label: item.title,
    isRequired: item.required,
    options: toNonNullable(item.options)
      .filter(option => option.product)
      .map(option => {
        return {
          id: option?.uid,
          isDefault: option.is_default,
          canChangeQty: option.can_change_quantity,
          label: option?.label,
          price: option.product ? resolveProductPrice(option.product) : 0,
          priceBefore: option.product ? option.product.price_range.maximum_price?.regular_price.value : 0,
          thumbnail: option.product?.thumbnail,
          qty: option.quantity,
          stock: option.product?.only_x_left_in_stock,
        };
      }),
  };
}
