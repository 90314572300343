// https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking
// https://developers.facebook.com/docs/facebook-pixel/reference
// this also will be supporting google analytics4
// !https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase

import { useContext } from '@nuxtjs/composition-api';

import { useEvent } from './events';
import { ProductData } from './product';

import { useAuth } from './auth';
import { mapOrderDetails } from './orders';

export enum TRACKING_EVENTS {
  ADD_TO_CART = 'ADD_TO_CART', // already implemented
  ADD_TO_WISH_LIST = 'ADD_TO_WISH_LIST',
  COMPLETE_REGISTRATION = 'COMPLETE_REGISTRATION',
  SEARCH = 'Search',
  PURCHASE = 'Purchase',
  ON_PRODUCT = 'ON_PROUDCT', // product impresession
  PRODUCT_CLICK = 'PRODUCT_CLICK', // product click
  PRODUCT_VIEW = 'PRODUCT_VIEW', // product view
  PRODUCT_REMOVE_FROM_CART = 'PRODUCT_REMOVE_FROM_CART', // product remove from cart
  CHECKOUT_STEP = 'CHECKOUT_STEP', // checkout step
  CHECKOUT_STEP_OPTION = 'CHECKOUT_STEP_OPTION', // checkout step option
}

export function useTrackingHandlers() {
  const { app } = useContext();
  const { user } = useAuth();

  useEvent(TRACKING_EVENTS.PURCHASE, (order: ReturnType<typeof mapOrderDetails>) => {
    app.$gtag('purchase', {
      order: {
        number: order?.id || '',
        total: order?.total,
        shippingFees: order?.shippingFees,
      },
      items: order?.items,
      transactionData: {
        transactionId: order?.items?.map(product => product?.sku),
        transactionName: 'order_success',
        transactionTotal: order?.total,
        transactionCurrency: 'EGP',
        transactionType: 'product_group',
        transactionNumItems: order?.items?.length,
        transactionContent: order?.items?.map(product => ({
          id: product?.sku,
          quantity: product?.quantity || 0,
        })),
        transactionAddress: {
          firstname: order?.shipping_address?.firstname,
          lastname: order?.shipping_address?.lastname,
          email: user?.value?.email,
          phone_number: `+20${order?.shipping_address?.telephone}`,
          street: order?.shipping_address?.street,
          city: order?.shipping_address?.city,
          region: order?.shipping_address?.region,
          country: 'Egypt',
          postalcode: '12345',
        },
      },
      ga4: {
        currency: 'EGP',
        transaction_id: order?.id,
        value: order?.total,
        affiliation: '', // TODO be added later
        coupon: '', // TODO be added later
        shipping: order?.shippingFees,
        tax: 0, // TODO be added later
        items: order?.items?.map((product, idx) => ({
          item_id: product?.sku,
          item_name: product?.name,
          affiliation: '', // TODO be added later
          coupon: '', // TODO be added later
          currency: 'EGP',
          discount: '',
          index: idx,
          item_brand: product?.brand,
          item_category: product?.category,
          price: product?.price,
          quantity: product?.quantity,
        })),
      },
    });
  });

  useEvent(TRACKING_EVENTS.SEARCH, (keyword: string) => {
    app.$gtag('search', {
      transactionData: {
        transactionContent: keyword,
        transactionCurrency: 'EGP',
        transactionValue: user.value ? user.value.id : null,
      },
      ga4: {
        search_term: keyword,
      },
    });
  });

  useEvent(
    TRACKING_EVENTS.COMPLETE_REGISTRATION,
    (customer: { firstname: string; lastname: string; email: string }) => {
      app.$gtag('completing_registration', {
        transactionData: {
          transactionContent: customer.firstname + ' ' + customer.lastname,
          transactionCurrency: 'EGP',
          transactionValue: user.value ? user.value.id : null,
          transactionStatus: 'active',
        },
      });
    }
  );

  useEvent(TRACKING_EVENTS.ADD_TO_WISH_LIST, (product: ProductData) => {
    app.$gtag('add_to_wishlist', {
      transactionData: {
        transactionName: product.name,
        transactionId: [product.id],
        transactionContent: product.description,
        transactionValue: user.value ? user.value.id : null, // user id
        transactionCategory: product.mainCategory,
        currency: 'EGP',
      },
      ga4: {
        currency: 'EGP',
        value: product.price,
        items: [product].map((product, idx) => ({
          item_id: product?.sku,
          item_name: product?.name,
          affiliation: '', // TODO be added later
          coupon: '', // TODO be added later
          currency: 'EGP',
          discount: '',
          index: idx,
          item_brand: product?.brand?.name,
          item_category: product?.categories?.[0]?.name,
          price: product?.price,
          quantity: 1,
        })),
      },
    });
  });

  useEvent(TRACKING_EVENTS.ON_PRODUCT, (product: ProductData) => {
    app.$gtag('product_impression', { ...product });
  });

  useEvent(TRACKING_EVENTS.PRODUCT_CLICK, (product: ProductData) => {
    app.$gtag('product_click', {
      ...product,
      ga4: {
        items: [product].map((product, idx) => ({
          item_id: product?.sku,
          item_name: product?.name,
          affiliation: '', // TODO be added later
          coupon: '', // TODO be added later
          currency: 'EGP',
          discount: '',
          index: idx,
          item_brand: product?.brand?.name,
          item_category: product?.categories?.[0]?.name,
          price: product?.price,
          quantity: 1,
        })),
      },
    });
  });

  useEvent(
    TRACKING_EVENTS.ADD_TO_CART,
    (cartItem: {
      name: string;
      id: string[];
      mainCategory: string;
      unitPrice: number;
      currency: string;
      quantity: number;
      sku: string;
      brand: string;
    }) => {
      // Fire GTM event for successful add to cart
      app.$gtag('product_added_to_cart', {
        product: cartItem,
        quantity: cartItem.quantity,
        transactionData: {
          transactionName: cartItem.name,
          transactionTotal: Number(cartItem.unitPrice).toFixed(2),
          transactionCurrency: 'EGP',
          transactionCategory: cartItem.mainCategory,
          transactionType: 'product',
          transactionContent: [cartItem.id],
        },
        ga4: {
          currency: 'EGP',
          value: cartItem.quantity * cartItem.unitPrice,
          items: [cartItem].map((product, idx) => ({
            item_id: product?.sku,
            item_name: product?.name,
            affiliation: '', // TODO be added later
            coupon: '', // TODO be added later
            currency: 'EGP',
            discount: '',
            index: idx,
            item_brand: product?.brand,
            item_category: product?.mainCategory,
            price: product?.unitPrice,
            quantity: product?.quantity,
          })),
        },
      });
    }
  );

  useEvent(
    TRACKING_EVENTS.PRODUCT_REMOVE_FROM_CART,
    (cartItem: {
      name: string;
      id: string[];
      mainCategory: string;
      unitPrice: number;
      currency: string;
      quantity: number;
      sku: string;
      brand: string;
    }) => {
      app.$gtag('product_removed_from_cart', {
        product: cartItem,
        quantity: cartItem?.quantity,
        ga4: {
          currency: 'EGP',
          value: cartItem?.quantity * cartItem?.unitPrice,
          items: [cartItem].map((product, idx) => ({
            item_id: product?.sku ?? 'N/A',
            item_name: product?.name,
            affiliation: '', // TODO be added later
            coupon: '', // TODO be added later
            currency: 'EGP',
            discount: '',
            index: idx,
            item_brand: product?.brand,
            item_category: product?.mainCategory,
            price: product?.unitPrice,
            quantity: product?.quantity,
          })),
        },
      });
    }
  );

  useEvent(TRACKING_EVENTS.PRODUCT_VIEW, (product: ProductData) => {
    // Fire GTM event for product details view
    app.$gtag('product_detail_views', {
      product,
      transactionData: {
        transactionName: product.name,
        transactionTotal: Number(product.price).toFixed(2),
        transactionCurrency: 'EGP',
        transactionCategory: product.mainCategory,
        transactionType: 'product',
        transactionContent: [product.id],
      },
      ga4: {
        currency: 'EGP',
        value: product.price,
        items: [product].map((product, idx) => ({
          item_id: product?.sku,
          item_name: product?.name,
          affiliation: '', // TODO be added later
          coupon: '', // TODO be added later
          currency: 'EGP',
          discount: '',
          index: idx,
          item_brand: product?.brand?.name,
          item_category: product?.mainCategory,
          price: product?.price,
          quantity: 1,
        })),
      },
    });
  });

  useEvent(TRACKING_EVENTS.CHECKOUT_STEP, ({ step, products }: { step: number; products: ProductData[] }) => {
    products && products.length && app.$gtag('checkout_step', { step, items: products });
  });

  useEvent(
    TRACKING_EVENTS.CHECKOUT_STEP_OPTION,
    ({ step, products, option }: { step: number; products: ProductData[]; option: string }) => {
      products && products.length && app.$gtag('checkout_step_option', { step, items: products, option });
    }
  );
}
