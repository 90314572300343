import { ref, watch } from '@nuxtjs/composition-api';
import { useAddCartItem } from './cart';
import { useBundleProductContext } from './productBundle';
import { useProductContext } from './productContext';

export function useAddProductToCartContext() {
  // eslint-disable-next-line no-unused-vars
  const { addItem, addConfigurableProductItem, addGiftCardItem } = useAddCartItem();
  const { productCtx, product } = useProductContext();
  const { selectedBundleOptions } = useBundleProductContext();

  const quantity = ref(1);

  watch(product, newProduct => {
    quantity.value = newProduct?.cartControl?.min || 1;
  });

  async function addToCart() {
    // TODO: Magento fucks this one up and returns incorrect total, should find a way around it.
    if (product.value?.type === 'GroupedProduct' && product.value.groupItems) {
      await addItem(
        product.value.groupItems.map(i => {
          return {
            sku: i.sku as string,
            quantity: i.qty,
          };
        })
      );
      return;
    }

    // handles bundled products
    if (product.value?.type === 'BundleProduct' && product.value.bundleItems) {
      await addItem({
        sku: product.value.sku as string,
        quantity: quantity.value,
        selected_options: selectedBundleOptions.value,
      });
      return;
    }

    // handles configurable products
    if (productCtx.value?.type === 'ConfigurableProduct') {
      if (product.value?.parentSku) {
        await addConfigurableProductItem({
          data: {
            sku: product.value.sku as string,
            quantity: quantity.value,
            selected_options: selectedBundleOptions.value,
            parent_sku: product.value?.parentSku || '',
          },
          parent_sku: product.value?.parentSku || '',
        });
        return;
      }

      throw new Error('requiredConfigurationOption');
    }

    if (product.value?.type === 'MageWorxGiftCards') {
      await addGiftCardItem({
        data: {
          sku: product.value.sku as string,
          quantity: quantity.value,
        },
        gift_card_product_options: {
          card_amount: product.value.priceBefore?.toString() || product.value.price.toString(),
          mail_from: product.value.giftCardFromName || '',
          mail_to: product.value.giftCardToName || '',
          mail_to_email: product.value.giftCardToEmail || '',
          mail_message: product.value.giftCardMessage || '',
          mail_delivery_date_user_value: '',
        },
      });
      return;
    }

    // Handles simple products
    await addItem({
      sku: product.value?.sku as string,
      parent_sku: product.value?.parentSku || '',
      quantity: quantity.value,
    });
  }

  return {
    addToCart,
    quantity,
  };
}
