import { ssrRef, useAsync, useFetch, ref, watch, Ref } from '@nuxtjs/composition-api';
import { useQuery } from 'villus';
import { useCachedSsrRef } from './serverCache';
import { useSetLocaleToCacheParams } from './i18n';
import { MaybeReactive } from '~/types/utils';
import { BrandDocument, BrandQuery, BrandsDocument, BrandsQuery, BrandsQueryVariables } from '~/graphql/Brand';

interface Options {
  fetchOnMount: boolean;
}

export function useBrands(variables?: MaybeReactive<BrandsQueryVariables>, opts?: Partial<Options>) {
  const { cacheParam } = useSetLocaleToCacheParams();
  const brands = useCachedSsrRef<BrandsQuery['brands']['items']>(cacheParam('brands'), []);
  const totalCount = ref(0);
  const { execute, isFetching } = useQuery({
    query: BrandsDocument,
    variables,
    fetchOnMount: opts?.fetchOnMount ?? false,
  });

  useFetch(async () => {
    // Don't refetch brands unless cache expired
    if (brands.value?.length && brands.value[0]) {
      return;
    }

    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    brands.value = data?.brands?.items || [];
    totalCount.value = data?.brands?.total_count || 0;
  });

  return {
    brands,
    totalCount,
    isFetching,
    execute,
  };
}

export function useFeaturedBrands(variables?: MaybeReactive<BrandsQueryVariables>) {
  const { cacheParam } = useSetLocaleToCacheParams();
  const brands = useCachedSsrRef<BrandsQuery['brands']['items']>(cacheParam('featured-brands'), []);
  const { execute, isFetching } = useQuery({
    query: BrandsDocument,
    variables,
    fetchOnMount: false,
  });

  useAsync(async () => {
    // Don't refetch brands unless cache expired
    if (brands.value.length) {
      return;
    }

    const { data, error } = await execute();
    if (error) {
      throw new Error(error.message);
    }

    brands.value = data?.brands?.items || [];
  });

  return {
    brands,
    isFetching,
  };
}

export function useBrand(slug: string) {
  const brand = ssrRef<BrandQuery['brand'] | null | undefined>(null, 'brand');
  const { isFetching, execute } = useQuery({
    query: BrandDocument,
    variables: {
      slug,
    },
    fetchOnMount: false,
  });

  useAsync(async () => {
    const { data } = await execute();

    brand.value = data?.brand;
  });

  return {
    brand,
    isFetching,
  };
}

type SearchResult = {
  letter: number;
  payload: {
    url: string;
    image: string;
    name: string;
  }[];
}[];

export function useSearchByLetter() {
  const threshold = 27; // the number of letters to display if there is no selected letter already
  const result = ref<SearchResult>([]);
  const letter = ref<number | null>(null);
  const { brands } = useBrands({ pageSize: 300 }, { fetchOnMount: true });

  watch(
    letter,
    newLetter => {
      if (!newLetter) {
        let count = 97;
        result.value = [];

        while (count !== 122 && result.value.length !== threshold) {
          result.value = brands.value?.filter(brand => {
            return brand?.name.toLowerCase().charCodeAt(0) === count;
          })
            ? [
                ...result.value,
                {
                  letter: count,
                  payload:
                    brands.value
                      ?.filter(brand => {
                        return brand?.name.toLowerCase().charCodeAt(0) === count;
                      })
                      .map(brand => ({
                        url: brand?.url_key || '',
                        name: brand?.name || '',
                        image: brand?.image_url || '',
                      })) || [],
                },
              ]
            : result.value;

          count++;
        }
        return;
      }

      if (!newLetter) {
        return;
      }

      result.value = [
        {
          letter: newLetter,
          payload:
            brands.value
              .filter(brand => {
                return brand?.name.toLowerCase().charCodeAt(0) === newLetter;
              })
              .map(brand => ({
                url: brand?.url_key || '',
                name: brand?.name || '',
                image: brand?.image_url || '',
              })) || [],
        },
      ];
    },
    {
      immediate: true,
    }
  );

  watch(
    brands,
    newBrands => {
      if (newBrands.length) letter.value = null;
    },
    {
      immediate: true,
    }
  );
  return {
    result,
    letter,
  };
}

// handles the search feature
export function useSearchBrands(variables?: MaybeReactive<BrandsQueryVariables>, opts?: Partial<Options>) {
  const brands: Ref<any> = ref([]);

  const { data, error, isFetching, execute } = useQuery({
    query: BrandsDocument,
    variables,
    fetchOnMount: opts?.fetchOnMount ?? true,
  });

  watch(data, value => {
    brands.value = value?.brands?.items.slice(0, 5);
  });

  return {
    brands,
    isFetching,
    error,
    execute,
  };
}
