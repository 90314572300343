import { computed, inject, onMounted, reactive, ref, unref, watch } from '@nuxtjs/composition-api';
import { useMutation, useQuery } from 'villus';
import { get, set } from 'idb-keyval';
import { mapProductListing } from './products';
import { useChannels, topics } from '~/features/channels';
import { useAuth } from '~/features/auth';
import { useRouter } from '~/features/router';

import {
  AddToWishlistMutation,
  AddToWishlistDocument,
  AllWishlistItemsDocument,
  RemoveFromWishlistDocument,
  WishlistCountDocument,
  WishlistDocument,
  WishlistQuery,
  WishlistQueryVariables,
} from '~/graphql/Wishlist';
import { MaybeReactive } from '~/types/utils';
import { toNonNullable } from '~/utils/collections';
import { ProductCardFragment } from '~/graphql/fragments';
import { AUTH_USER } from '~/utils/provides';

const WISHLIST_TRACKER = reactive({
  count: 0,
  lookup: {} as Record<string, number>,
  initialized: false,
});

const DB_KEY = 'fav-skus';

type WishlistItem = {
  sku: string;
};

const skus = ref<WishlistItem[]>([]);

export function useWishlist(variables?: MaybeReactive<WishlistQueryVariables>) {
  const items = ref<ReturnType<typeof mapWishlistItems>>([]);
  const { data, error, isFetching, execute } = useQuery({
    query: WishlistDocument,
    variables,
    cachePolicy: 'network-only',
  });

  watch(data, value => {
    if (!value) {
      return;
    }

    const vars = unref(variables);
    if (vars?.currentPage === 1) {
      items.value = mapWishlistItems(value);
      return;
    }

    items.value.push(...mapWishlistItems(value));
  });

  const totalCount = computed(() => {
    return data.value?.customer?.wishlists?.[0]?.totalCount || 0;
  });

  return {
    data,
    items,
    totalCount,
    error,
    isFetching,
    refetch: execute,
  };
}

function mapWishlistItems(data: WishlistQuery) {
  if (!data) {
    return [];
  }

  return toNonNullable(data.customer?.wishlists?.[0]?.items_v2?.items).map(item => ({
    wishlistId: item.id,
    ...mapProductListing(item.product as ProductCardFragment),
  }));
}

export function useAddToWishlist(sku: string | string[]) {
  const user = inject(AUTH_USER);
  const { execute } = useMutation(AddToWishlistDocument);
  const { redirect } = useRouter();
  // const { app } = useContext();

  async function _addToWishlist() {
    const { data, error } = await execute({
      pageSize: WISHLIST_TRACKER.count + 100, // keep a buffer of more items
      wishlistId: user?.value.wishlist.id as string,
      wishlistItems: Array.isArray(sku)
        ? sku.map(i => ({ sku: i, quantity: 1 }))
        : [
            {
              sku,
              quantity: 1,
            },
          ],
    });

    if (error) {
      throw error;
    }

    // TODO: Handle errors
    if (data.response?.errors.length) {
      throw new Error(data.response.errors[0]?.message);
    }

    // Fire Facebook event on adding to wishlist
    // app.$fireFbEvent('AddToWishlist');

    updateTracker(data?.response?.wishlist);
  }

  async function addToWishlist() {
    if (user?.value) {
      await _addToWishlist();
      return;
    }
    redirect('/login');
  }

  return {
    addToWishlist,
  };
}

export function useRemoveFromWishlist(sku: string) {
  const user = inject(AUTH_USER);
  const { execute } = useMutation(RemoveFromWishlistDocument);

  async function _removeFromWishlist() {
    const { data, error } = await execute({
      pageSize: WISHLIST_TRACKER.count + 100, // keep a buffer of more items
      wishlistId: user?.value.wishlist.id as string,
      wishlistItemsIds: [String(WISHLIST_TRACKER.lookup[sku])],
    });

    if (error) {
      throw error;
    }

    // TODO: Handle errors
    if (data.response?.errors.length) {
      throw new Error(data.response.errors[0]?.message);
    }

    updateTracker(data?.response?.wishlist);
  }

  async function _removeGuestFromWishlist() {
    await removeItem(sku);
  }

  async function removeFromWishlist() {
    if (user?.value) {
      await _removeFromWishlist();
      return;
    }
    await _removeGuestFromWishlist();
  }

  return {
    removeFromWishlist,
  };
}

function _useInitWishlistTracker() {
  WISHLIST_TRACKER.initialized = true;
  const { data: countData } = useQuery({
    query: WishlistCountDocument,
    cachePolicy: 'network-only',
  });

  const { data } = useQuery({
    query: AllWishlistItemsDocument,
    cachePolicy: 'network-only',
    fetchOnMount: false,
    variables: computed(() => {
      if (!countData.value) {
        return {};
      }

      return {
        pageSize: countData.value?.customer?.wishlist.totalCount || 100,
      };
    }),
  });

  watch(data, value => {
    updateTracker(value?.customer?.wishlist);
  });
}

export function useAddExplicitToWishlist() {
  const user = inject(AUTH_USER);
  const { execute } = useMutation(AddToWishlistDocument);
  // const { app } = useContext();

  async function addToWishlist(sku: string | string[]) {
    const { data, error } = await execute({
      pageSize: WISHLIST_TRACKER.count + 100, // keep a buffer of more items
      wishlistId: user?.value.wishlist.id as string,
      wishlistItems: Array.isArray(sku)
        ? sku.map(i => ({ sku: i, quantity: 1 }))
        : [
            {
              sku,
              quantity: 1,
            },
          ],
    });

    if (error) {
      throw error;
    }

    // TODO: Handle errors
    if (data.response?.errors.length) {
      throw new Error(data.response.errors[0]?.message);
    }

    // Fire Facebook event on adding to wishlist
    // app.$fireFbEvent('AddToWishlist');

    updateTracker(data?.response?.wishlist);
  }

  return {
    addToWishlist,
  };
}

function _useGuestInitWishlistTracker() {
  WISHLIST_TRACKER.initialized = true;
  const { subscribe } = useChannels();
  const { addToWishlist } = useAddExplicitToWishlist();
  const { user } = useAuth();

  onMounted(async () => {
    const items = (await get<WishlistItem[]>(DB_KEY)) || [];
    // skip if both lists are empty
    if (!items.length && !skus.value.length) {
      return;
    }

    // this will trigger the fetching
    skus.value = [...new Set(items)];
  });

  subscribe(topics.auth.identify, async () => {
    if (user.value) {
      await addToWishlist(skus.value.map(item => item.sku));

      await clearItems();
    }
  });
}

function useInitWishlistTracker() {
  const { user } = useAuth();

  if (user.value) {
    _useInitWishlistTracker();
    return;
  }
  _useGuestInitWishlistTracker();
}

export function useIsInWishlist(sku: string) {
  const { user } = useAuth();
  if (!WISHLIST_TRACKER.initialized) {
    useInitWishlistTracker();
  }

  return computed(() => {
    return user?.value ? WISHLIST_TRACKER.lookup[sku] !== undefined : !!skus.value.find(item => item?.sku === sku);
  });
}

type WishlistPartial = NonNullable<AddToWishlistMutation['response']>['wishlist'];

function updateTracker(data: WishlistPartial | undefined) {
  WISHLIST_TRACKER.count = data?.totalCount || 100;
  WISHLIST_TRACKER.lookup =
    data?.items_v2?.items?.reduce((acc: Record<string, number>, item) => {
      const sku = item?.product?.sku;
      if (!sku || !item?.id) {
        return acc;
      }

      acc[sku] = Number(item?.id);

      return acc;
    }, {} as Record<string, number>) || {};
}

export function useIsInWhishlist(sku: string) {
  return computed(() => {
    return skus.value.some(item => item.sku === sku);
  });
}

export async function compareItem(sku: WishlistItem) {
  /*
   * validation
   * if there is no items in the list, we can add the item to the list
   * if there is only one item in the list, then we compare that item's category with the only already in the list item's category ,
   */

  if (skus.value.length === 0) {
    await updateState([...skus.value, sku]);
    return;
  }

  await updateState([...skus.value, sku]);
}

export async function removeItem(sku: string) {
  skus.value = skus.value.filter(item => {
    return item.sku !== sku;
  });
  await updateState(skus.value);
}

export async function clearItems() {
  await updateState([]);
}

async function updateState(items: WishlistItem[]) {
  const uniqueSku = [...new Set(items)];
  await set(DB_KEY, uniqueSku);
  skus.value = uniqueSku;
}
