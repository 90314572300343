import { defineComponent, getCurrentInstance, h } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Money',
  props: {
    value: {
      type: null,
      required: true,
    },
    as: {
      type: String,
      default: 'span',
    },
    fallback: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const instance = getCurrentInstance()?.proxy;

    return () => {
      if (props.value === undefined) {
        return h(props.as, {}, `${instance?.$t('money.toBeCalculated')}`);
      }

      const text =
        props.value === 0
          ? `${instance?.$t('money.free')}`
          : `${(instance as any).$n(props.value, 'decimal')} ${instance?.$t('egp')}`;

      return h(props.as, {}, text);
    };
  },
});
