
















































































































































































import { defineComponent, onBeforeUnmount, ref, watch, watchEffect } from '@nuxtjs/composition-api';
import { useAuth } from '~/features/auth';
import { useEvent, useEventBus } from '~/features/events';
import { useRouter } from '~/features/router';

export default defineComponent({
  name: 'Account',
  setup() {
    const { user } = useAuth();

    const isOpen = ref(false);
    const { redirect, route } = useRouter();
    const { emit } = useEventBus();

    useEvent('TOGGLE_ACCOUNT_MENU', () => {
      emit('DEACTIVATE_CATEGORY_ICON');
      isOpen.value = !isOpen.value;
    });

    function close() {
      emit('DEACTIVATE_ACCOUNT_ICON');
      isOpen.value = false;
    }

    function navigateTo(url: string) {
      close();
      redirect(url);
    }

    function loggingOut() {
      emit('LOGOUT_CONFIRM');
      close();
    }

    watchEffect(() => {
      if (process.server) {
        return;
      }

      window.document.body.classList.toggle('overflow-hidden', isOpen.value);
    });

    onBeforeUnmount(() => window.document.body.classList.toggle('overflow-hidden', false));

    watch(route, () => {
      close();
    });

    return {
      isOpen,
      close,
      navigateTo,
      user,
      loggingOut,
    };
  },
});
