











































import { defineComponent, nextTick, onMounted, ref, toRef, watch } from '@nuxtjs/composition-api';
// eslint-disable-next-line import/no-named-as-default
import Swiper from 'swiper';

export default defineComponent({
  name: 'ProductSlider',
  props: {
    items: {
      type: Array,
      required: true,
    },
    arrows: {
      type: Boolean,
      default: false,
    },
    itemsPerRowOnDesktop: {
      type: Number,
      default: 4.3,
    },
    itemsPerRowOnMobile: {
      type: Number,
      default: 2.1,
    },
    alignment: {
      type: String,
      default: 'start',
      validator: (value: string) => ['start', 'center', 'end'].includes(value),
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    scrollbar: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const swiperRef = ref(null);
    const isSliderLoaded = ref(false);
    const items = toRef(props, 'items');
    const swiperInstance = ref<Swiper>();

    onMounted(async () => {
      const { default: Swiper, Navigation, Scrollbar } = await import('swiper');
      Swiper.use([Navigation, Scrollbar]);
      // @ts-ignore
      await import('swiper/swiper-bundle.css');

      isSliderLoaded.value = true;
      await nextTick();

      swiperInstance.value = new Swiper(swiperRef.value || '', {
        slidesPerView: props.itemsPerRowOnMobile,
        spaceBetween: 15,
        slidesOffsetBefore: 25,
        slidesOffsetAfter: 25,
        scrollbar: {
          el: '.swiper-scrollbar',
        },
        breakpoints: {
          '768': {
            slidesPerView: 3,
            spaceBetween: 20,
            centeredSlidesBounds: false,
            centerInsufficientSlides: false,
            slidesPerGroup: 4.4,
            slidesOffsetBefore: 0,
          },
          '1024': {
            slidesPerView: props.itemsPerRowOnDesktop,
            spaceBetween: 20,
            centeredSlidesBounds: false,
            centerInsufficientSlides: false, // !!(props.alignment === 'center'),
            slidesPerGroup: props.itemsPerRowOnDesktop,
            slidesOffsetBefore: 0,
          },
        },
      });

      watch(items, () => {
        swiperInstance?.value?.updateSlides();
      });
    });

    function nextSlider() {
      swiperInstance?.value?.slideNext();
    }

    function prevSlider() {
      swiperInstance?.value?.slidePrev();
    }

    return {
      swiperRef,
      isSliderLoaded,
      swiperInstance,
      prevSlider,
      nextSlider,
    };
  },
});
