import type { ClientPlugin } from 'villus';
import { useHeadlessLogout } from '~/features/auth';

/**
 * villus client locale plugin
 */
export function userExpiration(): ClientPlugin {
  const { logout } = useHeadlessLogout();
  return function userExpirationPlugin({ afterQuery }) {
    afterQuery(({ error }) => {
      if (
        process.browser &&
        [
          /The current customer isn't authorized./,
          /The current user cannot perform operations on cart/,
          /The request is allowed for logged in customer/,
        ].some(reg => reg.test(error?.message || ''))
      ) {
        logout();
      }
    });
  };
}
