










































































import { Organization, SearchAction, WebSite } from 'schema-dts';
import { defineComponent, useContext } from '@nuxtjs/composition-api';
import TheAlerts from '@/components/TheAlerts.vue';
import { useJsonLd, useSeoLinks } from '~/features/seo';
import { useI18nCookie } from '~/features/i18n';
import { useCartAttributes } from '~/features/cart';

export default defineComponent({
  setup() {
    useSeoLinks();
    useI18nCookie();
    const { spinTheWheelData } = useCartAttributes();

    const { $config } = useContext();
    const url = $config.appURL;

    // https://developers.google.com/search/docs/data-types/sitelinks-searchbox
    const websiteWithSearchAction: WebSite = {
      '@type': 'WebSite',
      url,
      potentialAction: {
        '@type': 'SearchAction',
        target: `${url}/search?q={search_term_string}`,
        'query-input': 'required name=search_term_string',
      } as SearchAction,
    };

    const org: Organization = {
      '@id': url,
      '@type': 'Organization',
      // TODO: Organization name
      name: 'Unique',
      url,
      logo: `${url}/img/logo.png`,
      sameAs: [
        // TODO: Any social links to facebook/twitter/whatever
      ],
    };

    useJsonLd({
      '@context': {
        '@vocab': 'http://schema.org/',
      },
      '@graph': [websiteWithSearchAction, org],
    });

    return {
      spinTheWheelData,
    };
  },
  components: {
    TheAlerts,
  },
  head: {},
});
