import { reactive } from '@nuxtjs/composition-api';
import { useMutation } from 'villus';
import { ContactFormDocument, SubscribeToEmailDocument } from '~/graphql/Contact';

export function useContactForm() {
  const { execute } = useMutation(ContactFormDocument);
  const input = reactive({
    name: '',
    email: '',
    phone_number: '',
    description: '',
    reason_of_contact: '',
    attachment: '',
  });

  async function submitForm() {
    const { error } = await execute({
      input,
    });

    if (error) {
      throw error;
    }
  }

  return {
    input,
    submitForm,
  };
}

export function useSubscribeToNewsletter() {
  const { execute } = useMutation(SubscribeToEmailDocument);
  const input = reactive({
    email: '',
  });

  async function submitForm() {
    const { error } = await execute({
      email: input.email,
    });

    if (error) {
      throw error;
    }
  }

  return {
    input,
    submitForm,
  };
}
