import { render, staticRenderFns } from "./Spinner.vue?vue&type=template&id=912ae2a2&scoped=true&functional=true&"
import script from "./Spinner.vue?vue&type=script&lang=js&"
export * from "./Spinner.vue?vue&type=script&lang=js&"
import style0 from "./Spinner.vue?vue&type=style&index=0&id=912ae2a2&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "912ae2a2",
  null
  
)

export default component.exports