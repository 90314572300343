import { PartialRecord } from '~/utils/collections';

export type Operation =
  | 'customerCart'
  | 'guestCart'
  | 'mergeCart'
  | 'addCartItem'
  | 'updateCartItem'
  | 'removeCartItem'
  | 'setPaymentMethod'
  | 'setShippingMethod'
  | 'setCartAddress'
  | 'cartPage'
  | 'applyCouponToCart'
  | 'removeCouponToCart'
  | 'applyRewardToCart'
  | 'removeRewardFromCard'
  | 'RemoveCartItemsCustomOptions'
  | 'UpdateCartItemsCustomOptions'
  | 'SetOrderSourceOnCart'
  | 'wheelCartGifts'
  | 'spinTheWheel'
  | 'applyGiftCardToCart'
  | 'removeGiftCardFromCart';

export type Flags =
  | 'withItems'
  | 'withItemPrices'
  | 'withItemDetails'
  | 'withItemCustomizableOptions'
  | 'withShippingAddress'
  | 'withAppliedCodes'
  | 'withPaymentSummary'
  | 'withPaymentMethods'
  | 'withSelectedPaymentMethod'
  | 'withShippingMethods'
  | 'withSelectedShippingMethod'
  | 'withItemConfigurableOptions'
  | 'withStoreLocations'
  | 'withAppliedRewardPoints'
  | 'withCartPromotions'
  | 'withSpinTheWheelData'
  | 'withAppliedGiftCards'
  | 'withMageWorxCartItem';

type CartModuleConfig = PartialRecord<Operation, PartialRecord<Flags, boolean>>;

export default {
  customerCart: {
    withItems: true,
    withItemDetails: true,
    withItemPrices: true,
    withPaymentSummary: true,
    withItemConfigurableOptions: true,
    withStoreLocations: true,
    withSelectedPaymentMethod: true,
    withSpinTheWheelData: true,
    withAppliedGiftCards: true,
    withMageWorxCartItem: true,
  },
  guestCart: {
    withItems: true,
    withItemDetails: true,
    withItemPrices: true,
    withPaymentSummary: true,
    withItemConfigurableOptions: true,
    withStoreLocations: true,
    withSelectedPaymentMethod: true,
    withAppliedGiftCards: true,
    withMageWorxCartItem: true,
  },
  addCartItem: {
    withItems: true,
    withItemDetails: true,
    withItemPrices: true,
    withPaymentSummary: true,
    withItemConfigurableOptions: true,
    withCartPromotions: true,
  },
  updateCartItem: {
    withItems: true,
    withItemDetails: true,
    withItemPrices: true,
    withPaymentSummary: true,
    withItemConfigurableOptions: true,
    withCartPromotions: true,
    withShippingAddress: true,
    withSelectedShippingMethod: true,
  },
  mergeCart: {
    withItems: true,
    withItemDetails: true,
    withItemPrices: true,
    withItemConfigurableOptions: true,
    withPaymentSummary: true,
    withCartPromotions: true,
  },
  removeCartItem: {
    withItems: true,
    withItemDetails: true,
    withItemPrices: true,
    withPaymentSummary: true,
    withItemConfigurableOptions: true,
    withCartPromotions: true,
    withShippingAddress: true,
    withSelectedShippingMethod: true,
  },
  applyCouponToCart: {
    withPaymentSummary: true,
    withAppliedCodes: true,
    withCartPromotions: true,
  },
  removeCouponToCart: {
    withPaymentSummary: true,
    withAppliedCodes: true,
    withCartPromotions: true,
  },
  setShippingMethod: {
    withShippingAddress: true,
    withPaymentSummary: true,
    withShippingMethods: true,
  },
  applyRewardToCart: {
    withPaymentSummary: true,
    withAppliedRewardPoints: true,
  },
  removeRewardFromCard: {
    withPaymentSummary: true,
    withAppliedRewardPoints: true,
  },
  setCartAddress: {
    withShippingAddress: true,
    withPaymentSummary: true,
    withShippingMethods: true,
    withSelectedShippingMethod: true,
  },

  UpdateCartItemsCustomOptions: {
    withItems: true,
    withItemDetails: true,
    withItemPrices: true,
    withItemConfigurableOptions: true,
  },
  RemoveCartItemsCustomOptions: {
    withItems: true,
    withItemDetails: true,
    withItemPrices: true,
    withItemConfigurableOptions: true,
  },
  SetOrderSourceOnCart: {
    withPaymentSummary: true,
  },
  applyGiftCardToCart: {
    withAppliedGiftCards: true,
    withPaymentSummary: true,
    withPaymentMethods: true,
  },
  removeGiftCardFromCart: {
    withAppliedGiftCards: true,
    withPaymentSummary: true,
    withPaymentMethods: true,
  },
  wheelCartGifts: {
    withCartPromotions: true,
  },
  spinTheWheel: {
    withItems: true,
    withItemDetails: true,
    withItemPrices: true,
    withItemConfigurableOptions: true,
    withSpinTheWheelData: true,
  },
} as CartModuleConfig;

/**
 * Determine what should be fetched in cart setup query initially based on the current route
 *
 * for example: if it happen to be on cart page, we should fetch cart items, shipping address, payment methods, etc.
 */

export const routeOptionsMapper: {
  [key: string]: CartModuleConfig;
} = {
  cart: {
    customerCart: {
      withItems: true,
      withItemDetails: true,
      withItemPrices: true,
      withPaymentSummary: true,
      withStoreLocations: true,
      withCartPromotions: true,
      withShippingAddress: true,
      withSelectedShippingMethod: true,
    },
    guestCart: {
      withItems: true,
      withItemDetails: true,
      withPaymentSummary: true,
      withStoreLocations: true,
      withCartPromotions: true,
      withShippingAddress: true,
      withSelectedShippingMethod: true,
    },
    updateCartItem: {
      withItems: true,
      withItemDetails: true,
      withItemPrices: true,
      withPaymentSummary: true,
    },
    UpdateCartItemsCustomOptions: {
      withItems: true,
      withItemDetails: true,
      withItemPrices: true,
    },
    RemoveCartItemsCustomOptions: {
      withItems: true,
      withItemDetails: true,
      withItemPrices: true,
    },
  },
  'checkout-shipping': {
    customerCart: {
      withItems: true,
      withItemDetails: true,
      withPaymentSummary: true,
      withAppliedCodes: true,
      withAppliedRewardPoints: true,
      withSelectedShippingMethod: true,
      withShippingAddress: true,
      withShippingMethods: true,
    },
  },
  'checkout-guest-shipping': {
    guestCart: {
      withItems: true,
      withItemDetails: true,
      withItemPrices: true,
      withPaymentSummary: true,
      withAppliedCodes: true,
      withAppliedRewardPoints: true,
      withSelectedShippingMethod: true,
      withShippingAddress: true,
      withShippingMethods: true,
    },
  },
  'checkout-pay': {
    customerCart: {
      withItems: true,
      withItemDetails: true,
      withPaymentSummary: true,
      withSelectedShippingMethod: true,
      withShippingMethods: true,
      withShippingAddress: true,
      withStoreLocations: true,
      withAppliedRewardPoints: true,
      withAppliedCodes: true,
    },
    guestCart: {
      withItems: true,
      withItemDetails: true,
      withItemPrices: true,
      withPaymentSummary: true,
      withSelectedShippingMethod: true,
      withShippingMethods: true,
      withShippingAddress: true,
      withStoreLocations: true,
      withAppliedRewardPoints: true,
    },
    setShippingMethod: {
      withShippingAddress: true,
      withSelectedShippingMethod: true,
      withShippingMethods: true,
    },
    setPaymentMethod: {
      withPaymentMethods: true,
      withPaymentSummary: true,
      withSelectedPaymentMethod: true,
    },
  },
};
