//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider } from 'vee-validate';

export default {
  name: 'TextInput',
  components: {
    ValidationProvider,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: '',
    },
    filled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    border: {
      type: Boolean,
      default: false,
    },
    fullBorder: { type: Boolean, default: false },
    underline: { type: Boolean, default: false },
    light: { type: Boolean, default: false },
    isOptional: { type: Boolean, default: true },
  },
  data: vm => ({
    innerValue: vm.value || '',
    showPassword: false,
    provider: undefined,
  }),
  computed: {
    hasValue() {
      return !!this.innerValue.length;
    },
    showError() {
      return !!this.errors.length && !this.provider.flags.untouched;
    },
    errors() {
      return this.provider?.errors.length ? this.provider.errors : [];
    },
    inValid() {
      return this.provider?.flags.invalid;
    },
    dirty() {
      return this.provider?.flags.dirty;
    },
  },
  watch: {
    innerValue(value) {
      this.$emit('input', value);
    },
    value(value) {
      if (value !== this.innerValue) {
        this.innerValue = value;
      }
    },
  },
  mounted() {
    this.provider = this.$refs.provider;
  },
  methods: {
    checkShowError() {
      if (!this.dirty) {
        this.provider.flags.untouched = true;
        return;
      }

      if (this.inValid) {
        this.provider.flags.untouched = false;
      }
    },
  },
};
