export type GQL_ERROR_CATEGORIES =
  | 'graphql-no-such-entity'
  | 'graphql-authentication'
  | 'graphql-authorization'
  | 'graphql-already-exists';

export const PAYMENT_ERROR = {
  CARD_DECLINED: {
    location: '/checkout-error?msg=CARD_DECLINED',
  },
  PAYMENT_ERROR: {
    location: '/checkout-error?msg=PAYMENT_ERROR',
  },
};

export const FETCHING_ORDER_ERROR = {
  NOT_FOUND: 'NOT_FOUND',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
};

export const FETCHING_ADDRESS_ERROR = {
  NOT_FOUND: 'NOT_FOUND',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
};
