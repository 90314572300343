import { isAfter, isBefore } from 'date-fns';
import { ProductCardFragment } from '~/graphql/fragments';
import { resolvePricePerStep, resolveProductPrice } from '~/utils/product';

export type Options = {
  color?: string | null;
  thumb: { src: string; alt: string };
  name: string;
  price: number;
  slug: string;
  stock: number;
}[];

export interface CartControl {
  step: number;
  min: number;
  max: number;
  unit: null | string;
}

export const extractConfigurableProductListingOptions = (apiItem: ProductCardFragment): Options => {
  const allowedCodes = ['color', 'colour', 'flormar_color'];
  if (apiItem.__typename === 'ConfigurableProduct' && apiItem.configurable_options?.length) {
    const options =
      apiItem.configurable_options
        ?.find(option => allowedCodes.includes(option?.attribute_code || ''))
        ?.values?.map(option => {
          const selectedVariant = apiItem.variants?.find(variant =>
            variant?.attributes?.find(attribute => attribute?.uid === option?.uid)
          );

          return {
            color: option?.swatch_data?.value,
            thumb: {
              src: selectedVariant?.product?.image?.url || '',
              alt: selectedVariant?.product?.image?.label || '',
            },
            name: selectedVariant?.product?.name || '',
            price: (selectedVariant?.product && resolveProductPrice(selectedVariant?.product)) || 0,
            slug: apiItem.url_key + '/var/' + selectedVariant?.product?.url_key,
            stock: selectedVariant?.product?.only_x_left_in_stock || 0,
          };
        }) || [];

    return [
      {
        thumb: {
          src: apiItem?.thumbnail?.url || '',
          alt: apiItem?.thumbnail?.label || '',
        },
        name: apiItem?.name || '',
        price: resolveProductPrice(apiItem),
        slug: apiItem.url_key || '',
        stock: resolveStockItem(apiItem) || 0,
      },
      ...options,
    ];
  }

  return [
    {
      thumb: {
        src: apiItem?.thumbnail?.url || '',
        alt: apiItem?.thumbnail?.label || '',
      },
      name: apiItem?.name || '',
      price: resolveProductPrice(apiItem),
      slug: apiItem.url_key || '',
      stock: apiItem?.only_x_left_in_stock || 0,
    },
  ];
};

function resolveStockItem(apiItem: ProductCardFragment): number {
  if (apiItem.__typename === 'ConfigurableProduct' && apiItem.variants?.length) {
    return apiItem.variants.reduce((accu, variant) => {
      const stock = variant?.product?.only_x_left_in_stock || 0;
      return accu + stock;
    }, 0);
  }
  return 0;
}

export function resolveIsNewProduct(apiProduct: ProductCardFragment): boolean {
  const newFrom = new Date(apiProduct.new_from_date ?? 0);
  const newTo = new Date(apiProduct.new_to_date || 0);
  const now = new Date();
  return isAfter(newTo, now) && isBefore(newFrom, now);
}

export function cartControlsBuilder(
  apiProduct: ProductCardFragment
): {
  cartControl: CartControl;
  pricePerStep: number;
  beforePricePerStep: number | null;
  currentPrice: number;
  oldPrice: number;
} {
  const currentPrice = resolveProductPrice(apiProduct);

  const oldPrice = apiProduct.price_range.maximum_price?.regular_price.value ?? currentPrice;

  const cartControl = {
    max: Number(apiProduct.cart_control.max_amount) || Number.MAX_VALUE, // maximum quantity allowed in the cart
    min: Number(apiProduct.cart_control.min_amount) || 0, // minimum quantity allowed in the cart
    step: Number(apiProduct.cart_control.increment_step) || 1, // quantity step
    unit: apiProduct.cart_control.unit || null,
  };

  const pricePerStep = resolvePricePerStep(currentPrice, Number(cartControl?.step)) || 0;
  const beforePricePerStep = resolvePricePerStep(oldPrice, Number(cartControl?.step)) || null;

  return {
    cartControl,
    pricePerStep,
    beforePricePerStep,
    currentPrice,
    oldPrice,
  };
}
