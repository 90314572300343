import { computed, inject, onMounted, reactive, ref } from '@nuxtjs/composition-api';
import { isString } from 'lodash-es';
import { useMutation, useQuery } from 'villus';
import { OrderAddress } from './../graphql/Auth';
import { useSsrCoreApp } from './ssrControl';
import { FETCHING_ADDRESS_ERROR } from '~/types/errors';
import {
  CartAddressInput,
  City,
  CountryCodeEnum,
  CustomerAddress,
  CustomerAddressInput,
  PickupLocation,
  Region,
  ShippingCartAddress,
} from '~/graphql-types.gen';
import {
  CreateAddressDocument,
  CreateAddressMutationVariables,
  CustomerAddressesDocument,
  DeleteAddressDocument,
  UpdateAddressDocument,
  UpdateAddressMutationVariables,
} from '~/graphql/Address';
import { CitiesForRegionDocument, RegionsForCountryDocument } from '~/graphql/Country';
import { toNonNullable } from '~/utils/collections';
import { AUTH_USER, CONFIG_STORE_COUNTRY } from '~/utils/provides';

interface Options {
  fetchOnMount: boolean;
}

export const invalidCustomerAddress = ref<boolean>(false); // boolean value to track current selected customer (street + floor + apartment) length

export function useCreateAddress() {
  const { execute, isFetching } = useMutation(CreateAddressDocument);

  async function createAddress(input: CreateAddressMutationVariables['input']) {
    try {
      const { data, error } = await execute({ input });

      if (error) {
        throw new Error(error.message);
      }

      return data.response;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    createAddress,
    isCreating: isFetching,
  };
}

export function useUpdateAddress() {
  const { execute, isFetching } = useMutation(UpdateAddressDocument);

  async function updateAddress(
    id: UpdateAddressMutationVariables['id'],
    input: UpdateAddressMutationVariables['input']
  ) {
    try {
      const { data, error } = await execute({ id, input });

      if (error) {
        throw new Error(error.message);
      }

      return data.response;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    updateAddress,
    isFetching,
  };
}

export function useCountry() {
  const countryId = inject(CONFIG_STORE_COUNTRY, CountryCodeEnum.Eg);
  const selectedRegion = ref<Region | null>(null);

  const { data: regions, isFetching: isFetchingRegions } = useQuery({
    query: RegionsForCountryDocument,
    variables: { countryId },
  });
  const { data: cities, isFetching: isFetchingCities } = useQuery({
    query: CitiesForRegionDocument,
    variables: computed(() => {
      if (!selectedRegion.value) {
        return {
          regionId: '',
        };
      }

      return {
        regionId: String(selectedRegion.value?.id) as string,
      };
    }),
    fetchOnMount: false,
  });

  return {
    selectedRegion,
    regions: computed<(null | Region)[]>(() => {
      return [null].concat((regions.value?.country?.regions || []) as any);
    }),
    cities: computed<(null | City)[]>(() => {
      return [null].concat((cities.value?.region?.cities || []) as any);
    }),
    isFetchingCities,
    isFetchingRegions,
  };
}

export function useCustomerAddresses(opts?: Partial<Options>) {
  const { waitForPageToBeLoaded } = useSsrCoreApp();

  const { isFetching, execute } = useQuery({
    query: CustomerAddressesDocument,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  const addresses = ref<CustomerAddress[]>([]);
  const isFetchingAddresses = ref(true);

  async function fetchAddresses() {
    isFetchingAddresses.value = true;
    await waitForPageToBeLoaded();

    try {
      const { data, error } = await execute();
      if (error) {
        throw new Error(error.message);
      }

      addresses.value = toNonNullable(data?.customer?.addresses).sort(
        (a, b) => Number(b.default_shipping) - Number(a.default_shipping)
      );

      return addresses.value;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);

      if (/The current customer isn't authorized\./.test((e as Error).message)) {
        throw new Error(FETCHING_ADDRESS_ERROR.NOT_AUTHORIZED.toString());
      }

      throw e;
    } finally {
      isFetchingAddresses.value = false;
    }
  }

  if (opts?.fetchOnMount)
    onMounted(async () => {
      await waitForPageToBeLoaded();

      execute();
    });

  return {
    addresses,
    isFetching,
    fetchAddresses,
  };
}

export function useAddressForm(initialValue?: CustomerAddress) {
  const user = inject(AUTH_USER);
  const countryCode = inject(CONFIG_STORE_COUNTRY, CountryCodeEnum.Eg);

  const fullName = `${initialValue?.firstname || user?.value?.firstname || ''} ${
    initialValue?.lastname || user?.value?.lastname || ''
  }`;
  const addressInput = reactive({
    fullName,
    phone: initialValue?.telephone || '',
    street: (initialValue?.street && initialValue?.street[0]) || '',
    buildingNo: initialValue?.building || '',
    floor: initialValue?.floor || '',
    apt: initialValue?.apartment || '',
    notes: '',
    city: initialValue?.city || (null as City | null),
    postcode: initialValue?.postcode || '',
    region: initialValue?.region
      ? {
          name: initialValue.region.region,
          code: initialValue.region.region_code,
          id: initialValue.region.region_id,
        }
      : (null as Region | null),
    landmark: initialValue?.landmark || '',
    title: initialValue?.title || '',
    isDefault: initialValue?.default_billing || false,
  });

  function toAddressInput(): CustomerAddressInput {
    return {
      telephone: addressInput.phone,
      street: [addressInput.street],
      firstname: addressInput.fullName.trim().split(' ')[0],
      lastname: addressInput.fullName.trim().split(' ').slice(1).join(' '),
      country_code: countryCode,
      city: addressInput.city?.code,
      postcode: addressInput.postcode,
      default_billing: addressInput.isDefault,
      default_shipping: addressInput.isDefault,
      region: {
        region: addressInput.region?.name,
        region_code: addressInput.region?.code,
        region_id: addressInput.region?.id,
      },
      landmark: addressInput.landmark,
      floor: addressInput.floor,
      building: addressInput.buildingNo,
      apartment: addressInput.apt,
      title: addressInput.title,
    };
  }

  return {
    toAddressInput: () => {
      return { fullName: addressInput.fullName, ...toAddressInput() };
    },
    formInput: addressInput,
  };
}

export function useDeleteAddress() {
  const { execute, isFetching } = useMutation(DeleteAddressDocument);
  const { updateAddress } = useUpdateAddress();

  async function deleteAddress(address: CustomerAddress) {
    try {
      // if set as default, make sure to unmark it before deletion
      if (address.default_shipping || address.default_billing) {
        await updateAddress(address.id as number, { default_billing: false, default_shipping: false });
      }

      const { data, error } = await execute({ id: address.id as number });
      if (error) {
        throw new Error(error.message);
      }

      return data.response;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    deleteAddress,
    isFetching,
  };
}

export function mapAddressFormat(address: CustomerAddress | ShippingCartAddress): string {
  const fragments = [
    address.building,
    address.street?.[0],
    isString(address.region) ? address.region : (address?.region as CustomerAddress['region'])?.region,
    address.city?.name,
    address.country?.name,
    address.floor ? `Floor ${address.floor}` : '',
    address.apartment ? `${address.apartment} Apartment` : '',
  ].filter(fragment => fragment);

  return fragments.join(',');
}

export function toCartAddressInput(customerAddress: CustomerAddressInput): CartAddressInput {
  return {
    telephone: customerAddress.telephone,
    street: customerAddress.street || [],
    firstname: customerAddress.firstname || '',
    lastname: customerAddress.lastname || '',
    country_code: customerAddress.country_code as CountryCodeEnum,
    city: customerAddress.city || '',
    postcode: customerAddress.postcode,
    region: customerAddress.region?.region_code,
    landmark: customerAddress.landmark,
    floor: customerAddress.floor,
    building: customerAddress.building,
    apartment: customerAddress.apartment,
    title: customerAddress.title,
  };
}

export function isIdenticalAddresses(a: Partial<ShippingCartAddress>, b: PickupLocation) {
  return a?.street?.[0] === b.street;
}

export function isIdenticalOrderAddresses(a: Partial<OrderAddress>, b: PickupLocation) {
  return a?.street?.[0] === b.street && a.telephone === b.phone;
}

/**
 * Validates the concatenated address string by ensuring it meets a minimum length.
 *
 * This function takes a partial `CustomerAddress` or `CustomerAddressInput` object,
 * concatenates the `street`, `floor`, and `apartment` properties, and checks if the
 * resulting string is at least 10 characters long. If not, it throws an error.
 *
 * @param {Partial<CustomerAddress> | Partial<CustomerAddressInput>} address - The address object containing street, floor, and apartment properties.
 * @throws {Error} Throws an error  if the concatenated address string is less than 10 characters long.
 */
export function validateStreetConcat(address: Partial<CustomerAddress> | Partial<CustomerAddressInput>) {
  const street = address.street?.[0] || '';
  const floor = address.floor || '';
  const apartment = address.apartment || '';

  const addressConcat = `${street}${floor}${apartment}`;

  if (addressConcat.length < 10) {
    throw new Error('addressConcatError');
  }
}
