








import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useDropdown } from '@/features/dropdown';
import { useEvent } from '@/features/events';
import { ProductData } from '~/features/product';

export default defineComponent({
  name: 'AddToCartConfirmation',
  setup() {
    const item = ref();
    const { isOpen, toggle } = useDropdown();
    function close() {
      toggle(false);
    }

    useEvent('ITEM_ADDED', (product: ProductData) => {
      item.value = product;
      toggle(true);

      setTimeout(close, 4000);
    });

    return {
      item,
      isOpen,
      close,
    };
  },
});
