













import { defineComponent, PropType, ref, watch, onMounted } from '@nuxtjs/composition-api';
import { Options } from '~/features/products/utils';
import { Unpacked } from '~/types/utils';

/**
 * Handle only colors for now
 */
export default defineComponent({
  props: {
    value: { type: Object as PropType<Unpacked<Options>>, required: false, default: () => {} },
    options: {
      type: Array as PropType<Options>,
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const selectedOption = ref(props.value);

    watch(
      () => props.value,
      (newValue, oldValue) => {
        if (newValue && oldValue.slug !== newValue.slug) selectedOption.value = newValue;
      }
    );

    watch(selectedOption, option => {
      emit('option', option);
    });

    onMounted(() => {
      // only works if there is no option color
      if (props.options.filter(option => option.color).length === 0 && props.options.length) {
        // This means that the options input is not going to be used
        // so we need to emit some values to the parent ( stock  )
        emit('option', {
          stock: props.options.some(({ stock }) => stock > 0) ? 1 : 0,
        });
      }
    });

    return {
      selectedOption,
    };
  },
});
