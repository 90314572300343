


























import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { isAfter } from 'date-fns';

export default defineComponent({
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  setup(_, { emit }) {
    const isOpen = ref(true);
    function close() {
      isOpen.value = false;
      // emit close event to restore header placement after banner removal
      emit('closeBanner');
    }

    // Start date for custom message (15th Aug 2024)
    const startDate = new Date(2024, 7, 15);
    // End date for custom message (21th Aug 2024 at 11:59 PM)
    const endDate = new Date(2024, 7, 21, 23, 59);
    const now = new Date();
    // Check if current date is between start and end dates
    const showCustomMessage = computed(() => isAfter(now, startDate) && isAfter(endDate, now));

    return {
      close,
      isOpen,
      showCustomMessage,
    };
  },
});
