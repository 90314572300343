import { computed, ComputedRef, provide, ref, Ref, useAsync } from '@nuxtjs/composition-api';
import { useQuery } from 'villus';
import { useCachedSsrRef } from './serverCache';
import { mapProductListing } from './products';
import { useSetLocaleToCacheParams } from './i18n';
import { StoreConfigDocument } from '@/graphql/Config';
import { CountryCodeEnum } from '~/graphql-types.gen';
import { CONFIG_STORE_COUNTRY, CONFIG_STORE_CURRENCY } from '~/utils/provides';
import { keysOf } from '~/utils/collections';

interface StoreConfig {
  currencyCode: string | null | undefined;
  defaultTitle: string | null | undefined;
  defaultDescription: string | null | undefined;
  defaultKeywords: string | null | undefined;
  title_prefix: string | null | undefined;
  title_suffix: string | null | undefined;
  initialized: boolean; // if it was populated from API
  limited_qty_threshold: number;

  // custom configuration

  enableStorePickup: boolean;
  bestSellers: [] | Array<ReturnType<typeof mapProductListing>>;
  valentineGiftsEnabled: boolean;

  hasProductDeals: boolean;
  isSpinTheWheelEnabled: boolean;
}

interface FeatureFlags {
  spinTheWheel: boolean;
}

// 30 minutes
const CACHE_TTL = 1000 * 60 * 30;
const DEFAULT_STORE_PICKUP = true;

const featureFlags: Ref<FeatureFlags> = ref({
  spinTheWheel: false,
});

export function getFeatureFlags() {
  return featureFlags.value;
}

export function useStoreConfig() {
  const { cacheParam } = useSetLocaleToCacheParams();
  const state = useCachedSsrRef<StoreConfig>(
    cacheParam('storeConfig'),
    {
      currencyCode: null,
      defaultTitle: null,
      defaultDescription: null,
      defaultKeywords: null,
      title_prefix: null,
      title_suffix: null,
      initialized: false,
      limited_qty_threshold: 1,

      enableStorePickup: true,
      bestSellers: [],
      valentineGiftsEnabled: false,
      hasProductDeals: false,
      isSpinTheWheelEnabled: false,
    },
    CACHE_TTL
  );

  const { data, error, execute } = useQuery({
    query: StoreConfigDocument,
    fetchOnMount: false,
  });

  useAsync(async () => {
    // Skip re-fetching the store config
    if (state.value.initialized) {
      featureFlags.value = {
        spinTheWheel: state.value.isSpinTheWheelEnabled,
      };
      return;
    }

    await execute();

    if (error.value) {
      throw new Error(error.value.message);
    }

    state.value = {
      currencyCode: data.value?.storeConfig?.base_currency_code,
      defaultTitle: data.value?.storeConfig?.default_title,
      defaultDescription: data.value?.storeConfig?.default_description,
      defaultKeywords: data.value?.storeConfig?.default_keywords,
      title_prefix: data.value?.storeConfig?.title_prefix,
      title_suffix: data.value?.storeConfig?.title_suffix,
      initialized: true,
      limited_qty_threshold: data.value?.storeConfig?.limited_qty_threshold || 1,

      enableStorePickup: (data.value?.storeConfig as any)?.enableStorePickup || DEFAULT_STORE_PICKUP,
      bestSellers: data.value?.storeConfig?.bestsellers?.items?.map(mapProductListing as any) || [],
      valentineGiftsEnabled: !!data.value?.storeConfig?.valentine_gifts_enabled,
      hasProductDeals: !!data.value?.deals?.total_count,
      isSpinTheWheelEnabled: !!data.value?.storeConfig?.spin_the_wheel_enabled,
    };

    featureFlags.value = {
      spinTheWheel: !!data.value?.storeConfig?.spin_the_wheel_enabled,
    };
  });

  // Breaks down the state ref into mini smaller refs for each property
  const refs = keysOf(state.value).reduce((acc, key) => {
    const keyRef = computed(() => {
      return state.value[key];
    });

    acc[key] = keyRef;

    return acc;
  }, {} as Record<keyof typeof state['value'], ComputedRef<any>>);

  provide(CONFIG_STORE_CURRENCY, refs.currencyCode);
  // TODO: Maybe don't hard code this
  provide(CONFIG_STORE_COUNTRY, CountryCodeEnum.Eg);

  return {
    ...refs,
  };
}
