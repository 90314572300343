import { useMutation } from 'villus';
import { computed, Ref, ref, watch } from '@nuxtjs/composition-api';
import { ValidationObserver } from 'vee-validate';
import { useAlerts } from '../alerts';
import { useI18n } from '../i18n';
import { useRouter } from '../router';
import { topics, useChannels } from '../channels';
import { useExceptions } from '../exceptions';
import { useAuth } from '../auth';
import { useEvent, useEventBus } from '../events';
import {
  cartState,
  mapShippingMethod,
  useCartAttributes,
  useEwalletPaymentMethods,
  useIsGuestCart,
  usePaymentMethods,
  useResetCart,
  useShippingMethods,
} from './cart';
import { useSendGuestOtp } from './guest-verification';
import { AvailablePaymentMethod, PlaceOrderDocument } from '~/graphql/Cart';
import { Unpacked } from '~/types/utils';
import { Maybe } from '~/graphql-types.gen';
import { orderCreatedRoute } from '~/utils/orders';
import { VerificationPayload } from '~/types/verifyIdentity';

type ShippingMethod = ReturnType<typeof mapShippingMethod> | undefined;
const selectedShippingMethod = ref<ShippingMethod | undefined>(undefined);
const validPaymentState = ref<boolean>(false);

// type PlaceOrderFn = (arg0: AvailablePaymentMethod, arg1: ShippingMethod) => Promise<any>;

/**
 * Simple and generic place order method
 * ! shouldn't be used out of context
 * @returns
 */
function usePlaceOrder() {
  const { execute } = useMutation(PlaceOrderDocument);
  const { cartId } = useCartAttributes();
  async function placeOrder() {
    const { data, error } = await execute({
      input: {
        cart_id: cartId.value,
      },
    });

    return {
      order: data.response?.order,
      error,
    };
  }

  return {
    placeOrder,
  };
}

function useSetShippingMethod() {
  const { setShippingMethod: execute } = useShippingMethods();

  async function setShippingMethod(shippingMethod: ShippingMethod) {
    const { is_virtual } = useCartAttributes();
    // Don't set shipping method if the whole cart is virtual
    if (is_virtual) {
      return;
    }

    if (!shippingMethod) {
      throw new Error('No selected shipping method');
    }

    await execute({
      carrier_code: shippingMethod?.carrier_code as string,
      method_code: shippingMethod?.method_code as string,
    });
  }

  return {
    setShippingMethod,
  };
}

function useClassicPlaceOrder() {
  const { placeOrder } = usePlaceOrder();
  const { setShippingMethod } = useSetShippingMethod();
  const { setPaymentMethod } = usePaymentMethods();
  const { resetCart } = useResetCart();
  const { redirect } = useRouter();
  const { isGuestCart } = useIsGuestCart();
  const { resolveException } = useExceptions('cart');

  async function classicPlaceOrder(paymentMethod: Partial<AvailablePaymentMethod>, shippingMethod: ShippingMethod) {
    await setShippingMethod(shippingMethod);

    if (!paymentMethod) {
      throw new Error('No selected payment method');
    }

    await setPaymentMethod(paymentMethod.code as string);
    const { order, error } = await placeOrder();

    if (error && resolveException(error)?.level === 'DANGER') {
      throw new Error(resolveException(error)?.message);
    }

    if (error || !order?.order_number) {
      throw new Error(error.message);
    }

    resetCart();
    redirect(orderCreatedRoute(order?.order_number?.toString(), isGuestCart.value));
  }

  return {
    classicPlaceOrder,
  };
}

function usePaymobPlaceOrder() {
  const { setShippingMethod } = useSetShippingMethod();
  const { setPaymentMethod } = usePaymentMethods(
    computed(() => ({
      withIframeEnabled: true,
    }))
  );

  async function paymobPlaceOrder(paymentMethod: Partial<AvailablePaymentMethod>, shippingMethod: ShippingMethod) {
    await setShippingMethod(shippingMethod);

    if (!paymentMethod) {
      throw new Error('No selected payment method');
    }

    const response = await setPaymentMethod(paymentMethod.code as string);
    if (!response?.cart.acceptIframeUrl) throw new Error('cannot place order');

    if (response?.cart.acceptIframeUrl) window.location.replace(response?.cart.acceptIframeUrl);
  }

  return {
    paymobPlaceOrder,
  };
}

function useExternalPlaceOrder() {
  const { setShippingMethod } = useSetShippingMethod();
  const { setPaymentMethod } = usePaymentMethods(
    computed(() => ({
      withIframeEnabled: true,
    }))
  );

  async function externalPlaceOrder(paymentMethod: Partial<AvailablePaymentMethod>, shippingMethod: ShippingMethod) {
    await setShippingMethod(shippingMethod);

    if (!paymentMethod) {
      throw new Error('No selected payment method');
    }

    const response = await setPaymentMethod(paymentMethod.code as string);
    if (!response?.cart.acceptIframeUrl) throw new Error('cannot place order');

    if (response?.cart.acceptIframeUrl) window.location.replace(response?.cart.acceptIframeUrl);
  }

  return {
    externalPlaceOrder,
  };
}

export function useEwalletPlaceOrder() {
  const { setPaymentMethod } = useEwalletPaymentMethods();

  async function eWalletPlaceOrder(phoneNumber: string) {
    if (!phoneNumber) {
      throw new Error('Phone number is not inserted');
    }

    const response = await setPaymentMethod(phoneNumber);
    if (!response?.cart.acceptIframeUrl) throw new Error('validation.walletNumberError');

    if (response?.cart.acceptIframeUrl) window.location.replace(response?.cart.acceptIframeUrl);
  }

  return {
    eWalletPlaceOrder,
  };
}

/**
 * Placing Order Can Take multiple Shapes/steps/algorithms based on the options selected from the payment method
 * This API resolves and execute place order based on that options passed to it
 * @returns
 */
function useApplicationPlaceOrder(
  selectedPaymentMethod: Ref<Maybe<Partial<AvailablePaymentMethod>>>,
  shippingMethod: Ref<ShippingMethod>
) {
  const state = ref<'idle' | 'loading' | 'error'>('idle');

  const { classicPlaceOrder } = useClassicPlaceOrder();
  const { paymobPlaceOrder } = usePaymobPlaceOrder();
  const { externalPlaceOrder } = useExternalPlaceOrder();
  const { setShippingMethod } = useSetShippingMethod();

  const { push } = useChannels();

  const { isLoggedIn } = useAuth();
  const { emit } = useEventBus();
  const { sendGuestOtp } = useSendGuestOtp();
  const isCartVerified = computed(() => {
    return cartState.isCartVerified;
  });

  /**
   * change the state from error to idle when payment method got changed
   */
  watch(selectedPaymentMethod, () => {
    if (state.value === 'error') state.value = 'idle';
  });

  useEvent('OTP_CLOSED', () => {
    state.value = 'idle';
  });

  /**
   *
   */
  async function placeOrder() {
    if (
      selectedPaymentMethod.value &&
      ['cashondelivery', 'cardondelivery', 'free'].includes(selectedPaymentMethod.value?.code as string)
    ) {
      try {
        state.value = 'loading';

        if (!isLoggedIn.value && !isCartVerified.value) {
          sendGuestOtp(String(cartState.address?.telephone));

          emit('ENTER_OTP', {
            identityString: cartState.address?.telephone,
            readonly: true,
            validationOnly: false,
            // Get last 4 digits of phone number
            mask: cartState.address?.telephone?.slice(-4),
            guestVerification: true,
          } as VerificationPayload);
          return;
        }
        await classicPlaceOrder(selectedPaymentMethod.value as Partial<AvailablePaymentMethod>, shippingMethod.value);
      } catch (e) {
        state.value = 'error';
        throw e;
      }
      return;
    }

    if (selectedPaymentMethod.value?.code === 'robusta_accept_cc') {
      try {
        state.value = 'loading';

        await paymobPlaceOrder(selectedPaymentMethod.value as Partial<AvailablePaymentMethod>, shippingMethod.value);
      } catch (e) {
        state.value = 'error';
      }

      return;
    }

    if (
      selectedPaymentMethod.value?.code &&
      [
        'robusta_accept_valu',
        'robusta_accept_premium',
        'robusta_accept_sympl',
        'robusta_accept_installments',
        'robusta_accept_souhoola',
        'robusta_accept_forsa',
      ].includes(selectedPaymentMethod.value?.code)
    ) {
      try {
        state.value = 'loading';

        await externalPlaceOrder(selectedPaymentMethod.value as Partial<AvailablePaymentMethod>, shippingMethod.value);
      } catch (e) {
        state.value = 'error';
      }

      return;
    }

    if (selectedPaymentMethod.value?.code === 'robusta_accept_mobile_wallets') {
      try {
        state.value = 'loading';

        await setShippingMethod(shippingMethod.value);
        push(topics.cart.placeOrder);
        return;
      } catch (e) {
        state.value = 'error';
      }
    }

    state.value = 'error';
    throw new Error(' Payment method is not supported ');
  }

  return {
    placeOrder,
    state,
  };
}

export function useCheckoutShippingMethod() {
  const { setShippingMethod, shippingMethods, isFetching: isUpdatingShippingMethod } = useShippingMethods();
  const { isStorePickup } = useCartAttributes();

  watch(selectedShippingMethod, async value => {
    if (!value) {
      return;
    }

    await setShippingMethod({
      carrier_code: value.carrier_code,
      method_code: value.method_code as string,
    });
  });

  /**
   * Setting default selected shipping method
   */
  selectedShippingMethod.value = !isStorePickup.value
    ? selectedShippingMethod.value
      ? selectedShippingMethod.value
      : shippingMethods.value.find(method => method.method_code === 'homedelivery')
    : shippingMethods.value.find(method => method.method_code === 'store-pickup');

  // const unwatch = watch(shippingMethods, newShippingMethods => {
  //   if (newShippingMethods && newShippingMethods.length && !isStorePickup.value) {
  //     selectedShippingMethod.value = newShippingMethods[0]; // By default select the first one
  //   }
  //   unwatch();
  // });

  return {
    shippingMethods,
    setShippingMethod,
    isUpdatingShippingMethod,
    selectedShippingMethod,
  };
}

export function useCheckoutPaymentMethod() {
  const { setPaymentMethod, paymentMethods, isFetching: isUpdatingPaymentState } = usePaymentMethods();
  const selectedPaymentMethod = ref<Unpacked<typeof paymentMethods['value']> | undefined>(undefined);
  const { cartId, selectedPaymentMethod: paymentMethod } = useCartAttributes();

  watch(selectedPaymentMethod, async value => {
    if (!value) {
      return;
    }

    await setPaymentMethod(value.code);
  });

  /**
   * Setting default selected payment method
   */
  watch(paymentMethods, newPaymentMethods => {
    const prevPaymentMethod = newPaymentMethods.find(m => m?.code === paymentMethod.value?.code);

    if (!cartId.value && newPaymentMethods && newPaymentMethods.length) {
      const unwatch = watch(cartId, newCartId => {
        if (newCartId) {
          selectedPaymentMethod.value =
            prevPaymentMethod ?? newPaymentMethods.find(method => method?.code === 'robusta_accept_cc'); // Select the previously selected payment method or credit card / debit card by default
          unwatch();
        }
      });
      return;
    }

    selectedPaymentMethod.value =
      prevPaymentMethod ?? newPaymentMethods.find(method => method?.code === 'robusta_accept_cc'); // Select the previously selected payment method or credit card / debit card by default
  });

  return {
    paymentMethods,
    setPaymentMethod,
    isUpdatingPaymentState,
    selectedPaymentMethod,
  };
}

/**
 * Generic interface for checking out mutation again
 */
export function useCheckout() {
  const {
    isUpdatingPaymentState,
    selectedPaymentMethod,
    paymentMethods,
    setPaymentMethod,
  } = useCheckoutPaymentMethod();
  const {
    isUpdatingShippingMethod,
    selectedShippingMethod,
    shippingMethods,
    setShippingMethod,
  } = useCheckoutShippingMethod();
  const { error } = useAlerts();
  const { t } = useI18n();
  const { placeOrder, state } = useApplicationPlaceOrder(selectedPaymentMethod, selectedShippingMethod);
  const isPlacingOrder = ref(false);
  const { subscribe } = useChannels();
  const subscriptionHandler = ref<any>();
  const formRef = ref<InstanceType<typeof ValidationObserver> | null>(null);

  async function submitCheckout() {
    try {
      await placeOrder();
    } catch (e) {
      error('error', t((e as Error).message).toString());
    }
  }

  if (!subscriptionHandler.value)
    subscriptionHandler.value = subscribe(topics.cart.placingOrderError, () => {
      state.value = 'error';
    });

  /**
   * Watching for form ref state change.
   */

  watch(
    () => formRef.value?.errors,
    newErrors => {
      if (
        state.value === 'error' &&
        newErrors &&
        Object.keys(newErrors).length &&
        Object.keys(newErrors)?.every(errorKey => newErrors[errorKey].length === 0)
      ) {
        state.value = 'idle';
      }
    }
  );

  return {
    selectedPaymentMethod,
    selectedShippingMethod,
    setPaymentMethod,
    setShippingMethod,
    paymentMethods,
    shippingMethods,

    /**
     * aggregate loading state to block the user when its updating payment or its fetching shipping method
     */

    loading: computed(() => state.value === 'loading'),
    isUpdatingPaymentState,
    isUpdatingShippingMethod,
    isInvalidOptions: computed(() => state.value === 'error'),
    submitCheckout,
    isPlacingOrder,
    formRef,
  };
}

export function useValidPayment() {
  const { redirect } = useRouter();
  function setValidPaymentState(value: boolean) {
    validPaymentState.value = value;
  }

  function checkValidPayment() {
    if (!validPaymentState.value) {
      redirect('/');
    }
  }

  return { setValidPaymentState, checkValidPayment };
}
