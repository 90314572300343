import { useQuery } from 'villus';
import { computed, useAsync } from '@nuxtjs/composition-api';
import { useCachedSsrRef } from './serverCache';
import { useSetLocaleToCacheParams } from './i18n';
import { CategoryListDocument, CategoryListQuery } from '@/graphql/Category';
import { Unpacked } from '~/types/utils';
import { toNonNullable } from '~/utils/collections';
import { CategoryTree, OfferCardFragment } from '~/graphql/fragments';

export type Category = Omit<
  NonNullable<Unpacked<NonNullable<CategoryListQuery['categories']>['items']>>,
  'children'
> & {
  children?: Category[];
  meta_title?: string;
  meta_description?: string;
  meta_keywords?: string;
  offer?: OfferCardFragment;
  level: number;
  order?: number;
};

/**
 * used for tracking the race condition
 * loading means to lock this statement until it get changed to its value
 */
let CATEGORY_QUERY_PENDING: Partial<Category>[] | 'loading' | undefined;
const QUERY_THRESHOLD = 450;

export function useCategories() {
  const { cacheParam } = useSetLocaleToCacheParams();

  const categories = useCachedSsrRef<ReturnType<typeof mapCategoryTree>[]>(cacheParam('categories'), []);

  const { execute } = useQuery({
    query: CategoryListDocument,
    fetchOnMount: false,
  });

  useAsync(async () => {
    if (categories.value?.length) {
      return;
    }

    if (CATEGORY_QUERY_PENDING === 'loading') {
      // Wait until PENDING is false
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await new Promise((resolve, reject) => {
        let count = 0;

        const interval = setInterval(() => {
          if (CATEGORY_QUERY_PENDING !== 'loading') {
            // eslint-disable-next-line no-console
            console.log(
              '[Category Caching]: Result is fetched , Now Setting the category to the one got from another process , ..continue'
            );

            clearInterval(interval);
            resolve(true);
          }

          if (count > QUERY_THRESHOLD) {
            // eslint-disable-next-line no-console
            console.log(
              '[Category Caching]: Timeout : Unable to get category query from another process , resetting Category query and refetch again '
            );
            resolve(true);
            CATEGORY_QUERY_PENDING = undefined;
          } else {
            count++;
          }
        }, 100);
      });
    }
    if (CATEGORY_QUERY_PENDING !== 'loading' && CATEGORY_QUERY_PENDING?.length) {
    }

    // Don't refetch categories unless cache expired
    if (categories.value.length) {
      return;
    }

    CATEGORY_QUERY_PENDING = 'loading';

    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    categories.value = sortByPosition(toNonNullable(data?.categories?.items).map(mapCategoryTree));

    CATEGORY_QUERY_PENDING = flatten(categories.value);
  });

  const flatCategories = computed(() => {
    return flatten(categories.value);
  });

  // const featured = computed(() => {
  //   return flatCategories.value.filter(category => (category as any).is_homepage_featured);
  // });

  const findBySlug = (slug: string) => {
    return flatCategories.value.find((c: any) => 'url_key' in c && c.url_key === slug);
  };
  const findByName = (name: string) => {
    return flatCategories.value.find((c: any) => 'name' in c && c.name.toLowerCase() === name.toLowerCase());
  };

  return {
    categoryTree: categories,
    findBySlug,
    flatCategories,
    findByName,
    // featured,
  };
}

export function useMegaMenuCategories() {
  const { cacheParam } = useSetLocaleToCacheParams();

  const categories = useCachedSsrRef<ReturnType<typeof mapCategoryTree>[]>(cacheParam('megaMenuCategories'), []);

  const { execute } = useQuery({
    query: CategoryListDocument,
    fetchOnMount: false,
  });

  useAsync(async () => {
    if (CATEGORY_QUERY_PENDING === 'loading') {
      // Wait until PENDING is false
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await new Promise((resolve, reject) => {
        let count = 0;

        const interval = setInterval(() => {
          if (CATEGORY_QUERY_PENDING !== 'loading') {
            // eslint-disable-next-line no-console
            console.log(
              '[Category Caching]: Result is fetched , Now Setting the category to the one got from another process , ..continue'
            );

            clearInterval(interval);
            resolve(true);
          }

          if (count > QUERY_THRESHOLD) {
            // eslint-disable-next-line no-console
            console.log(
              '[Category Caching]: Timeout : Unable to get category query from another process , resetting Category query and refetch again '
            );
            resolve(true);
            CATEGORY_QUERY_PENDING = undefined;
          } else {
            count++;
          }
        }, 100);
      });
    }
    if (CATEGORY_QUERY_PENDING !== 'loading' && CATEGORY_QUERY_PENDING?.length) {
    }

    // Don't refetch categories unless cache expired
    if (categories.value.length) {
      return;
    }

    CATEGORY_QUERY_PENDING = 'loading';

    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    categories.value = sortByPosition(
      toNonNullable(data?.categories?.items?.[0]?.children).map(c => mapMegaMenuCategoryTree(c))
    );

    CATEGORY_QUERY_PENDING = flatten(categories.value);
  });

  const flatCategories = computed(() => {
    return flatten(categories.value);
  });

  const findBySlug = (slug: string) => {
    return flatCategories.value.find((c: any) => 'url_key' in c && c.url_key === slug);
  };

  return {
    megaMenuCategories: computed({
      get: () =>
        categories.value
          .filter(category => category.includeInMenu === 1)
          .slice(0, 9)
          .sort((a, b) => (a.order || Number.MAX_VALUE) - (b.order || Number.MAX_VALUE)),
      set: () => {},
    }),
    findBySlug,
  };
}

export function useHomeFeaturedCategories() {
  const { cacheParam } = useSetLocaleToCacheParams();

  const categories = useCachedSsrRef<ReturnType<typeof mapCategoryTree>[]>(
    cacheParam('home-page-featured-categories'),
    []
  );

  const { execute } = useQuery({
    query: CategoryListDocument,
    fetchOnMount: false,
  });

  useAsync(async () => {
    if (categories.value?.length) {
      return;
    }

    if (CATEGORY_QUERY_PENDING === 'loading') {
      // Wait until PENDING is false
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await new Promise((resolve, reject) => {
        let count = 0;
        const interval = setInterval(() => {
          if (CATEGORY_QUERY_PENDING !== 'loading') {
            // eslint-disable-next-line no-console
            console.log(
              '[Category Caching]: Result is fetched , Now Setting the category to the one got from another process , ..continue'
            );

            clearInterval(interval);
            resolve(true);
          }

          if (count > QUERY_THRESHOLD) {
            // eslint-disable-next-line no-console
            console.log(
              '[Category Caching]: Timeout : Unable to get category query from another process , resetting Category query and refetch again '
            );
            resolve(true);
            CATEGORY_QUERY_PENDING = undefined;
          } else {
            count++;
          }
        }, 100);
      });
    }
    if (CATEGORY_QUERY_PENDING !== 'loading' && CATEGORY_QUERY_PENDING?.length) {
    }

    // Don't refetch categories unless cache expired
    if (categories.value.length) {
      return;
    }

    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    categories.value = sortByPosition(
      toNonNullable(data?.categories?.items?.[0]?.children).map(c => mapHomePageCategoryTree(c))
    );

    CATEGORY_QUERY_PENDING = flatten(categories.value);
  });

  const flatCategories = computed(() => {
    return flatten(categories.value);
  });

  const findBySlug = (slug: string) => {
    return flatCategories.value.find((c: any) => 'url_key' in c && c.url_key === slug);
  };

  return {
    megaMenuCategories: computed({
      get: () =>
        flatCategories.value
          .filter(category => category.includeInHomePage === 1)
          .slice(0, 9)
          .sort(
            (a, b) => (Number(a.featured_order) || Number.MAX_VALUE) - (Number(b.featured_order) || Number.MAX_VALUE)
          ),
      set: () => {},
    }),
    findBySlug,
  };
}

export function mapCategoryTree(
  apiItem: NonNullable<Unpacked<NonNullable<CategoryListQuery['categories']>['items']>>
): Category {
  return {
    ...apiItem,
    level: ((apiItem as any).level ?? 1) - 1,
    children: apiItem.children
      ? toNonNullable(apiItem.children as NonNullable<CategoryListQuery['categories']>['items']).map(mapCategoryTree)
      : undefined,
  };
}

/**
 *
 * @param apiItem
 * @param siblings
 * @param parent
 * @description mapping to optimized version of mega menu categories tree
 * @returns
 */
export function mapMegaMenuCategoryTree(
  apiItem: NonNullable<Unpacked<NonNullable<CategoryListQuery['categories']>['items']>>
): Category {
  return {
    id: apiItem.id,
    uid: apiItem.uid,
    name: apiItem.name,
    url_key: apiItem.url_key,
    includeInMenu: apiItem.includeInMenu,
    includeInHomePage: apiItem.includeInHomePage,
    meta_title: (apiItem as any).meta_title,
    meta_description: (apiItem as any).meta_description,
    level: ((apiItem as any).level ?? 1) - 1,
    ...((apiItem as Category).offer ? { offer: (apiItem as Category).offer } : {}),
    image: apiItem.image,
    path: apiItem.path,
    children: apiItem.children
      ? toNonNullable(apiItem.children as NonNullable<CategoryListQuery['categories']>['items']).map(c =>
          mapMegaMenuCategoryTree(c)
        )
      : undefined,
    order: Number(apiItem.featured_order || 0),
  };
}

/**
 *
 * @param apiItem
 * @param siblings
 * @param parent
 * @description mapping to optimized version of mega menu categories tree
 * @returns
 */
export function mapHomePageCategoryTree(
  apiItem: NonNullable<Unpacked<NonNullable<CategoryListQuery['categories']>['items']>>
): Category {
  return {
    id: apiItem.id,
    uid: apiItem.uid,
    name: apiItem.name,
    url_key: apiItem.url_key,
    includeInMenu: apiItem.includeInMenu,
    includeInHomePage: apiItem.includeInHomePage,
    featured_order: apiItem.featured_order,
    meta_title: (apiItem as any).meta_title,
    meta_description: (apiItem as any).meta_description,
    level: ((apiItem as any).level ?? 1) - 1,
    ...((apiItem as Category).offer ? { offer: (apiItem as Category).offer } : {}),
    image: apiItem.image,
    path: apiItem.path,
    children: apiItem.children
      ? toNonNullable(apiItem.children as NonNullable<CategoryListQuery['categories']>['items']).map(c =>
          mapMegaMenuCategoryTree(c)
        )
      : undefined,
  };
}

function flatten<T extends { children?: T[] }>(arr: T[]): T[] {
  return arr.reduce((acc: T[], value) => {
    const category = { ...value };
    acc.push(category);
    if (value.children) {
      acc = acc.concat(flatten(value.children));
    }

    return acc;
  }, []);
}

/**
 * Recursively sorts the categories by position
 */
function sortByPosition<T extends { children?: T[]; position?: number | null }>(arr: T[]): T[] {
  const sorted: T[] = [];
  arr.forEach(item => {
    if (item.children && item.children.length) {
      item.children = sortByPosition(item.children);
    }

    sorted.push(item);
  });

  sorted.sort((a, b) => (a.position || Number.MAX_VALUE) - (b.position || Number.MAX_VALUE));

  return sorted;
}

/**
 * sort categories by featured sort order
 * then filter them by is_shown_in_homepage flag
 * then get the first two in the list
 * to display their content in the two featured category sliders in the homepage
 */
export function prepCategoriesForSliders(categories: CategoryTree[]) {
  return sortByPosition(toNonNullable(categories).map(mapCategoryTree));
}
