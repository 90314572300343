import { render, staticRenderFns } from "./minimal-with-footer.vue?vue&type=template&id=6be32fea&"
import script from "./minimal-with-footer.vue?vue&type=script&lang=ts&"
export * from "./minimal-with-footer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AppLink.js').default,TheFooter: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/TheFooter.vue').default})
