var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.as,_vm._g(_vm._b({tag:"component",staticClass:"AppButton",class:{
    'is-white': _vm.white,
    'is-inverted': _vm.inverted,
    'is-danger': _vm.variant === 'danger',
    'is-gray': _vm.variant === 'gray',
    'is-secondary': _vm.variant === 'secondary',
    'disable-hover': _vm.disableHover,
    'opacity-50 pointer-events-none': _vm.disabled,
    'cursor-wait pointer-events-none': _vm.loading,
    'rounded-md': _vm.rounded,
  },attrs:{"disabled":_vm.disabled,"aria-label":_vm.ariaLabel}},'component',_vm.$attrs,false),_vm.$listeners),[(_vm.loading)?_c('Spinner',{staticClass:"w-6 h-6 mx-auto",class:{
      'text-white': _vm.inverted,
    }}):_vm._t("default"),_vm._v(" "),(_vm.withArrow)?_c('div',{staticClass:"AppButton__ArrowContainer"},[_c('svg',{staticClass:"AppButton__Arrow",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 72 17"}},[_c('g',{attrs:{"fill":"none","fill-rule":"evenodd"}},[_c('path',{attrs:{"d":"M.5 8.5H36"}}),_vm._v(" "),_c('path',{attrs:{"d":"M.5 8.5H36","transform":"translate(34, 0)"}}),_vm._v(" "),_c('path',{attrs:{"d":"M63 1l7.5 7.5L63 16"}})])])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }