














































































































































































































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { CombinedError } from 'villus';
import { ValidationObserver } from 'vee-validate';
import { useAlerts } from '~/features/alerts';
import { useSubscribeToNewsletter } from '~/features/contact';
import { useI18n } from '~/features/i18n';

export default defineComponent({
  components: {
    ValidationObserver,
  },
  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { submitForm, input } = useSubscribeToNewsletter();
    const { success, error } = useAlerts();
    const { t } = useI18n();
    const isSubmitting = ref(false);

    async function onSubmit() {
      try {
        isSubmitting.value = true;

        await submitForm();
        success(t('success').toString(), t('newsLetterSuccess').toString());
        input.email = '';
      } catch (e) {
        if (e instanceof CombinedError) {
          error('error', e.message);
        }
      } finally {
        isSubmitting.value = false;
      }
    }
    function scrollToTop() {
      window.scrollTo(0, 0);
    }

    return {
      scrollToTop,
      onSubmit,
      input,
      isSubmitting,
    };
  },
});
