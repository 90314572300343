//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Alert from './Alert';

export default {
  components: {
    Alert,
  },
  computed: {
    ...mapGetters({
      alerts: 'alerts/items',
    }),
  },
};
