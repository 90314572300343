




















































import { Organization, SearchAction, WebSite } from 'schema-dts';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  useContext,
  watchEffect,
} from '@nuxtjs/composition-api';
import { throttle } from 'throttle-debounce';
import { useJsonLd, useSeoLinks } from '~/features/seo';
import { useI18nCookie } from '~/features/i18n';
import { useComparison } from '~/features/compare';
import { useCartAttributes } from '~/features/cart';
import { useRouter } from '~/features/router';
import { useLogout } from '~/features/auth';
import { useEvent } from '~/features/events';

export default defineComponent({
  setup() {
    useSeoLinks();
    useI18nCookie();
    const { shouldShowComparison } = useComparison();
    const { spinTheWheelData } = useCartAttributes();
    const { $config } = useContext();
    const url = $config.appURL;
    const showHeader = ref(true);
    const currentScrollPosition = ref(0);
    const lastScrollPosition = ref(0);
    const scrollOffset = ref(100);
    const downloadAppUrl = ref('');
    const { route } = useRouter();
    const isShown = ref(false);
    const { logout } = useLogout();

    useEvent('LOGOUT_CONFIRM', () => {
      isShown.value = true;
    });

    function loggingOut() {
      logout();
      isShown.value = false;
    }

    // Check if on cart page
    const isCartPage = computed(() => route.value.path.includes('cart'));

    const onScroll = throttle(300, () => {
      currentScrollPosition.value = window.pageYOffset;

      if (window.pageYOffset < 0) {
        return;
      }
      // if the header's distance covered still hasn't exceeded the scrolloffset -> return
      if (Math.abs(window.pageYOffset - lastScrollPosition.value) < scrollOffset.value) {
        return;
      }

      showHeader.value = window.pageYOffset < lastScrollPosition.value;
      lastScrollPosition.value = window.pageYOffset;
    });

    watchEffect(() => {
      if (process.server) {
        return;
      }
      // set current scroll position in case page is loaded with scroll
      currentScrollPosition.value = window.pageYOffset;
      window.addEventListener('scroll', onScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', onScroll);
    });

    onMounted(() => {
      const mobileOperatingSystem = getMobileOperatingSystem();

      // if no downloadAppUrl stored then it hasn't been displayed before within current session
      // save it and set ref to display banner
      if (process.browser && !sessionStorage.getItem('downloadAppUrl')) {
        if (mobileOperatingSystem === 'Android') {
          downloadAppUrl.value = 'https://play.google.com/store/apps/details?id=com.mazayastores.android&hl=en&gl=US';
        } else if (mobileOperatingSystem === 'iOS') {
          downloadAppUrl.value = 'https://apps.apple.com/eg/app/mazaya-stores/id1562432316';
        }
        sessionStorage.setItem('downloadAppUrl', downloadAppUrl.value);
      }
    });

    /**
     * uses the navigator useAgent to detect the current OS
     */
    function getMobileOperatingSystem() {
      const userAgent = navigator.userAgent || navigator.vendor;

      if (/android/i.test(userAgent)) {
        return 'Android';
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'iOS';
      }

      return 'unknown';
    }

    // https://developers.google.com/search/docs/data-types/sitelinks-searchbox
    const websiteWithSearchAction: WebSite = {
      '@type': 'WebSite',
      url,
      potentialAction: {
        '@type': 'SearchAction',
        target: `${url}/search?q={search_term_string}`,
        'query-input': 'required name=search_term_string',
      } as SearchAction,
    };

    const org: Organization = {
      '@id': url,
      '@type': 'Organization',
      // TODO: Organization name
      name: 'Unique',
      url,
      logo: `${url}/img/logo.png`,
      sameAs: [
        // TODO: Any social links to facebook/twitter/whatever
      ],
    };

    useJsonLd({
      '@context': {
        '@vocab': 'http://schema.org/',
      },
      '@graph': [websiteWithSearchAction, org],
    });

    return {
      loggingOut,
      shouldShowComparison,
      showHeader,
      currentScrollPosition,
      downloadAppUrl,
      // Both variables to determine if there's a timer
      spinTheWheelData,
      isCartPage,
      isShown,
    };
  },
  head: {},
});
