import { computed, ref, watch } from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import { useEventBus } from './events';
import { useSearchProducts as useProducts } from './products';
import { useSearchBrands } from './brands';
import { TRACKING_EVENTS } from './trackingHandlers';

export function useSearchProduct(
  initialValue: string = '',
  opts: { passive?: Boolean } = {
    passive: true,
  }
) {
  const confirmedSearchWord = ref('');

  const searchBy = ref<string>(initialValue);
  const searchByQueryParam = ref<string>('');
  const { emit } = useEventBus();

  const { products: result, execute, isFetching, categoriesResult } = useProducts(
    computed(() => ({
      page: 1,
      pageSize: 15,
      search: searchByQueryParam.value,
    })),
    {
      fetchOnMount: !opts?.passive,
    }
  );
  const { brands, execute: fetchBrands, isFetching: isFetchingBrands } = useSearchBrands(
    computed(() => ({
      page: 1,
      pageSize: 10,
      filter: { name: { match: searchByQueryParam.value } },
    })),
    {
      fetchOnMount: !opts?.passive,
    }
  );

  watch(
    searchBy,
    debounce(value => {
      confirmedSearchWord.value = value;
      searchByQueryParam.value = value;
      emit(TRACKING_EVENTS.SEARCH, value);
    }, 400),
    {
      immediate: true,
    }
  );

  return {
    searchBy,
    result,
    execute,
    isFetching,
    categoriesResult,
    confirmedSearchWord,
    brands,
    fetchBrands,
    isFetchingBrands,
  };
}
