




























































import { defineComponent, onBeforeUnmount, PropType, watch } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VariantModalDialog',
  props: {
    value: {
      type: null,
      default: false,
    },
    variant: {
      type: String as PropType<'primary' | 'secondary' | 'warn' | 'bottom'>,
      default: 'primary',
      validator: (value: string) => ['primary', 'secondary', 'warn', 'bottom'].includes(value),
    },
    disableDesktop: {
      type: Boolean,
      default: false,
    },
    closeButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    function close() {
      if (props.closeButton) {
        emit('input', false);
      }
    }

    watch(
      () => props.value,
      newValue => {
        document.body.classList.toggle('overflow-hidden', newValue);
      }
    );
    onBeforeUnmount(() => window.document.body.classList.toggle('overflow-hidden', false));

    return {
      close,
    };
  },
});
