




















import { computed, defineComponent, PropType, toRefs } from '@nuxtjs/composition-api';
import { useQuantityControl } from '~/features/products/quantity-control';
import { EnteredOptionInput, CustomizableOptionInput } from '~/graphql-types.gen';

/**
 *
 * @description this one controls the amount of items to be added to the cart through the qty controls
 *
 * @case when the product min amount on cart is reached - toggle the remove from cart button
 */
export default defineComponent({
  name: 'ProductCardQtyControl',
  props: {
    sku: {
      type: String,
      required: true,
    },
    parentSku: {
      type: String,
      required: false,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    stock: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: '',
    },

    /**
     *
     * Entered Options
     */
    options: {
      type: Object as PropType<{
        entered_options?: Array<EnteredOptionInput>;
        customizable_options?: Array<CustomizableOptionInput>;
      }>,
      default: () => {},
    },
  },
  setup(props) {
    const { max, min, stock } = toRefs(props);
    const { count, items, requestChangeCount, maxQuantity } = useQuantityControl(
      computed(() => props.sku),
      max,
      min,
      stock,
      computed(() => props.parentSku || null),
      computed(() => props.options)
    );

    return { count, requestChangeCount, items, maxQuantity };
  },
});
