import { defineNuxtMiddleware } from '@nuxtjs/composition-api';

/**
 * Used to inject referral page to cookies
 */
export default defineNuxtMiddleware(function ReferralsMiddleware({ req, res }) {
  if (!req || !res) return;
  // get request url
  const url = req.url;
  // Extract the path from the url
  const path = url?.split(/[?#]/)[0] || '/';

  // set cookie
  res.setHeader('Set-Cookie', `referrer=${path}; Path=/;`);
});
