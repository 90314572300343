import { useQuery } from 'villus';
import { onMounted, Ref, ref, watch } from '@nuxtjs/composition-api';
import type { Route } from 'vue-router';
import { useExceptions } from './exceptions';
import { mapProductListing } from './products';
import { CarouselByPageDocument, CarouselByPageQuery } from '~/graphql/Carousels';
import { toNonNullable } from '~/utils/collections';
import { Unpacked } from '~/types/utils';
export function useGetCarousels() {
  const { execute, isFetching } = useQuery({
    query: CarouselByPageDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });
  const { resolveException } = useExceptions('carousels');

  async function executeGetCarousels(page: string) {
    if (!page) throw new Error('page cannot be undefined ');

    const { data, error } = await execute({
      variables: {
        page,
      },
    });
    const exception = error && resolveException(error);

    if (exception?.level === 'DANGER') {
      throw new Error(exception.message);
    }

    return data?.carousels
      .sort((a: any, b: any) => (a.position || Number.MAX_VALUE) - (b.position || Number.MAX_VALUE))
      .filter(carousel => carousel?.name && carousel?.products?.items?.length)
      .map(mapCarouselItems);
  }

  return {
    isFetching,
    executeGetCarousels,
  };
}

const blacklist = ['/en', '/ar'];
/**
 * useCarousel utility composition function
 *
 * [ fetch and return carousels data when ->]
 *  1-  trigger on component mount and refetch the data
 *  2- watch for route change and refetch the data
 *
 * TODO lazy carousel evaluation
 *
 */
export function useCarousel(route: Ref<Route>) {
  const { executeGetCarousels, isFetching } = useGetCarousels();

  const carouselInfo = ref<any>([]);
  const error = ref();

  async function refetchCarouselInfo(page: string) {
    try {
      if (!page || blacklist.includes(page)) return;
      carouselInfo.value = [];

      const response = await executeGetCarousels(page);

      carouselInfo.value = response;
    } catch (e) {
      error.value = e;
    }
  }

  onMounted(async () => {
    await refetchCarouselInfo(route.value.path);
  });

  watch(route, newRoute => {
    refetchCarouselInfo(newRoute.path.replace(/\/+$/, ''));
  });

  return {
    carouselInfo,
    isFetching,
    error,
  };
}

export function mapCarouselItems(carousel: Unpacked<CarouselByPageQuery['carousels']>) {
  return {
    ...carousel,
    subTitle: carousel?.subtitle,
    title: carousel?.title,
    items: toNonNullable(carousel?.products?.items).map(mapProductListing),
  };
}
