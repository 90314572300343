import { computed, onMounted, reactive, ref, watch } from '@nuxtjs/composition-api';
import { get, set } from 'idb-keyval';
import { intersection } from 'lodash-es';
import { useProducts } from './products';
import { useRouter } from './router';
import { lastItem } from '~/utils/collections';
import { Unpacked } from '~/types/utils';

const DB_KEY = 'compare_skus';

type CompareItem = {
  sku: string;
  categoryIds: number[] | [];
};

const skus = ref<CompareItem[]>([]);

const shouldShowComparison = reactive({
  value: true,
  smallScreenOnly: false,
});

export function useComparison() {
  const { route } = useRouter();
  const variables = computed(() => {
    const currentSkus = skus.value;
    return currentSkus.length
      ? {
          pageSize: 4,
          withAttributes: true,
          filter: { sku: { in: skus.value.map(item => item.sku) } },
        }
      : {
          pageSize: 0,
        };
  });

  const { products, isFetching } = useProducts(variables, { fetchOnMount: false });

  watch(route, () => {
    shouldShowComparison.value = true;
    shouldShowComparison.smallScreenOnly = false;
  });

  function setShouldShowComparison(newValue: boolean, smallScreenOnly = false) {
    shouldShowComparison.value = newValue;
    shouldShowComparison.smallScreenOnly = smallScreenOnly;
  }

  onMounted(async () => {
    const items = (await get<CompareItem[]>(DB_KEY)) || [];
    // skip if both lists are empty
    if (!items.length && !skus.value.length) {
      return;
    }

    // this will trigger the fetching
    skus.value = [...new Set(items)];
  });

  const itemsWithAddNodes = computed(() => {
    type Product = Unpacked<typeof products['value']> | null;
    const items = [...products.value] as Product[];
    const renderItems: Partial<Product>[] = skus.value.map((sku, i) => {
      const item = items.find(item => item?.sku === sku.sku);
      if (item) {
        return item;
      } else {
        return {
          id: i * -1,
          name: sku.sku,
          sku: sku.sku,
          thumb: {
            src: '/images/default-product-img.png',
            alt: 'Dummy product image',
          },
          stock: 100,
        };
      }
    });

    if (renderItems.length < 4) {
      renderItems.push(null);
    }

    return renderItems;
  });

  /**
   * Returns the common category for the compared items
   */
  const category = computed(() => {
    const firstItem = products.value[0];
    if (!firstItem || !firstItem.categories) {
      return { url: '', name: '' };
    }
    const lastCategory = lastItem(firstItem.categories);
    return { url: lastCategory?.url_path || '', name: lastCategory?.name };
  });

  type Attribute = Record<string, { key: string; title: string; values: string[]; idx: number }>;
  type Attributes = {
    [key: string]: Attribute;
  };

  const attributesRef = {
    dimensions: ['indoor_unit_dimensions_wxhxd_m', 'attr_outdoor_unit_dimensions_wxhxd_', 'net_weight'],
  };

  const attributes = computed(() => {
    const attributes = products.value.reduce(
      (attrs: Attributes, item, itemIdx) => {
        item.attributes?.forEach((attr, idx) => {
          const category =
            Object.keys(attributesRef).find((key: string) =>
              attributesRef[key as keyof typeof attributesRef].includes(attr.key)
            ) ?? 'features';

          // create initial value if it wasn't added to the aggregation before
          if (!attrs[category][attr.key]) {
            attrs[category][attr.key] = { key: attr.key, title: attr.label, values: [], idx };
          }

          // Add the item value to the list
          attrs[category][attr.key].values[itemIdx] = attr.value;
        });
        return attrs;
      },
      { features: {}, dimensions: {} }
    );

    return attributes;
  });

  return {
    products,
    skus,
    isFetching,
    itemsWithAddNodes,
    category,
    shouldShowComparison,
    setShouldShowComparison,
    attributes,
  };
}

export function useIsInComparison(sku: string) {
  return computed(() => {
    return skus.value.some(item => item.sku === sku);
  });
}

export async function compareItem(sku: CompareItem) {
  /*
   * validation
   * if there is no items in the list, we can add the item to the list
   * if there is only one item in the list, then we compare that item's category with the only already in the list item's category ,
   */

  if (skus.value.length === 0) {
    await updateState([...skus.value, sku]);
    return;
  }

  if (intersection(lastItem(skus.value).categoryIds, sku.categoryIds).length > 0) {
    await updateState([...skus.value, sku]);
    return;
  }

  // if the item's category is not in the list, we can't add it to the list
  throw new Error('invalidComparison');
}

export async function removeItem(sku: string) {
  skus.value = skus.value.filter(item => {
    return item.sku !== sku;
  });
  await updateState(skus.value);
}

export async function clearItems() {
  await updateState([]);
}

async function updateState(items: CompareItem[]) {
  const uniqueSku = [...new Set(items)];
  await set(DB_KEY, uniqueSku);
  skus.value = uniqueSku;
}
