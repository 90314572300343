import { render, staticRenderFns } from "./LoginModal.vue?vue&type=template&id=96729684&"
import script from "./LoginModal.vue?vue&type=script&lang=ts&"
export * from "./LoginModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./LoginModal.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppButton: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AppButton.vue').default,ModalDialog: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/ModalDialog.vue').default})
