
















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { mapProductListing } from '~/features/products';

export default defineComponent({
  name: 'ProductsCarousel',
  props: {
    title: {
      type: String,
      default: '',
    },
    // TODO handle href when its ready
    href: {
      type: String,
      default: '',
    },
    products: {
      type: Array as PropType<ReturnType<typeof mapProductListing>[]>,
      default: () => [],
    },
    type: {
      type: String,
      validator: (value: string) => ['default', 'inverted'].includes(value),
      value: 'default',
    },
  },
  setup() {},
});
