import { useMutation } from 'villus';
import { useAlerts } from '../alerts';
import { cartState } from './cart';
import { SendGuestOtpDocument } from '~/graphql/GuestVerification';

export function useSendGuestOtp() {
  const { execute } = useMutation(SendGuestOtpDocument);
  const { error: errorAlert } = useAlerts();

  async function sendGuestOtp(phoneNumber: string) {
    const { error } = await execute({
      phoneNumber,
      cartId: cartState.cartId,
    });

    if (error) {
      if (/OTP Resend limit/.test(error.message)) {
        errorAlert("You've reached the OTP send limit. Please wait 2 mins and retry.");
        return;
      }

      throw new Error(error.message);
    }
  }

  return {
    sendGuestOtp,
  };
}
