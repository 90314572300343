/**
 *
 * under construction
 * @description generic module for handling api exceptions
 * @param {object} error - error object
 *
 */

/**
 *
 * TODO memoize the composition function
 */

import { capitalize, isString } from 'lodash-es';
import { CombinedError } from 'villus';
import errorsObj from '~/exceptions';
import { CartUserInputError } from '~/graphql-types.gen';

type ErrorList = RegExp[];

/**
 * @description generic type for handling api exceptions
 * 'Danger' exceptions are those that should be handled by the user , throws an exception
 * 'Warning' exceptions are those that should be handled by the user , but do not throw an exception
 * 'Green' exceptions are those that shouldn't be throwing an error at all
 */
type ErrorType = 'DANGER' | 'WARNING' | 'GREEN';
type FeaturedErrors = 'cart' | 'customCart' | 'carousels' | 'customer' | 'otpVerification';
type ExceptionEntity = {
  errorList: ErrorList;
  type: ErrorType;
  key?: string;
};

export type ExceptionList = {
  // eslint-disable-next-line no-unused-vars
  [key in FeaturedErrors]: { [key: string]: ExceptionEntity };
};

export const errorsCategories = {
  cart: {
    notActive: 'NOT_ACTIVE',
    insufficientStock: 'INSUFFICIENT_STOCK',
    maxAllowedQuantity: 'MAX_ALLOWED_QUANTITY',
    itemNotSellable: 'ITEM_NOT_SELLABLE',
  },
};

const testException = (error: CombinedError | string, list: ErrorList) => {
  return list.some(item => item.test(isString(error) ? error : error.message));
};

type Exception = {
  level: ErrorType;
  message: string;
};
function forgeException(exception: ExceptionEntity, message: string): Exception {
  return {
    level: exception.type,
    message,
  };
}

/**
 *
 * @description this function is used to handle magento gql errors returned by the api
 * @returns
 */
export function useExceptions(feature: keyof typeof errorsObj) {
  function resolveException(error: CombinedError): Exception {
    const exceptions = errorsObj[feature];
    if (!exceptions) {
      throw new Error(`There is no exceptions for this feature ${feature}`);
    }

    const keys = Object.keys(exceptions);
    const exception = keys.find(key => testException(error, exceptions[key].errorList));
    if (exception) {
      return forgeException(exceptions[exception], exceptions[exception].key ?? exception);
    }
    return {
      level: 'GREEN',
      message: 'NO_EXCEPTION',
    };
  }

  return {
    resolveException,
  };
}

/**
 *
 * @description this function is used to handle the custom user_errors that are returned by the cart api
 * @returns
 */
export function useExceptionsCodes(feature: keyof typeof errorsObj) {
  function resolveException(errors: Array<CartUserInputError>): Exception {
    const exceptions = errorsObj[feature];
    if (!exceptions) {
      throw new Error(`There is no exceptions for this feature ${feature}`);
    }
    const keys = Object.keys(exceptions);
    const targetError = errors[0].code;

    const exception = keys.find(key => testException(targetError, exceptions[key].errorList));

    if (exception) {
      return forgeException(exceptions[exception], exception);
    }
    return {
      level: 'GREEN',
      message: 'NO_EXCEPTION',
    };
  }

  return {
    resolveException,
  };
}

/**
 *
 * @description this function is used to handle  wether the error is returned by the cart api or the magento gql api
 * @returns
 */
export function useCompositeExceptions(feature: keyof typeof errorsObj) {
  const { resolveException: resolveExceptionGql } = useExceptions(feature);
  const { resolveException: resolveExceptionCart } = useExceptionsCodes(
    ('custom' + capitalize(feature)) as keyof typeof errorsObj
  );

  function resolveException(error: CombinedError | Array<CartUserInputError>): Exception[] | [] {
    const results = [];
    if (error && (error as CombinedError)?.name === 'CombinedError') {
      results.push(resolveExceptionGql(error as CombinedError));
    }

    if (Array.isArray(error)) {
      results.push(resolveExceptionCart(error));
    }
    return results;
  }

  return {
    resolveException,
  };
}
