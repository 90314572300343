
































































































































import { defineComponent, computed, PropType, ref } from '@nuxtjs/composition-api';
import { CartItem, useRemoveCartItem, useUpdateCartItem } from '~/features/cart';
import { useAlerts } from '~/features/alerts';
import { useI18n } from '~/features/i18n';
import { CartControl } from '~/graphql-types.gen';

export default defineComponent({
  name: 'CartItem',
  props: {
    id: {
      type: null,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    oldPrice: {
      type: Number,
      default: undefined,
    },
    updatable: {
      type: Boolean,
      default: false,
    },
    stock: {
      type: Number,
      required: true,
    },
    sku: {
      type: String,
      required: true,
    },
    cartControl: {
      type: Object as PropType<CartControl>,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: 'SimpleProduct',
    },
    url: {
      type: String,
      required: false,
      default: '',
    },
    brand: {
      type: String,
      required: false,
      default: '',
    },
    configurationOptions: {
      type: Array as PropType<{ label: string; value: string }[]>,
      default: () => [],
    },
    isInvalidItem: {
      type: Boolean,
      default: true,
    },
    allowChangeQuantity: {
      type: Boolean,
      default: true,
    },
    giftBoxMessage: {
      type: String,
      default: '',
    },
    giftBoxOptionId: {
      type: [String, Number],
      default: '',
    },
    productGift: {
      type: Object as PropType<CartItem>,
      default: null,
    },
  },
  setup({ id, name, configurationOptions }) {
    const { removeItem, isRemoving } = useRemoveCartItem(id);
    const { updateItem } = useUpdateCartItem(id);
    const { error } = useAlerts();
    const { t } = useI18n();
    const openGalleryView = ref(false);

    async function onQuantityUpdate(value: number) {
      try {
        await updateItem(value);
      } catch (err) {
        if (/the requested qty is not available/i.test((err as Error).message)) {
          error(t('qtyUnavailable', [name]) as string);
          return;
        }

        if (
          /Product that you are trying to add is not available/i.test((err as Error).message) ||
          /Some of the products are out of stock/i.test((err as Error).message)
        ) {
          error(t('outOfStockError', [name]) as string);
        }
      }
    }

    return {
      removeItem,
      isRemoving,
      onQuantityUpdate,
      options: computed(() => configurationOptions.map(curr => `${curr.label} ${curr.value}`).join(' | ')),
      openGalleryView,
    };
  },
});
