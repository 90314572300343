









import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useEvent } from '~/features/events';

export default defineComponent({
  setup() {
    const isShown = ref(false);
    useEvent('AUTH_REQUIRED', () => {
      isShown.value = true;
    });

    return {
      isShown,
    };
  },
});
