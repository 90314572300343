


















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ProductNodes } from '~/features/products';
import { Unpacked } from '~/types/utils';

export default defineComponent({
  props: {
    product: Object as PropType<Unpacked<ProductNodes>>,
    default: () => {},
  },
});
