import { ExceptionList } from './features/exceptions';

const errorsObj: ExceptionList = {
  cart: {
    insufficientCartItems: {
      errorList: [/^\[GraphQL\] Some of the products are out of stock\.$/],
      type: 'WARNING',
    },
    notActiveCartErrors: {
      errorList: [
        /\[GraphQL\] Current user does not have an active cart\./i,
        /The current user cannot perform operations on cart "(.*)"/gm,
        /\[GraphQL\] Could not find a cart with ID "(.*)"/gm,
        /\[GraphQL\] The cart isn't active\./i,
      ],
      type: 'DANGER',
    },
    unknownError: {
      errorList: [/^\[GraphQL\] Internal server error$/],
      type: 'DANGER',
    },
    invalidCoupons: {
      errorList: [/The coupon code isn't valid\. Verify the code and try again\./],
      type: 'DANGER',
    },
    inactiveCoupons: {
      errorList: [/\[GraphQL\] Coupon is not active yet, please try again later\./],
      type: 'DANGER',
    },
    invalidCartStore: {
      errorList: [/Can't assign cart to store in/],
      type: 'DANGER',
      key: 'invalidCartStore',
    },
    invalidStore: {
      errorList: [/Unable to place order: Some addresses/],
      type: 'DANGER',
      key: 'invalidStore',
    },
    insufficientStock: {
      errorList: [/Unable to place order: Some of the products are out of stock/],
      type: 'DANGER',
      key: 'insufficientStock',
    },
  },
  carousels: {
    errorFetching: {
      errorList: [],
      type: 'DANGER',
    },
  },
  /**
   * the user errors ones
   */
  customCart: {
    undefinedError: {
      errorList: [/UNDEFINED/],
      type: 'WARNING',
    },
    insufficientStock: {
      errorList: [/INSUFFICIENT_STOCK/],
      type: 'DANGER',
    },
    productNotFound: {
      errorList: [/PRODUCT_NOT_FOUND/],
      type: 'DANGER',
    },
    itemNotSellable: {
      errorList: [/NOT_SALABLE/],
      type: 'DANGER',
    },
  },
  /**
   * user errors
   */
  customer: {
    deleteUserError: {
      errorList: [/ Could not find a user with/],
      type: 'DANGER',
      key: 'deleteUserError',
    },
  },
  otpVerification: {
    wrongOtp: {
      errorList: [/\[GraphQL\] The current customer isn't authorized\./],
      type: 'DANGER',
      key: 'wrongOtp',
    },
  },
};

export default errorsObj;
