







































































































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useComparison, removeItem, clearItems } from '@/features/compare';
import { useRouter } from '~/features/router';

export default defineComponent({
  name: 'ComparisonMenu',
  setup() {
    const { redirect } = useRouter();
    const { products, isFetching } = useComparison();
    const isShown = ref(false);

    function goToComparison() {
      isShown.value = false;
      redirect('/compare');
    }

    return {
      products,
      isFetching,
      removeItem,
      isShown,
      goToComparison,
      clearItems,
    };
  },
});
