


































































































































import { computed, defineComponent, ref, useContext, watch } from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import { useBrands } from '~/features/brands';
import { useMegaMenuCategories } from '~/features/categories';
import { useStoreConfig } from '~/features/storeConfig';
import { Unpacked } from '~/types/utils';

let listener: ReturnType<typeof document.addEventListener> | undefined;

export default defineComponent({
  name: 'CategoryMegaMenu',
  setup() {
    const { route } = useContext();
    const { brands } = useBrands({ pageSize: 200 });
    const { megaMenuCategories: categoryTree } = useMegaMenuCategories();
    const { hasProductDeals } = useStoreConfig();
    const selectedItem = ref<
      { type: 'brand' | 'category'; payload: Unpacked<typeof categoryTree> | undefined } | undefined
    >(undefined);

    watch(selectedItem, value => {
      if (!value && listener) {
        document.removeEventListener('mousemove', listener);
        return;
      }
      if (value)
        listener = document.addEventListener(
          'mouseover',
          debounce(e => {
            if (e.target instanceof Element && document.getElementById('category-menu')?.contains(e.target)) return;
            selectedItem.value = undefined;
          }, 200)
        );
    });

    watch(route, () => {
      selectedItem.value = undefined;
    });

    const offer = computed(() => {
      return (selectedItem.value as any)?.offer;
    });

    return {
      categories: computed(() =>
        categoryTree.value.map(category => {
          // Filter out children that should not be included in menu
          return {
            ...category,
            children: category.children?.filter(child => child.includeInMenu),
          };
        })
      ),
      selectedItem,
      offer,
      brands,
      hasProductDeals,
    };
  },
});
