





















































































































import { computed, ComputedRef, defineComponent, onMounted, ref, watch } from '@nuxtjs/composition-api';
import { ValidationObserver } from 'vee-validate';
import { CombinedError } from 'villus';
import { purify } from '~/directives/purify-html';
import { useAlerts } from '~/features/alerts';
import { useI18n } from '~/features/i18n';
import {
  //   useEditPhoneNumber,
  usePhoneVerification,
  useSendVerificationCode,
  useVerifyOtp,
} from '~/features/otpVerification';
import { handleMoveToNextInput, handleFocusOtpInput, handleInvalidNumberKeys } from '~/utils/dom-events';
import { isEmail, isPhone } from '~/utils/text';
import exceptions from '~/exceptions';
import { useSendGuestOtp } from '~/features/cart/guest-verification';
import { useCartAttributes } from '~/features/cart';

type State = 'ENTER_CODE' | 'UPDATE_IDENTITY';
export default defineComponent({
  /**
   * emits:
   * identity: the value represents the updated value triggered from update phone number ( phone number only )
   */
  name: 'VerifyOtp',
  props: {
    /**
     * @description the identity string could be an email|phone number to be verified
     */
    value: {
      type: String,
      required: true,
    },
    disableChangePhoneNumber: {
      type: Boolean,
      default: false,
    },
    validationOnly: { type: Boolean, default: false },
    mask: { type: String, default: '' },

    alignment: {
      type: String,
      required: false,
      validator: (value: string) => {
        return ['center', 'start'].includes(value);
      },
    },

    guestVerification: {
      type: Boolean,
      default: false,
    },
  },
  components: { ValidationObserver },

  directives: {
    purify,
  },
  setup(props, { emit }) {
    const identity = ref(props.value);
    const state = ref<State>('ENTER_CODE');

    const touchedOtp = ref(false);
    const invalidOtp = ref(false);

    const { reSendVerificationCode, isFetching: isSendingVerificationCode } = useSendVerificationCode(identity.value);
    const { inputs, phoneVerification } = usePhoneVerification();
    const { error, success } = useAlerts();
    const { sendGuestOtp } = useSendGuestOtp();
    const { email: cartEmail } = useCartAttributes();

    const { t } = useI18n();

    const { verifyOtp, isFetching: isVerifyingOtp } = useVerifyOtp(identity);
    let activeInterval: ReturnType<typeof setInterval> | null = null;
    const activeTimer = ref<number | null>(null);

    const type: ComputedRef<'email' | 'phone' | 'something'> = computed(() => {
      if (isEmail(identity.value)) {
        return 'email';
      }

      if (isPhone(identity.value)) {
        return 'phone';
      }

      return 'something';
    });

    function startResendTimer() {
      stopResendTimer();
      activeTimer.value = 120; // seconds
      activeInterval = setInterval(() => {
        if (typeof activeTimer.value !== 'number') {
          return;
        }
        activeTimer.value--;
        if (activeTimer.value <= 0) {
          stopResendTimer();
        }
      }, 1000); // every second
    }

    function stopResendTimer() {
      if (activeInterval) {
        clearInterval(activeInterval);
      }
      activeInterval = null;
      activeTimer.value = null;
    }

    onMounted(() => {
      startResendTimer();
    });

    async function handleResendCode() {
      if (!props.guestVerification) {
        try {
          await reSendVerificationCode(identity.value);
          success('OTP', t('otpCodeSent').toString());
          startResendTimer();
        } catch (err) {
          error(t('resendError').toString(), (err as Error).message.replace('[GraphQL] ', ''));
          stopResendTimer();
        }
      } else {
        try {
          await sendGuestOtp(identity.value);
          success('OTP', t('otpCodeSent').toString());
          startResendTimer();
        } catch (err) {
          if (/OTP Resend limit/.test((err as Error).message)) {
            error("You've reached the OTP send limit. Please wait 2 mins and retry.");
            startResendTimer();
            return;
          }
          stopResendTimer();
        }
      }
    }

    function onEditPhoneNumberSubmit() {
      try {
        throw new Error('Edit phone number is not supported');
        // await editPhoneNumber(props.value, identity.value);
        // emit('input', identity.value);
        // state.value = 'ENTER_CODE';
      } catch (err) {
        if (/\[GraphQL\] A customer exists with the new phone number\./.test((err as CombinedError).message)) {
          error('phone', 'A customer exists with the new phone number.');
        }
      }
    }

    watch(phoneVerification, async value => {
      if (!value) {
        return;
      }

      try {
        await verifyOtp(value, props.validationOnly);
        emit('success', value);
      } catch (e) {
        if ((e as Error)?.message === exceptions.otpVerification.wrongOtp.key) {
          error(type.value, 'Make sure you typed a valid OTP');

          touchedOtp.value = false;
          invalidOtp.value = true;
          return;
        }
        error('error', (e as CombinedError).message);
        touchedOtp.value = false;
        invalidOtp.value = false;
        emit('error');
      }
    });

    return {
      type,
      identity,
      state,
      onEditPhoneNumberSubmit,
      handleResendCode,
      isVerifyingOtp,
      inputs,
      isSendingVerificationCode,
      handleMoveToNextInput,
      handleFocusOtpInput,
      handleInvalidNumberKeys,
      activeTimer,
      touchedOtp,
      invalidOtp,
      cartEmail,
    };
  },
});
