import { computed, reactive, ref, watch } from '@nuxtjs/composition-api';
import debounce from 'lodash/debounce';
import { CombinedError, useMutation } from 'villus';
import { useAuth, useIdentity } from './auth';

// uncomment when its ready form the backend
import { useExceptions } from './exceptions';

import { cartState, useCheckout, useGuestCartVerification } from './cart';
import { useAlerts } from './alerts';
import { useEventBus } from './events';
import { MaybeReactive } from '~/types/utils';
import { EnterOtpDocument, ResendOtpDocument, VerifyCustomerDocument } from '~/graphql/Auth';
import { extractValue } from '~/utils/collections';
import { VerifyGuestOtpDocument } from '~/graphql/GuestVerification';

/**
 * phone number verification input handler
 * @returns
 */
export function usePhoneVerification() {
  const phoneVerification = ref('');
  const inputs = reactive({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
  });

  const notNumeralsRe = /\D/g;

  watch(inputs, inputValue => {
    let input: keyof typeof inputValue;
    for (input in inputValue) {
      inputValue[input] = inputValue[input].replace(notNumeralsRe, '');
      if (inputValue[input].length > 1) {
        inputValue[input] = inputValue[input].substring(0, 1);
      }
    }
  });

  watch(
    inputs,
    debounce(inputValue => {
      if (inputValue.input1 && inputValue.input2 && inputValue.input3 && inputValue.input4)
        phoneVerification.value = `${inputValue.input1}${inputValue.input2}${inputValue.input3}${inputValue.input4}`;
    }, 400)
  );

  return { inputs, phoneVerification };
}

/**
 * Used for verifying the phone number
 * @deprecated
 * @returns
 */
// export function useVerifyPhoneNumber(phoneNumber: MaybeReactive<string>) {
//   const { execute, isFetching } = useMutation(VerifyPhoneNumberDocument);
//   const { identify } = useIdentity();

//   async function verifyPhoneNumber(code: string) {
//     try {
//       const number: string = (phoneNumber as ComputedRef<string>).value
//         ? (phoneNumber as ComputedRef<string>).value
//         : (phoneNumber as string);
//       const { error } = await execute({ code, phoneNumber: number });
//       if (error) throw error;

//       const user = await identify();

//       if (!user) {
//         throw new Error('No user was authenticated with token');
//       }

//       return user;
//     } catch (e) {
//       // eslint-disable-next-line no-console
//       console.log((e as CombinedError).message);
//       throw e;
//     }
//   }

//   return {
//     verifyPhoneNumber,
//     isFetching,
//   };
// }

// used for verify the otp only without logging in the user
export function useVerifyOtp(phoneNumber: MaybeReactive<string>) {
  const { isLoggedIn } = useAuth();
  const { updateVerificationState } = useGuestCartVerification();
  const { submitCheckout } = useCheckout();
  const { error: errorAlert } = useAlerts();
  const { execute: verifyCustomerOtp, isFetching: isFetchingCustomerOtp } = useMutation(EnterOtpDocument);
  const { execute: verifyGuestOtp, isFetching: isFetchingGuestOtp } = useMutation(VerifyGuestOtpDocument);

  const { execute: executeVerifyCustomer, isFetching: isFetchingVerifyCustomer } = useMutation(VerifyCustomerDocument);

  const { resolveException } = useExceptions('otpVerification');
  const { identify } = useIdentity();
  const { emit } = useEventBus();

  async function verifyOtp(code: string, forceLogin: Boolean = false) {
    try {
      if (forceLogin) {
        const { error } = await executeVerifyCustomer({
          otp: code,
          phoneNumber: extractValue(phoneNumber),
        });

        if (error) {
          throw error;
        }
        if (error && resolveException(error)?.level === 'DANGER') throw resolveException(error);
        return await identify();
      }

      if (isLoggedIn.value) {
        const { error } = await verifyCustomerOtp({ otp: code, phoneNumber: extractValue(phoneNumber) });
        if (error) {
          throw error;
        }
        if (error && resolveException(error)?.level === 'DANGER') throw resolveException(error);
      } else {
        const { error } = await verifyGuestOtp({ otp: code, cartId: cartState.cartId });

        if (error) {
          emit('OTP_CLOSED');
          if (/Wrong OTP/.test(error.message)) {
            errorAlert('Incorrect OTP. Please try again.');
            return;
          }
          errorAlert('We were not able to verify your phone number. Please try again.');
        }
        await updateVerificationState();
        submitCheckout();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      throw e;
    }
  }

  return {
    verifyOtp,
    isFetching: computed(
      () => isFetchingVerifyCustomer.value || isFetchingCustomerOtp.value || isFetchingGuestOtp.value
    ),
  };
}

/**
 * Used for resending the verification code
 * @returns
 */
export function useSendVerificationCode(phoneNumber: string) {
  const { execute, isFetching } = useMutation(ResendOtpDocument);

  async function sendVerificationCode() {
    try {
      const { error } = await execute({ phoneNumber });
      if (error) throw error;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log((e as CombinedError).message);
      throw e;
    }
  }

  async function reSendVerificationCode(phoneNumber: string) {
    const { error } = await execute({ phoneNumber });
    if (error) throw error;
  }

  return {
    sendVerificationCode,
    reSendVerificationCode,
    isFetching,
  };
}

/**
 * Editing phone number
 * @deprecated
 * @returns
 */
// export function useEditPhoneNumber() {
//   const { execute, isFetching } = useMutation(EditCustomerPhoneNumberDocument);

//   async function editPhoneNumber(oldPhoneNumber: string, phoneNumber: string) {
//     try {
//       const { error } = await execute({ old: oldPhoneNumber, new: phoneNumber });
//       if (error) throw error;
//     } catch (e) {
//       // eslint-disable-next-line no-console
//       console.log((e as CombinedError).message);
//       throw e;
//     }
//   }

//   return {
//     editPhoneNumber,
//     isFetching,
//   };
// }

/**
 * Requesting reset password
 * @deprecated
 */
// export function useRequestPasswordResetPhone() {
//   const { execute: executeRequestResetPassword, isFetching: isFetchingRequestResetPassword } = useMutation(
//     RequestResetPasswordPhoneDocument
//   );

//   const { execute: executeResetPassword, isFetching: isFetchingResetPassword } = useMutation(ResetPasswordDocument);

//   async function requestPasswordReset(identityString: string) {
//     try {
//       const { data, error } = await executeRequestResetPassword({
//         identityString,
//       });

//       if (error) {
//         throw error;
//       }

//       return data;
//     } catch (err) {
//       // eslint-disable-next-line no-console
//       console.error(err);

//       throw err;
//     }
//   }

//   async function resetPassword(newPassword: string, token: string, phoneNumber: string) {
//     try {
//       const { data, error } = await executeResetPassword({
//         newPassword,
//         token,
//         phoneNumber,
//       });

//       if (error) {
//         throw error;
//       }

//       return data;
//     } catch (err) {
//       // eslint-disable-next-line no-console
//       console.error(err);

//       throw err;
//     }
//   }

//   return {
//     requestPasswordReset,
//     resetPassword,
//     isFetchingRequestResetPassword,
//     isFetchingResetPassword,
//   };
// }
