

























import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      required: true,
    },
    quantity: {
      type: Number,
      default: null,
    },
  },
  setup() {
    const openGalleryView = ref(false);

    return {
      openGalleryView,
    };
  },
});
