


































import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { Options } from '~/features/products/utils';
import { Unpacked } from '~/types/utils';

export default defineComponent({
  name: 'ProductCardThumb',
  props: {
    value: { type: Object as PropType<Unpacked<Options>>, required: false, default: () => {} },
    items: [Array, Object] as PropType<Options | Unpacked<Options>>,
  },
  setup(props) {
    const selectedThumb = ref();

    watch(
      () => props.value,
      newValue => {
        if (Array.isArray(props.items))
          selectedThumb.value = (props.items as Options).findIndex(item => item.slug === newValue.slug);
      }
    );

    return {
      selectedThumb,
      isArray: Array.isArray,
    };
  },
});
