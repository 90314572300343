



















import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useEvent, useEventBus } from '~/features/events';
import { VerificationPayload } from '~/types/verifyIdentity';

/**
 * verify OTP dialog
 * listens for verify_otp event
 * accepts the following
 *  - identity string phone | email
 *  = readonly or not ( incase of phone type )
 *
 * emits
 * success: callback from verify otp component
 * error: callback from from verify otp component
 *
 */
export default defineComponent({
  name: 'VerifyOtpDialog',
  setup() {
    const isOpen = ref(false);
    const identity = ref('');
    const readonly = ref(false);
    const validationOnly = ref(false);
    const mask = ref('');
    const guestVerification = ref(false);

    const { emit } = useEventBus();

    useEvent(
      'ENTER_OTP',
      ({
        identityString,
        readonly: _readonly,
        validationOnly: _validationOnly,
        mask: _mask,
        guestVerification: _guestVerification,
      }: VerificationPayload) => {
        identity.value = identityString;
        readonly.value = _readonly ?? false;
        isOpen.value = true;
        validationOnly.value = !!_validationOnly;
        mask.value = (_mask?.length || Number.MAX_VALUE <= 3 ? `*********${_mask}` : _mask) || '';
        guestVerification.value = Boolean(_guestVerification);
      }
    );

    function handleOtpSuccess(otp: string) {
      emit('OTP_AUTHENTICATED', { otp });
      isOpen.value = false;
    }

    function pauseVerification() {
      emit('OTP_CLOSED');
    }

    return {
      isOpen,
      identity,
      readonly,
      validationOnly,
      handleOtpSuccess,
      mask,
      guestVerification,
      pauseVerification,
    };
  },
});
