import { ref, watch, computed } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { uniqBy } from 'lodash-es';
import { useQuery } from 'villus';
import { mapBundleProductItem } from '../product';
import { cartControlsBuilder, extractConfigurableProductListingOptions, resolveIsNewProduct } from './utils';
import {
  ProductsQueryVariables,
  ProductsDocument,
  DiscountedProductsDocument,
  DiscountedProductsQueryVariables,
  SearchProductsDocument,
  SearchProductsQueryVariables,
} from '~/graphql/Product';
import { MaybeReactive } from '~/types/utils';
import { toNonNullable } from '~/utils/collections';
import { resolveProductPrice, resolveProductStock } from '~/utils/product';
import { BundleItemFragment, ProductCardFragment } from '~/graphql/fragments';
import { CustomAttribute } from '~/graphql-types.gen';

export type ProductNodes = Array<ReturnType<typeof mapProductListing>>;

type ProductsReducer = (old: ProductNodes, curr: ProductNodes) => ProductNodes;

export type ProductListingType = 'default' | 'discounted' | 'search';

const replaceReducer: ProductsReducer = (_, curr) => {
  return curr;
};

export const appendReducer: ProductsReducer = (old, curr) => {
  return uniqBy([...old, ...curr], 'id');
};

interface Options {
  fetchOnMount: boolean;
}

/**
 * A generic products listing function that fetched different product queries according to the type passed
 * @param variables
 * @param type type of the query to be fetched : default or discounted or search
 * @returns
 */
export function useProductsGeneric(
  variables?: MaybeReactive<DiscountedProductsQueryVariables>,
  type: ProductListingType = 'default'
) {
  const products: Ref<ProductNodes> = ref([]);
  const totalCount = ref(0);

  const { data, error, isFetching } = useQuery({
    query: resolveProductTypeQuery(type),
    variables,
    fetchOnMount: true,
  });

  watch(data, value => {
    const fetchedPage = data.value?.connection?.page_info?.current_page;
    const reducer = fetchedPage === 1 || !fetchedPage ? replaceReducer : appendReducer;
    products.value = reducer(products.value, toNonNullable(value?.connection?.nodes).map(mapProductListing as any));

    totalCount.value = value?.connection?.total_count || 0;
  });
  return {
    products,
    totalCount,
    data,
    error,
    isFetching,
  };
}

export function useProducts(variables?: MaybeReactive<ProductsQueryVariables>, opts?: Partial<Options>) {
  const products: Ref<ProductNodes> = ref([]);
  const totalCount = ref(0);

  const { data, error, isFetching } = useQuery({
    query: ProductsDocument,
    variables,
    fetchOnMount: opts?.fetchOnMount ?? true,
  });

  watch(data, value => {
    const fetchedPage = data.value?.connection?.page_info?.current_page;
    const reducer = fetchedPage === 1 || !fetchedPage ? replaceReducer : appendReducer;
    products.value = reducer(products.value, toNonNullable(value?.connection?.nodes).map(mapProductListing as any));
    totalCount.value = value?.connection?.total_count || 0;
  });

  return {
    products,
    totalCount,
    data,
    error,
    isFetching,
  };
}

export function useDiscountedProducts(
  variables?: MaybeReactive<DiscountedProductsQueryVariables>,
  opts?: Partial<Options>
) {
  const products: Ref<ProductNodes> = ref([]);
  const totalCount = ref(0);

  const { data, error, isFetching } = useQuery({
    query: DiscountedProductsDocument,
    variables,
    fetchOnMount: opts?.fetchOnMount ?? true,
  });

  watch(data, value => {
    const fetchedPage = data.value?.connection?.page_info?.current_page;
    const reducer = fetchedPage === 1 || !fetchedPage ? replaceReducer : appendReducer;
    products.value = reducer(products.value, toNonNullable(value?.connection?.nodes).map(mapProductListing as any));
    totalCount.value = value?.connection?.total_count || 0;
  });

  return {
    products,
    totalCount,
    data,
    error,
    isFetching,
  };
}

// handles the search feature
export function useSearchProducts(variables?: MaybeReactive<SearchProductsQueryVariables>, opts?: Partial<Options>) {
  const products: Ref<any> = ref([]);
  const categoriesResult: Ref<any> = ref([]);

  const { data, error, isFetching, execute } = useQuery({
    query: SearchProductsDocument,
    variables,
    fetchOnMount: opts?.fetchOnMount ?? true,
  });

  watch(data, value => {
    products.value = value?.search?.catalogsearch_fulltext?.items?.map(mapProductListing as any).slice(0, 5);
    categoriesResult.value = value?.search?.magento_catalog_category?.items;
  });

  return {
    products,
    isFetching,
    error,
    execute,
    categoriesResult,
  };
}

const productVariantsResolver = (apiProduct: ProductCardFragment) => {
  return apiProduct.__typename === 'ConfigurableProduct'
    ? apiProduct.variants?.map(variant => ({
        ...variant?.product,
        price: (variant?.product && resolveProductPrice(variant?.product)) || 0,
        stock: variant?.product?.only_x_left_in_stock ?? 0,
        priceBefore: (function () {
          const currentPrice = (variant?.product && resolveProductPrice(variant?.product)) || 0;

          const priceBefore = variant?.product?.price_range.maximum_price?.regular_price.value ?? currentPrice;

          return currentPrice < priceBefore ? priceBefore : undefined;
        })(),
      }))
    : [];
};

const productBundleResolver = (apiProduct: ProductCardFragment) => {
  return apiProduct.__typename === 'BundleProduct'
    ? toNonNullable((apiProduct.items as Array<BundleItemFragment>).map(mapBundleProductItem))
    : [];
};

/**
 * Maps a product listing to props compatible with the card component.
 */
export function mapProductListing(apiProduct: ProductCardFragment) {
  const cartControl = cartControlsBuilder(apiProduct);

  const productsVariants = productVariantsResolver(apiProduct);

  const bundleItems = productBundleResolver(apiProduct);

  const price = computed(() => {
    if (['GroupedProduct', 'ConfigurableProduct'].includes(apiProduct.__typename)) {
      // get the minimum price of all options
      return productsVariants?.reduce(
        (accu, option) => (option.price < accu ? option.price : accu),
        Number.MAX_SAFE_INTEGER
      );
    }

    if (cartControl.pricePerStep && cartControl.pricePerStep !== cartControl.currentPrice) {
      return cartControl.pricePerStep;
    }

    return cartControl.currentPrice;
  });

  const priceBefore = computed(() => {
    if (['GroupedProduct', 'ConfigurableProduct'].includes(apiProduct.__typename)) {
      // get the minimum price of all options
      return productsVariants?.reduce<{
        price: number;
        priceBefore: number;
      }>(
        (accu, option) =>
          option?.price < accu?.price
            ? {
                price: option.price || 0,
                priceBefore: option.priceBefore || 0,
              }
            : accu,
        {
          price: Number.MAX_SAFE_INTEGER,
          priceBefore: 0,
        }
      )?.priceBefore;
    }

    if (cartControl.beforePricePerStep && cartControl.beforePricePerStep !== cartControl.oldPrice) {
      return cartControl.pricePerStep;
    }
    return cartControl.oldPrice;
  });

  return {
    id: apiProduct.id || '',
    name: apiProduct.name || '',
    sku: apiProduct.sku || '',
    isNew: resolveIsNewProduct(apiProduct),
    isBestseller: apiProduct.is_best_seller || false,
    customLabel: apiProduct.attributes?.find(attribute => attribute?.key === 'custom_label_1')?.value || '',
    stock: resolveProductStock(apiProduct) || 0,
    price: price.value,
    priceBefore: priceBefore.value ?? undefined,
    slug: apiProduct.url_key || '',
    thumb: {
      src: apiProduct.thumbnail?.url || '',
      alt: apiProduct.thumbnail?.label || apiProduct.name || '',
    },
    categories: apiProduct.categories,
    attributes: (apiProduct as any).attributes as CustomAttribute[] | undefined,
    type: apiProduct.__typename,
    cartControl: cartControl.cartControl,
    options: extractConfigurableProductListingOptions(apiProduct),
    productsVariants,
    brand: apiProduct.brand,
    hasGift:
      apiProduct.potential_gift_promotions?.length &&
      apiProduct.potential_gift_promotions?.[0]?.gifts?.items?.[0]?.only_x_left_in_stock,
    bundleItems,
  };
}

export function resolveProductTypeQuery(type: ProductListingType) {
  if (type === 'discounted') {
    return DiscountedProductsDocument;
  }
  if (type === 'default') {
    return ProductsDocument;
  }

  return ProductsDocument;
}
