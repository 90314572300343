import { render, staticRenderFns } from "./CategoryMegaMenu.vue?vue&type=template&id=c7fd0be8&scoped=true&"
import script from "./CategoryMegaMenu.vue?vue&type=script&lang=ts&"
export * from "./CategoryMegaMenu.vue?vue&type=script&lang=ts&"
import style0 from "./CategoryMegaMenu.vue?vue&type=style&index=0&id=c7fd0be8&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7fd0be8",
  null
  
)

/* custom blocks */
import block0 from "./CategoryMegaMenu.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AppLink.js').default,OfferCard: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/OfferCard.vue').default})
