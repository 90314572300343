import { render, staticRenderFns } from "./default.vue?vue&type=template&id=8adea7de&scoped=true&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&id=8adea7de&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8adea7de",
  null
  
)

/* custom blocks */
import block0 from "./default.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DownloadAppBanner: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/DownloadAppBanner.vue').default,TheHeader: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/TheHeader.vue').default,SideMenu: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/SideMenu.vue').default,CategoryDialog: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/CategoryDialog.vue').default,AccountDialog: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AccountDialog.vue').default,AsideCart: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AsideCart.vue').default,AlertGroup: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AlertGroup.vue').default,LoginModal: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/LoginModal.vue').default,ComparisonMenu: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/ComparisonMenu.vue').default,ComposeCarouselViewer: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/compose/CarouselViewer.vue').default,TheFooter: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/TheFooter.vue').default,AppStickyBar: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AppStickyBar.vue').default,AppButton: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AppButton.vue').default,ModalDialog: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/ModalDialog.vue').default})
