import { isEmpty } from 'lodash-es';
import { CombinedError } from 'villus';

type HttpHeaders = Record<string, string | undefined | string[]>;
export function makeHttpJSONRequest(
  body: Record<string, any> | null,
  opts: {
    headers?: Record<string, any>;
    method?: 'get' | 'post' | 'patch' | 'put' | 'delete';
    url?: string | null;
  } = {
    method: 'post',
    headers: {},
    url: null,
  }
) {
  if (!process.env.API_URL) throw new Error('API_URL in env is not provided ');

  const fetchRequest = {
    headers: {
      'content-type': 'application/json',
      ...(opts.headers ? cleanHeaders(opts.headers) : undefined),
    },
    method: opts.method,
    ...(body ? { body: JSON.stringify(body) } : undefined),
  };

  if (isEmpty(body) && opts.method === 'get') {
    delete fetchRequest.body;
  }

  return fetch(opts?.url || process.env.API_URL || '', fetchRequest);
}

export async function parseResponse(response: Response) {
  let json;
  const responseData = {
    ok: response.ok,
    statusText: response.statusText,
    status: response.status,
    headers: response.headers,
  };

  try {
    json = await response.json();
  } catch (err) {
    return {
      ...responseData,
      statusText: (err as CombinedError).message,
      body: null,
    };
  }

  return {
    ...responseData,
    body: json,
  };
}

function cleanHeaders(headers: HttpHeaders) {
  const allowedHeaders = ['content-type', 'authorization', 'store', 'content-language'];
  const cleanedHeaders: HttpHeaders = {};

  Object.keys(headers).forEach(header => {
    if (header && allowedHeaders.includes(header)) {
      cleanedHeaders[header] = headers[header];
    }
  });

  return cleanedHeaders;
}

export function mapStoreLocale(locale: 'en' | 'ar') {
  switch (locale) {
    case 'en':
      return 'en';
    case 'ar':
      return 'ar';
    default:
      return 'en';
  }
}
