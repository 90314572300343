




















import { defineComponent, toRefs } from '@nuxtjs/composition-api';
import { useQuantityControl } from '~/features/products/quantity-control';

/**
 *
 * @description this one controls the amount of items to be added to the cart through the qty controls
 *
 * @case when the product min amount on cart is reached - toggle the remove from cart button
 */
export default defineComponent({
  name: 'ProductCardQtyControl',
  props: {
    sku: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    stock: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { max, min, stock } = toRefs(props);
    const { count, items, requestChangeCount, maxQuantity } = useQuantityControl(props.sku, max, min, stock);

    return { count, requestChangeCount, items, maxQuantity };
  },
});
