






import { defineComponent } from '@nuxtjs/composition-api';
import { useAlerts } from '~/features/alerts';

export default defineComponent({
  setup() {
    const { alerts, dismiss } = useAlerts();

    return {
      alerts,
      dismiss,
    };
  },
});
