




import { computed, defineComponent, onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';
import { intervalToDuration, isBefore } from 'date-fns';
import { useCartRefresh } from '~/features/cart';
import { useI18n } from '~/features/i18n';
/**
 * A component for handling any countdown timer
 */
export default defineComponent({
  props: {
    endDate: {
      type: String,
      required: true,
    },
  },
  setup({ endDate }) {
    let interval: ReturnType<typeof setInterval>;
    const { t } = useI18n();
    const { refresh } = useCartRefresh();

    const timeLeft = ref<ReturnType<typeof intervalToDuration> | null>(null);
    const hours = computed(() => {
      if (!timeLeft.value?.hours) {
        return '00';
      }

      return timeLeft.value?.hours < 10 ? `0${timeLeft.value?.hours}` : timeLeft.value?.hours;
    });
    const minutes = computed(() => {
      if (!timeLeft.value?.minutes) {
        return '00';
      }

      return timeLeft.value?.minutes < 10 ? `0${timeLeft.value?.minutes}` : timeLeft.value?.minutes;
    });
    const seconds = computed(() => {
      if (!timeLeft.value?.seconds) {
        return '00';
      }

      return timeLeft.value?.seconds < 10 ? `0${timeLeft.value?.seconds}` : timeLeft.value?.seconds;
    });

    // Concatenate days, hours, minutes, seconds and skip day if its 0
    const countdownToShow = computed(() => {
      let countdown = '';

      if (timeLeft.value?.days) {
        countdown = `${timeLeft.value.days}${t('d')} `;
      }
      countdown += `${hours.value}:${minutes.value}:${seconds.value}`;

      return countdown;
    });

    onMounted(() => {
      calculateTimer();
    });

    function calculateTimer() {
      // Make sure it has not expired
      if (isBefore(new Date(), new Date(endDate))) {
        interval = setInterval(() => {
          timeLeft.value = intervalToDuration({
            start: new Date(),
            end: new Date(endDate),
          });
          timerCheck();
        }, 1000);
      }
    }

    function timerCheck() {
      if (!isBefore(new Date(), new Date(endDate))) {
        refresh();
        if (interval) {
          clearInterval(interval);
        }
        timeLeft.value = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }
    }

    onUnmounted(() => {
      if (interval) {
        clearInterval(interval);
      }
    });

    return {
      countdownToShow,
    };
  },
});
