import { computed, Ref } from '@nuxtjs/composition-api';
import { useAuth } from './auth';
import { sleep } from '~/utils/async';

export function useSsrCoreApp(isFetchingDeps?: Ref<boolean>[] | []) {
  const { isPendingAuth } = useAuth();

  const isServer = computed(() => process.server);
  const isPageLoading = computed(() => {
    return isServer.value || isPendingAuth.value || isFetchingDeps?.some(dep => dep.value);
  });

  /**
   * @description Helper function to make sure that the page is fully loaded to make sure there is no extra requests or unneeded business while fetching data with wrong credentials
   */
  async function waitForPageToBeLoaded(): Promise<void> {
    /**
     * Finish until page loaded
     */

    console.log(
      isServer.value,
      isPendingAuth.value,
      isFetchingDeps?.some(dep => dep.value)
    );
    while (isPageLoading.value) {
      await sleep(200);
    }
  }

  return {
    isPageLoading,
    isServer,
    waitForPageToBeLoaded,
  };
}
