














import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { debounce } from 'throttle-debounce';
import { useAddToWishlist, useIsInWishlist, useRemoveFromWishlist } from '~/features/wishlist';
import { useEventBus } from '~/features/events';
import { TRACKING_EVENTS } from '~/features/trackingHandlers';
import { ProductData } from '~/features/product';
import { useAuth } from '~/features/auth';
import { useRouter } from '~/features/router';
import { useAlerts } from '~/features/alerts';
import { useI18n } from '~/features/i18n';

export default defineComponent({
  name: 'FavoriteButton',
  props: {
    sku: {
      type: String,
      required: true,
    },
    product: {
      type: Object as PropType<ProductData>,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const isFavorited = ref(false);
    const { emit: emitEvent } = useEventBus();
    const { user } = useAuth();
    const { redirect } = useRouter();
    const { warn } = useAlerts();
    const { t } = useI18n();

    const isInWishlist = useIsInWishlist(props.sku);

    watch(
      isInWishlist,
      value => {
        isFavorited.value = value;
      },
      {
        immediate: true,
      }
    );

    const { addToWishlist } = useAddToWishlist(props.sku);
    const { removeFromWishlist } = useRemoveFromWishlist(props.sku);
    // Ensures we don't call too many requests
    const commitToggle = debounce(500, () => {
      if (isFavorited.value) {
        props.product && emitEvent(TRACKING_EVENTS.ADD_TO_WISH_LIST, props.product);

        return addToWishlist();
      }

      return removeFromWishlist().then(() => {
        emit('unfavorite');
      });
    });

    function toggleWishlist() {
      if (!user?.value) {
        warn(t('loginFirst').toString());
        redirect('/login');
        return;
      }

      isFavorited.value = !isFavorited.value;
      commitToggle();
    }

    return {
      isFavorited,
      toggleWishlist,
    };
  },
});
