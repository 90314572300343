


















































































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useCartAttributes } from '@/features/cart';
import { useEventBus } from '~/features/events';
import { useAuth } from '~/features/auth';
import { useRouter } from '~/features/router';

export default defineComponent({
  name: 'TheHeader',
  setup() {
    const { route } = useRouter();
    const { isLoggedIn, user } = useAuth();
    const { count, spinTheWheelData } = useCartAttributes();
    const { emit } = useEventBus();

    function openAsideCart() {
      emit('TOGGLE_ASIDE_CART', true);
    }

    function openSideMenu() {
      emit('TOGGLE_SIDE_MENU', true);
    }

    // Check if on cart page
    const isCartPage = computed(() => route.value.path.includes('cart'));

    return {
      isLoggedIn,
      user,
      cartCount: count,
      openAsideCart,
      openSideMenu,
      spinTheWheelData,
      isCartPage,
    };
  },
});
