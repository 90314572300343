import { inject, Ref, ssrRef, computed, watch, onMounted, ref } from '@nuxtjs/composition-api';
import { clone, isEmpty } from 'lodash-es';
import { ProductData } from './product';
import { PRODUCT_CONTEXT } from '~/utils/provides';
import { PartialPick } from '~/utils/collections';
import { isWeightedItem } from '~/utils/product';

type BundleProductContext = {
  parentSku?: string;
};

export type ProductContext = ProductData & BundleProductContext;
const product = ssrRef<ProductContext>(
  {
    id: 0,
    type: 'SimpleProduct',
    variants: undefined,
    isNew: false,
    stock: 0,
    price: 0,
    bundleItems: undefined,
    groupItems: undefined,
    cartControl: {
      max: 0,
      min: 0,
      step: 1,
      unit: '',
    },
    short_description: {
      html: '',
    },
    attributes: [],
    special_to_date: undefined,
    special_from_date: undefined,
    configurationOptions: undefined,
    dynamicPrice: false,
    mainCategory: '',
    media_gallery: [],
    price_range: undefined,
    priceBefore: 0,
    rating_summary: 0,
    review_count: 0,
    special_price: undefined,
    description: {
      html: '',
    },
    name: '',
    categories: [],
    sku: '',
    thumbnail: {},
    parentSku: '',
    image: {},
    brand: {
      name: '',
    },
    meta_description: '',
    meta_keywords: '',
    meta_title: '',
    url_key: '',
    configurableGiftBoxOptionId: null,
    simpleGiftBoxOptionId: null,
    heroImage: null,
    hasGift: false,
    gift: null,
    giftCardPriceOptions: undefined,
    giftCardType: undefined,
    giftCardFromName: '',
    giftCardToName: '',
    giftCardToEmail: '',
    giftCardMessage: '',
  },
  'product-context'
);

const giftWrappingComment = ref('');
function useInitProductContext() {
  const productCtx = inject(PRODUCT_CONTEXT) as Ref<ProductData>;

  watch(
    productCtx,
    newCtx => {
      product.value = clone(newCtx);
    },
    {
      immediate: true,
    }
  );

  onMounted(() => {
    if (!product.value.id) product.value = clone(productCtx.value);
  });
}
export function useProductContext(
  opts = {
    init: false,
  }
) {
  const productCtx = inject(PRODUCT_CONTEXT) as Ref<ProductData>;

  const _productCtx = computed(() => productCtx.value);

  if (opts.init) useInitProductContext();
  /**
   * @description use this function in order to modify/add/append product options to the base product model
   */
  function appendProductValues(
    attributes: PartialPick<
      typeof product['value'],
      | 'name'
      | 'price'
      | 'parentSku'
      | 'sku'
      | 'thumbnail'
      | 'description'
      | 'media_gallery'
      | 'stock'
      | 'type'
      | 'priceBefore'
      | 'hasGift'
      | 'gift'
      | 'giftCardToEmail'
      | 'giftCardToName'
      | 'giftCardFromName'
      | 'giftCardMessage'
    >
  ) {
    if (attributes === null || isEmpty(attributes)) {
      return;
    }

    product.value = {
      ...product.value,
      ...attributes,
    };
  }

  return {
    product: computed(() => product.value),
    productCtx: _productCtx,
    appendProductValues,
    isWeightedItem: computed(() => isWeightedItem(product.value)),
  };
}

export function useProductGiftWrappingContext() {
  return {
    giftWrappingComment,
  };
}
