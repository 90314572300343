import { ComputedRef, Ref } from '@nuxtjs/composition-api';
import { MaybeReactive } from '~/types/utils';

export function lastItem<T>(arr: T[]) {
  return arr[arr.length - 1];
}

export function toNonNullable<T>(arr: Array<T | null | undefined> | null | undefined): T[] {
  if (!arr) {
    return [];
  }

  return arr.filter(x => !!x) as T[];
}

/**
 * A typed version of Object.keys
 */
export function keysOf<TRecord extends Record<string, any>>(record: TRecord | undefined | null): (keyof TRecord)[] {
  if (!record) {
    return [];
  }

  return Object.keys(record);
}

export function sortByPosition<T extends null | undefined | { position?: number | null }>(dir: 'ASC' | 'DESC') {
  if (dir === 'ASC') {
    return (a: T, b: T) => (a?.position || 0) - (b?.position || 0);
  }

  return (a: T, b: T) => (b?.position || 0) - (a?.position || 0);
}
export const sortByPositionAsc = sortByPosition('ASC');

export function toNonDuplicateArrayBy<T>(arr: Array<T | null | undefined> | null | undefined, key: keyof T): T[] {
  if (!arr) {
    return [];
  }
  return [...new Map(toNonNullable(arr).map(item => [item[key], item])).values()] as T[];
}

export type PartialRecord<K extends keyof any, T> = {
  // eslint-disable-next-line no-unused-vars
  [P in K]?: T;
};

/**
 * From T, pick a set of properties whose keys are in the union K
 */
export type PartialPick<T, K extends keyof T> = {
  [P in K]?: T[P];
};

export function clamp(min: number, max: number, value: number) {
  return Math.min(Math.max(value, min), max);
}

export function extractValue<T>(input: MaybeReactive<T>): T {
  if (typeof (input as Ref<T> | ComputedRef<T>).value !== 'undefined') {
    return (input as Ref<T> | ComputedRef<T>).value;
  }
  return input as T;
}
