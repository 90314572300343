var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"provider",staticClass:"TextInput",class:{
    'mt-4': _vm.label,
    'pb-4': _vm.rules,
  },attrs:{"tag":"div","rules":_vm.rules,"name":_vm.name,"vid":_vm.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var required = ref.required;
return [(_vm.type !== 'textarea')?_c('input',_vm._b({staticClass:"w-full h-full py-4 leading-normal bg-transparent",class:{
      'bg-gray-100 placeholder-gray-500': _vm.filled && _vm.dark && !_vm.showError,
      'bg-gray-850': _vm.filled && !_vm.dark && !_vm.showError,
      'bg-red-50': _vm.filled && _vm.showError,
      'border border-red-700': _vm.border && _vm.showError,
      'border border-primary-600': _vm.border && !_vm.showError,
      'rounded-md': _vm.rounded,
      'has-value': _vm.hasValue,
      'pl-10': _vm.icon,
      'px-3': !_vm.icon,
      'border-b': _vm.underline,
      'font-bold': !_vm.light,
      'pl-11': _vm.showError,
      'border border-primary-800': _vm.fullBorder,
    },attrs:{"id":_vm.id,"name":_vm.name,"type":_vm.showPassword ? 'text' : _vm.type,"placeholder":_vm.placeholder,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.innerValue},on:{"focus":function (e) {
        _vm.$emit('focus', e);
      },"input":function (e) {
        _vm.$emit('input', e.target.value);
      },"blur":function($event){return _vm.checkShowError()}}},'input',_vm.$attrs,false)):_vm._e(),_vm._v(" "),(_vm.type === 'textarea')?_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],staticClass:"w-full h-full py-4 leading-normal bg-transparent",class:{
      'bg-gray-100 placeholder-gray-500': _vm.filled && _vm.dark && !_vm.showError,
      'bg-gray-850': _vm.filled && !_vm.dark && !_vm.showError,
      'bg-red-50': _vm.filled && _vm.showError,
      'border border-red-700': _vm.border && _vm.showError,
      'border border-primary-600': _vm.border && !_vm.showError,
      'rounded-md': _vm.rounded,
      'has-value': _vm.hasValue,
      'pl-10': _vm.icon,
      'px-3': !_vm.icon,
      'border-b': _vm.underline,
      'font-bold': !_vm.light,
      'pl-11': _vm.showError,
      'border border-primary-800': _vm.fullBorder,
    },attrs:{"id":_vm.id,"name":_vm.name,"type":_vm.showPassword ? 'text' : _vm.type,"placeholder":_vm.placeholder},domProps:{"value":(_vm.innerValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.innerValue=$event.target.value}}},'textarea',_vm.$attrs,false)):_vm._e(),_vm._v(" "),(!_vm.showError && _vm.type === 'password')?_c('button',{staticClass:"w-5 h-5 absolute right-5 z-10",attrs:{"type":"button"}},[_c('svg-icon',{staticClass:"text-primary-700",attrs:{"name":_vm.showPassword ? 'eye' : 'eye-hide',"width":"21","height":"21"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}})],1):_vm._e(),_vm._v(" "),(_vm.showError)?_c('svg-icon',{staticClass:"w-4 h-4 absolute left-4 z-20 text-red-700",attrs:{"name":'error',"width":"21","height":"21"}}):_vm._e(),_vm._v(" "),_c('label',{staticClass:"TextInput__label uppercase",attrs:{"for":_vm.id}},[_vm._t("label"),_vm._v(" "),(!_vm.$slots.label)?[_c('span',[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('span',{staticClass:"text-gray-500"},[_vm._v(_vm._s(required ? ' *' : _vm.isOptional ? _vm.$t('optional') : ''))])]:_vm._e()],2),_vm._v(" "),(_vm.showError)?_c('span',{staticClass:"TextInput__error"},[_vm._v(_vm._s(_vm.errors[0]))]):(_vm.$slots.hint)?_c('div',{staticClass:"TextInput__hint"},[_vm._t("hint")],2):_vm._e(),_vm._v(" "),(_vm.icon)?_c('svg-icon',{staticClass:"absolute left-3 z-10",attrs:{"name":_vm.icon,"width":"21","height":"21"}}):_vm._e(),_vm._v(" "),_vm._t("after")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }