





























































































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { useAuth } from '~/features/auth';
import { useCartAttributes } from '~/features/cart';
import { useEventBus, useEvent } from '~/features/events';
import { useRouter } from '~/features/router';

export default defineComponent({
  setup() {
    const { user } = useAuth();
    const { count } = useCartAttributes();
    const { emit } = useEventBus();
    const { redirect } = useRouter();
    const categoriesTabActive = ref(false);
    const accountTabActive = ref(false);
    const noOtherTabsActive = computed(() => !categoriesTabActive.value && !accountTabActive.value);

    useEvent('DEACTIVATE_ACCOUNT_ICON', () => {
      accountTabActive.value = false;
    });

    useEvent('DEACTIVATE_CATEGORY_ICON', () => {
      categoriesTabActive.value = false;
    });

    function toggleCategoryMenu() {
      categoriesTabActive.value = !categoriesTabActive.value;
      emit('TOGGLE_CATEGORY_MENU');
    }

    function toAccount() {
      if (user.value) {
        accountTabActive.value = !accountTabActive.value;
        emit('TOGGLE_ACCOUNT_MENU');
        return;
      }

      redirect('/login');
    }
    return {
      count,
      user,
      toggleCategoryMenu,
      toAccount,
      categoriesTabActive,
      accountTabActive,
      noOtherTabsActive,
    };
  },
});
