



























import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ModalDialog',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
      validator: (value: string) => ['default', 'small', 'large', 'full', 'full-bottom'].includes(value),
    },
  },
  setup(_, { emit }) {
    function close() {
      emit('input', false);
    }

    return {
      close,
    };
  },
});
