import type { InjectionKey, Ref } from '@nuxtjs/composition-api';
import { CountryCodeEnum } from '~/graphql-types.gen';
import { Customer } from '~/features/auth';

export const AUTH_USER: InjectionKey<Ref<Customer>> = Symbol('auth.user');

export const AUTH_IS_LOGGED_IN: InjectionKey<Ref<boolean>> = Symbol('auth.isLoggedIn');

export const CONFIG_STORE_CURRENCY: InjectionKey<Ref<string>> = Symbol('store_currency');

export const CONFIG_STORE_COUNTRY: InjectionKey<CountryCodeEnum> = Symbol('store_country');

export const PRODUCT_CONTEXT = 'product';

export const CATEGORY_CONTEXT = 'category';
