import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _486416fa = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _28ac3c35 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _3b892256 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _0eccc198 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _2f3c842a = () => interopDefault(import('../pages/account/addresses/index.vue' /* webpackChunkName: "pages/account/addresses/index" */))
const _79e07e37 = () => interopDefault(import('../pages/account/edit/index.vue' /* webpackChunkName: "pages/account/edit/index" */))
const _58df40d2 = () => interopDefault(import('../pages/account/orders/index.vue' /* webpackChunkName: "pages/account/orders/index" */))
const _234ce7ef = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _3e4213f9 = () => interopDefault(import('../pages/account/addresses/new.vue' /* webpackChunkName: "pages/account/addresses/new" */))
const _28652c01 = () => interopDefault(import('../pages/account/edit/email.vue' /* webpackChunkName: "pages/account/edit/email" */))
const _04839cc6 = () => interopDefault(import('../pages/account/edit/password.vue' /* webpackChunkName: "pages/account/edit/password" */))
const _c2e9608c = () => interopDefault(import('../pages/account/orders/_id.vue' /* webpackChunkName: "pages/account/orders/_id" */))
const _67027536 = () => interopDefault(import('../pages/account/addresses/_id/edit.vue' /* webpackChunkName: "pages/account/addresses/_id/edit" */))
const _4737f152 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _7d0a495d = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "pages/careers" */))
const _35c2d728 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _7df3ee4e = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _ae6d59d8 = () => interopDefault(import('../pages/checkout/guest-shipping.vue' /* webpackChunkName: "pages/checkout/guest-shipping" */))
const _74237ce7 = () => interopDefault(import('../pages/checkout/pay.vue' /* webpackChunkName: "pages/checkout/pay" */))
const _1648cbff = () => interopDefault(import('../pages/checkout/shipping.vue' /* webpackChunkName: "pages/checkout/shipping" */))
const _d3f1f4e6 = () => interopDefault(import('../pages/compare.vue' /* webpackChunkName: "pages/compare" */))
const _9dea5170 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _291c1d2f = () => interopDefault(import('../pages/deals.vue' /* webpackChunkName: "pages/deals" */))
const _6cb6c9b4 = () => interopDefault(import('../pages/dior-expertise.vue' /* webpackChunkName: "pages/dior-expertise" */))
const _e7ab95a0 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _6ba36da5 = () => interopDefault(import('../pages/faqs.vue' /* webpackChunkName: "pages/faqs" */))
const _47e2f078 = () => interopDefault(import('../pages/gift-card-balance.vue' /* webpackChunkName: "pages/gift-card-balance" */))
const _2d3c3edf = () => interopDefault(import('../pages/health-check.vue' /* webpackChunkName: "pages/health-check" */))
const _120bba11 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1b2f0ca5 = () => interopDefault(import('../pages/loyalty-program.vue' /* webpackChunkName: "pages/loyalty-program" */))
const _69bfc630 = () => interopDefault(import('../pages/makeup-academy.vue' /* webpackChunkName: "pages/makeup-academy" */))
const _1483df9f = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _7e0a4daa = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _6fdda1b0 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _36b222cb = () => interopDefault(import('../pages/search/_name.vue' /* webpackChunkName: "pages/search/_name" */))
const _396bcd3c = () => interopDefault(import('../pages/spin-the-wheel.vue' /* webpackChunkName: "pages/spin-the-wheel" */))
const _5b956a8c = () => interopDefault(import('../pages/stores.vue' /* webpackChunkName: "pages/stores" */))
const _584ae37c = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _648671d9 = () => interopDefault(import('../pages/unsubscribe-maillist.vue' /* webpackChunkName: "pages/unsubscribe-maillist" */))
const _35bc5585 = () => interopDefault(import('../pages/auth/recover.vue' /* webpackChunkName: "pages/auth/recover" */))
const _43479766 = () => interopDefault(import('../pages/orders/error.vue' /* webpackChunkName: "pages/orders/error" */))
const _1bed96ad = () => interopDefault(import('../pages/abandonedcart/subscriber/unsubscribe.vue' /* webpackChunkName: "pages/abandonedcart/subscriber/unsubscribe" */))
const _4aa533e2 = () => interopDefault(import('../pages/brands/_slug.vue' /* webpackChunkName: "pages/brands/_slug" */))
const _7739a1e0 = () => interopDefault(import('../pages/guest-order/_id/success.vue' /* webpackChunkName: "pages/guest-order/_id/success" */))
const _5f87ec12 = () => interopDefault(import('../pages/orders/_id/create-review.vue' /* webpackChunkName: "pages/orders/_id/create-review" */))
const _2033ea8c = () => interopDefault(import('../pages/orders/_id/success.vue' /* webpackChunkName: "pages/orders/_id/success" */))
const _441fc37e = () => interopDefault(import('../pages/_/create-review.vue' /* webpackChunkName: "pages/_/create-review" */))
const _ce59656c = () => interopDefault(import('../pages/_/index.vue' /* webpackChunkName: "pages/_/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ar",
    component: _486416fa,
    name: "index___ar"
  }, {
    path: "/en",
    component: _486416fa,
    name: "index___en"
  }, {
    path: "/ar/about",
    component: _28ac3c35,
    name: "about___ar"
  }, {
    path: "/ar/account",
    component: _3b892256,
    children: [{
      path: "",
      component: _0eccc198,
      name: "account___ar"
    }, {
      path: "addresses",
      component: _2f3c842a,
      name: "account-addresses___ar"
    }, {
      path: "edit",
      component: _79e07e37,
      name: "account-edit___ar"
    }, {
      path: "orders",
      component: _58df40d2,
      name: "account-orders___ar"
    }, {
      path: "wishlist",
      component: _234ce7ef,
      name: "account-wishlist___ar"
    }, {
      path: "addresses/new",
      component: _3e4213f9,
      name: "account-addresses-new___ar"
    }, {
      path: "edit/email",
      component: _28652c01,
      name: "account-edit-email___ar"
    }, {
      path: "edit/password",
      component: _04839cc6,
      name: "account-edit-password___ar"
    }, {
      path: "orders/:id",
      component: _c2e9608c,
      name: "account-orders-id___ar"
    }, {
      path: "addresses/:id/edit",
      component: _67027536,
      name: "account-addresses-id-edit___ar"
    }]
  }, {
    path: "/ar/brands",
    component: _4737f152,
    name: "brands___ar"
  }, {
    path: "/ar/careers",
    component: _7d0a495d,
    name: "careers___ar"
  }, {
    path: "/ar/cart",
    component: _35c2d728,
    name: "cart___ar"
  }, {
    path: "/ar/checkout",
    component: _7df3ee4e,
    name: "checkout___ar",
    children: [{
      path: "guest-shipping",
      component: _ae6d59d8,
      name: "checkout-guest-shipping___ar"
    }, {
      path: "pay",
      component: _74237ce7,
      name: "checkout-pay___ar"
    }, {
      path: "shipping",
      component: _1648cbff,
      name: "checkout-shipping___ar"
    }]
  }, {
    path: "/ar/compare",
    component: _d3f1f4e6,
    name: "compare___ar"
  }, {
    path: "/ar/contact",
    component: _9dea5170,
    name: "contact___ar"
  }, {
    path: "/ar/deals",
    component: _291c1d2f,
    name: "deals___ar"
  }, {
    path: "/ar/dior-expertise",
    component: _6cb6c9b4,
    name: "dior-expertise___ar"
  }, {
    path: "/ar/error",
    component: _e7ab95a0,
    name: "error___ar"
  }, {
    path: "/ar/faqs",
    component: _6ba36da5,
    name: "faqs___ar"
  }, {
    path: "/ar/gift-card-balance",
    component: _47e2f078,
    name: "gift-card-balance___ar"
  }, {
    path: "/ar/health-check",
    component: _2d3c3edf,
    name: "health-check___ar"
  }, {
    path: "/ar/login",
    component: _120bba11,
    name: "login___ar"
  }, {
    path: "/ar/loyalty-program",
    component: _1b2f0ca5,
    name: "loyalty-program___ar"
  }, {
    path: "/ar/makeup-academy",
    component: _69bfc630,
    name: "makeup-academy___ar"
  }, {
    path: "/ar/privacy-policy",
    component: _1483df9f,
    name: "privacy-policy___ar"
  }, {
    path: "/ar/register",
    component: _7e0a4daa,
    name: "register___ar"
  }, {
    path: "/ar/search",
    component: _6fdda1b0,
    name: "search___ar",
    children: [{
      path: ":name?",
      component: _36b222cb,
      name: "search-name___ar"
    }]
  }, {
    path: "/ar/spin-the-wheel",
    component: _396bcd3c,
    name: "spin-the-wheel___ar"
  }, {
    path: "/ar/stores",
    component: _5b956a8c,
    name: "stores___ar"
  }, {
    path: "/ar/terms-and-conditions",
    component: _584ae37c,
    name: "terms-and-conditions___ar"
  }, {
    path: "/ar/unsubscribe-maillist",
    component: _648671d9,
    name: "unsubscribe-maillist___ar"
  }, {
    path: "/en/about",
    component: _28ac3c35,
    name: "about___en"
  }, {
    path: "/en/account",
    component: _3b892256,
    children: [{
      path: "",
      component: _0eccc198,
      name: "account___en"
    }, {
      path: "addresses",
      component: _2f3c842a,
      name: "account-addresses___en"
    }, {
      path: "edit",
      component: _79e07e37,
      name: "account-edit___en"
    }, {
      path: "orders",
      component: _58df40d2,
      name: "account-orders___en"
    }, {
      path: "wishlist",
      component: _234ce7ef,
      name: "account-wishlist___en"
    }, {
      path: "addresses/new",
      component: _3e4213f9,
      name: "account-addresses-new___en"
    }, {
      path: "edit/email",
      component: _28652c01,
      name: "account-edit-email___en"
    }, {
      path: "edit/password",
      component: _04839cc6,
      name: "account-edit-password___en"
    }, {
      path: "orders/:id",
      component: _c2e9608c,
      name: "account-orders-id___en"
    }, {
      path: "addresses/:id/edit",
      component: _67027536,
      name: "account-addresses-id-edit___en"
    }]
  }, {
    path: "/en/brands",
    component: _4737f152,
    name: "brands___en"
  }, {
    path: "/en/careers",
    component: _7d0a495d,
    name: "careers___en"
  }, {
    path: "/en/cart",
    component: _35c2d728,
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _7df3ee4e,
    name: "checkout___en",
    children: [{
      path: "guest-shipping",
      component: _ae6d59d8,
      name: "checkout-guest-shipping___en"
    }, {
      path: "pay",
      component: _74237ce7,
      name: "checkout-pay___en"
    }, {
      path: "shipping",
      component: _1648cbff,
      name: "checkout-shipping___en"
    }]
  }, {
    path: "/en/compare",
    component: _d3f1f4e6,
    name: "compare___en"
  }, {
    path: "/en/contact",
    component: _9dea5170,
    name: "contact___en"
  }, {
    path: "/en/deals",
    component: _291c1d2f,
    name: "deals___en"
  }, {
    path: "/en/dior-expertise",
    component: _6cb6c9b4,
    name: "dior-expertise___en"
  }, {
    path: "/en/error",
    component: _e7ab95a0,
    name: "error___en"
  }, {
    path: "/en/faqs",
    component: _6ba36da5,
    name: "faqs___en"
  }, {
    path: "/en/gift-card-balance",
    component: _47e2f078,
    name: "gift-card-balance___en"
  }, {
    path: "/en/health-check",
    component: _2d3c3edf,
    name: "health-check___en"
  }, {
    path: "/en/login",
    component: _120bba11,
    name: "login___en"
  }, {
    path: "/en/loyalty-program",
    component: _1b2f0ca5,
    name: "loyalty-program___en"
  }, {
    path: "/en/makeup-academy",
    component: _69bfc630,
    name: "makeup-academy___en"
  }, {
    path: "/en/privacy-policy",
    component: _1483df9f,
    name: "privacy-policy___en"
  }, {
    path: "/en/register",
    component: _7e0a4daa,
    name: "register___en"
  }, {
    path: "/en/search",
    component: _6fdda1b0,
    name: "search___en",
    children: [{
      path: ":name?",
      component: _36b222cb,
      name: "search-name___en"
    }]
  }, {
    path: "/en/spin-the-wheel",
    component: _396bcd3c,
    name: "spin-the-wheel___en"
  }, {
    path: "/en/stores",
    component: _5b956a8c,
    name: "stores___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _584ae37c,
    name: "terms-and-conditions___en"
  }, {
    path: "/en/unsubscribe-maillist",
    component: _648671d9,
    name: "unsubscribe-maillist___en"
  }, {
    path: "/ar/auth/recover",
    component: _35bc5585,
    name: "auth-recover___ar"
  }, {
    path: "/ar/orders/error",
    component: _43479766,
    name: "orders-error___ar"
  }, {
    path: "/en/auth/recover",
    component: _35bc5585,
    name: "auth-recover___en"
  }, {
    path: "/en/orders/error",
    component: _43479766,
    name: "orders-error___en"
  }, {
    path: "/ar/abandonedcart/subscriber/unsubscribe",
    component: _1bed96ad,
    name: "abandonedcart-subscriber-unsubscribe___ar"
  }, {
    path: "/en/abandonedcart/subscriber/unsubscribe",
    component: _1bed96ad,
    name: "abandonedcart-subscriber-unsubscribe___en"
  }, {
    path: "/ar/brands/:slug",
    component: _4aa533e2,
    name: "brands-slug___ar"
  }, {
    path: "/en/brands/:slug",
    component: _4aa533e2,
    name: "brands-slug___en"
  }, {
    path: "/ar/guest-order/:id?/success",
    component: _7739a1e0,
    name: "guest-order-id-success___ar"
  }, {
    path: "/ar/orders/:id?/create-review",
    component: _5f87ec12,
    name: "orders-id-create-review___ar"
  }, {
    path: "/ar/orders/:id?/success",
    component: _2033ea8c,
    name: "orders-id-success___ar"
  }, {
    path: "/en/guest-order/:id?/success",
    component: _7739a1e0,
    name: "guest-order-id-success___en"
  }, {
    path: "/en/orders/:id?/create-review",
    component: _5f87ec12,
    name: "orders-id-create-review___en"
  }, {
    path: "/en/orders/:id?/success",
    component: _2033ea8c,
    name: "orders-id-success___en"
  }, {
    path: "/ar/*/create-review",
    component: _441fc37e,
    name: "all-create-review___ar"
  }, {
    path: "/en/*/create-review",
    component: _441fc37e,
    name: "all-create-review___en"
  }, {
    path: "/en/*",
    component: _ce59656c,
    name: "all___en"
  }, {
    path: "/ar/*",
    component: _ce59656c,
    name: "all___ar"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
