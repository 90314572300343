import { render, staticRenderFns } from "./GiftWrapping.vue?vue&type=template&id=8f7b1496&"
import script from "./GiftWrapping.vue?vue&type=script&lang=ts&"
export * from "./GiftWrapping.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./GiftWrapping.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CheckboxInput: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/CheckboxInput.vue').default,TextInput: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/TextInput.vue').default,AppButton: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AppButton.vue').default,VariantModalDialog: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/VariantModalDialog.vue').default})
