import { render, staticRenderFns } from "./GiftWrapping.vue?vue&type=template&id=76a10604&"
import script from "./GiftWrapping.vue?vue&type=script&lang=ts&"
export * from "./GiftWrapping.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Spinner: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/Spinner.vue').default,GiftWrapping: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/GiftWrapping.vue').default})
