


















import { computed, defineComponent, nextTick, onMounted, PropType, ref, toRef, watch } from '@nuxtjs/composition-api';
// eslint-disable-next-line import/no-named-as-default
import type Swiper from 'swiper';

export default defineComponent({
  name: 'ThumbSlider',
  props: {
    items: {
      type: Array as PropType<{ value: string; [key: string]: string }[]>,
      required: true,
    },
    slidesPerViewMobile: {
      type: Number,
      default: 1,
    },
    containImages: {
      type: Boolean,
      default: false,
    },
    containVerticalImages: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'primary',
    },
    selectedItem: {
      type: Object,
      default: null,
    },
    selectedIndex: { type: Number, default: 0 },
  },
  setup(props, { emit }) {
    const swiperRefThumb = ref(null);
    const isSliderLoaded = ref(false);
    const items = toRef(props, 'items');
    const thumbSwiperInstance = ref<Swiper>();

    onMounted(async () => {
      const { default: Swiper, Navigation } = await import('swiper');
      Swiper.use([Navigation]);
      // @ts-ignore
      await import('swiper/swiper-bundle.css');

      isSliderLoaded.value = true;
      await nextTick();

      thumbSwiperInstance.value = new Swiper(swiperRefThumb.value || '', {
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlidesBounds: true,
        centerInsufficientSlides: true,
        centeredSlides: true,
        slidesOffsetBefore: 0,
        touchMoveStopPropagation: true,
      });

      watch(items, () => {
        thumbSwiperInstance.value?.updateSlides();
      });

      watch(
        selectedItemIndex,
        value => {
          if (value && value !== -1) {
            thumbSwiperInstance.value?.slideTo(value);
          }
        },
        { immediate: true }
      );

      watch(
        () => props.selectedIndex,
        value => {
          if (value && value !== -1) {
            thumbSwiperInstance.value?.slideTo(value);
          }
        },
        { immediate: true }
      );

      thumbSwiperInstance.value.on('slideChange', function (slide) {
        emit('swipe', slide.realIndex);
      });
    });

    function nextSlide() {
      thumbSwiperInstance.value?.slideNext();
    }

    function prevSlide() {
      thumbSwiperInstance.value?.slidePrev();
    }

    const selectedItemIndex = computed(() => {
      if (!items.value || !props.selectedItem) return -1;
      return items.value?.findIndex(item => item?.value === props.selectedItem.value);
    });

    return {
      swiperRefThumb,
      isSliderLoaded,
      thumbSwiperInstance,
      nextSlide,
      prevSlide,
      selectedItemIndex,
    };
  },
});
