function isInput(el: any): el is HTMLInputElement {
  if (el instanceof HTMLElement) {
    return el?.tagName.toLowerCase() === 'input';
  }
  return false;
}

/**
 *  move to next input when current input at its max length
 * @param e The keyboard event interface passed down by the dom event API
 */
export function handleMoveToNextInput(e: KeyboardEvent) {
  const target: HTMLElement | null = e.target as HTMLElement;
  const maxLength = parseInt(target.dataset.maxLength || '1', 10);
  const myLength = isInput(target) ? target.value.length : maxLength;
  if (myLength >= maxLength) {
    let next = target;
    while ((next = next.nextElementSibling as any)) {
      if (next == null) break;
      if (isInput(next)) {
        next.focus();
        next.click();
        break;
      }
    }
  }
  // Move to previous field if empty (user pressed backspace)
  else if (myLength === 0 && ['Backspace', 'Delete'].includes(e.key)) {
    let previous = target;
    while ((previous = previous.previousElementSibling as any)) {
      if (previous == null) break;
      if (isInput(previous)) {
        previous.focus();
        previous.click();
        break;
      }
    }
  }
}

/**
 *  Selects all the value inside the input on focus
 * @param e The focus event interface passed down by the dom event API
 */
export function handleFocusOtpInput(e: FocusEvent) {
  const target: HTMLElement | null = e.target as HTMLElement;
  if (isInput(target)) {
    target.select();
  }
}

/**
 *  Prevents invalid number input inputs
 * @param e The keyboard event interface passed down by the dom event API
 */
export function handleInvalidNumberKeys(e: KeyboardEvent) {
  const INVALID_KEYS = ['e', '+', '-'];
  if (INVALID_KEYS.includes(e.key)) {
    e.preventDefault();
  }
}
