import { render, staticRenderFns } from "./CarouselViewer.vue?vue&type=template&id=4c89cb2e&"
import script from "./CarouselViewer.vue?vue&type=script&lang=ts&"
export * from "./CarouselViewer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/SectionTitle.vue').default,ProductsCarousel: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/ProductsCarousel.vue').default})
