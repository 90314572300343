import type { ClientPlugin } from 'villus';
import { clone } from 'lodash-es';
import { normalizeQuery } from './villus';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exclude = [
  // Skips all mutations
  /mutation/,
  /query Identity/,
  /query GetPhoneNumberByEmail/,
  /PaymentMethods/,
  /Cart/i,
  /SelectedAddress/,
  /Order/i,
  /Customer/i,
  /Wishlist/i,
  /StockSource/i,
  /StockSources/i,
  /deliveryDateAndTime/i,
  /NextDeliveryInterval/i,
  /CustomerCart/i,
];

const varnishExclude = [
  /FlashSale/,
  /RecentlyViewed/,
  /BestSellers/,
  /CustomerCartCrossSellProducts/,
  /GuestCartCrossSellProducts/,
  /OrderByIncrementId/,
];

export function varnishPlugin(locale: string): ClientPlugin {
  return function varnishPlugin({ opContext, operation }) {
    const normalizedQuery = normalizeQuery(operation.query)?.replace(/\n|\r/g, '') || '';

    if (operation.type === 'query' && !varnishExclude.some(regex => regex.test(normalizedQuery))) {
      const headers = clone(opContext.headers);

      const store = locale === 'ar' ? 'ar' : 'en';

      if (!exclude.some(r => r.test(normalizedQuery))) delete headers.authorization;
      opContext.method = 'GET';
      opContext.url =
        opContext.url +
        `?query=${encodeURIComponent(normalizedQuery)}&variables=${encodeURIComponent(
          JSON.stringify(operation.variables)?.replace(/\n|\r/g, '')
        )}&store=${store}`;

      opContext.headers = {
        ...headers,
        varnish: (!exclude.some(r => r.test(normalizedQuery))).toString(),
      };

      opContext.body = undefined;
    }
  };
}
