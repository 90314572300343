import { getCurrentInstance, useContext } from '@nuxtjs/composition-api';
import useCookies from './cookies';
import { useI18n } from './i18n';

interface LocationOpts {
  query: Record<string, any>;
  params: Record<string, any>;
  replace: boolean;
  onComplete: () => any;
}
/* Used for list of routes to be excluded when back to last page */
const exclude = [/auth/, /login/, /register/];

export function useRouter() {
  const { redirect: redir, route, query } = useContext();
  const vm = getCurrentInstance()?.proxy;
  const { getLocale } = useI18n();
  const { setCookie, cookies } = useCookies();

  async function redirect(path: string, opts: Partial<LocationOpts> = {}) {
    const localizedPath = prefixPath(path, getLocale());
    if (!vm || !vm.$router) {
      redir(localizedPath);

      // Simulates route navigation delay
      return new Promise(resolve => setTimeout(resolve, 100));
    }

    try {
      await vm.$router.push({ path: localizedPath, ...opts }, opts.onComplete);
    } catch (err: any) {
      if (err.name !== 'NavigationDuplicated') {
        throw err;
      }
    }
  }

  if (vm && vm.$router) {
    vm?.$router.afterEach((_, from) => {
      !exclude.some(r => r.test(from.path)) &&
        !process.server &&
        (() => {
          localStorage.setItem('LS_ROUTE_KEY', from.path);
          setCookie('referrer', from.path);
        })();
    });
  }

  function back() {
    if (cookies.referrer && !cookies.referrer.includes('login')) {
      return redir(cookies.referrer);
    }
    if (!vm || !vm.$router) {
      return redir(prefixPath('/', getLocale()));
    }

    const lastRouteName = localStorage.getItem('LS_ROUTE_KEY');
    if (lastRouteName) {
      return redir(lastRouteName);
    }

    return redir(prefixPath('/', getLocale()));
  }

  const lastRoute = process.browser ? localStorage.getItem('LS_ROUTE_KEY') : '';

  return {
    redirect,
    route,
    query,
    back,
    lastRoute,
  };
}

function prefixPath(path: string, locale: string): string {
  if (!path.startsWith(`/${locale}/`)) {
    return `/${locale}${path}`;
  }

  return path;
}
