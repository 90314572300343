









































































































import { defineComponent, useContext, watch } from '@nuxtjs/composition-api';
import { useAuth } from '~/features/auth';
import { useCartAttributes } from '~/features/cart';
import { useDropdown } from '~/features/dropdown';
import { useEvent } from '~/features/events';
import { useRouter } from '~/features/router';

export default defineComponent({
  name: 'AsideCart',
  setup() {
    const { items, count, total } = useCartAttributes();
    const { isOpen, toggle } = useDropdown();
    const { route } = useContext();
    const { user } = useAuth();
    const { redirect } = useRouter();

    useEvent('TOGGLE_ASIDE_CART', (value: boolean) => {
      isOpen.value = value;
    });

    function close() {
      toggle(false);
    }

    watch(
      () => route.value,
      () => {
        close();
      }
    );

    function toCheckout() {
      if (user.value) {
        redirect('/checkout/shipping');
        return;
      }

      redirect('/login?checkout=1');
    }

    return {
      isOpen,
      count,
      items,
      close,
      total,
      toCheckout,
    };
  },
});
