export function copyToClipboard(str: string) {
  const el = document.createElement('textarea'); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selection = document.getSelection() || { rangeCount: 0 };

  const selected =
    selection.rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection()?.getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection()?.removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection()?.addRange(selected); // Restore the original selection
  }
}

/**
 * Replaces placeholder values in a string with their actual values
 * @example interpolate('Hello, my name is {name}', { name: 'John Doe' });
 */
export function interpolate(templateStr: string, placeholders: Record<string, any>) {
  return templateStr.replace(/{([^}]+)}/g, function (_, p) {
    return p in placeholders ? placeholders[p] : `{${p}}`;
  });
}

/**
 * Generates a unique hash number based on string contents
 */
export function hash(x: string) {
  let h, i, l;
  for (h = 5381 | 0, i = 0, l = x.length | 0; i < l; i++) {
    h = (h << 5) + h + x.charCodeAt(i);
  }

  return h >>> 0;
}

export function isEmail(str: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
}

export function isOnlyNumbers(value: string) {
  return !!value.match(/[0-9\u06F0-\u06F9]/g);
}

export function isProperPhoneLength(value: string) {
  const sanitized = value.replace(/[- ]/g, '');
  return sanitized.length === 11;
}

export function isPhoneProper(value: string) {
  if (!value) {
    return false;
  }
  const ACCEPTED_PREFIX = ['010', '011', '012', '015'];
  const sanitized = value.replace(/[- ]/g, '');
  const numericalRe = /^\d+$/g;
  const firstThree = sanitized.substring(0, 3);
  return !!sanitized.match(numericalRe) && ACCEPTED_PREFIX.includes(firstThree);
}

export function isPhone(value: string) {
  return isPhoneProper(value) && isOnlyNumbers(value) && isProperPhoneLength(value);
}

export function isColor(color: string) {
  return !!color.startsWith('#');
}
