import { render, staticRenderFns } from "./MenuCard.vue?vue&type=template&id=76e894cb&"
import script from "./MenuCard.vue?vue&type=script&lang=ts&"
export * from "./MenuCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/builds/robustastudio/mazaya/mazaya-customer-app/components/AppLink.js').default})
