





















import { Organization, SearchAction, WebSite } from 'schema-dts';
import { defineComponent, useContext } from '@nuxtjs/composition-api';
import TheAlerts from '@/components/TheAlerts.vue';
import { useJsonLd, useSeoLinks } from '~/features/seo';
import { useI18nCookie } from '~/features/i18n';

export default defineComponent({
  setup() {
    useSeoLinks();
    useI18nCookie();

    const { $config } = useContext();
    const url = $config.appURL;

    // https://developers.google.com/search/docs/data-types/sitelinks-searchbox
    const websiteWithSearchAction: WebSite = {
      '@type': 'WebSite',
      url,
      potentialAction: {
        '@type': 'SearchAction',
        target: `${url}/search?q={search_term_string}`,
        'query-input': 'required name=search_term_string',
      } as SearchAction,
    };

    const org: Organization = {
      '@id': url,
      '@type': 'Organization',
      name: 'Mazaya',
      url,
      logo: `${url}/img/logo.png`,
    };

    useJsonLd({
      '@context': {
        '@vocab': 'http://schema.org/',
      },
      '@graph': [websiteWithSearchAction, org],
    });
  },
  components: {
    TheAlerts,
  },
  head: {},
});
