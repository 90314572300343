//
//
//
//
//
//
//
//
//
//
//
//

export default {
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    navigate: {
      type: Function,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isExactActive: {
      type: Boolean,
      default: false,
    },
    exact: {
      type: Boolean,
      default: false,
    },
  },
};
