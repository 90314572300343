


























































import { defineComponent, inject, ref, useContext, watch, watchEffect } from '@nuxtjs/composition-api';
import { useMegaMenuCategories } from '~/features/categories';
import { useEvent, useEventBus } from '~/features/events';
import { AUTH_IS_LOGGED_IN } from '~/utils/provides';

export default defineComponent({
  name: 'SideMenu',

  setup() {
    const isOpen = ref(false);
    useEvent('TOGGLE_SIDE_MENU', value => {
      isOpen.value = value === undefined ? !isOpen.value : value;
    });
    const { megaMenuCategories: categoryTree } = useMegaMenuCategories();
    const selectedCategoryId = ref<string>('');
    const isLoggedIn = inject(AUTH_IS_LOGGED_IN);
    const { route } = useContext();

    const { emit } = useEventBus();

    watchEffect(() => {
      if (process.server) {
        return;
      }

      window.document.body.classList.toggle('overflow-hidden', isOpen.value);
    });

    function close() {
      isOpen.value = false;
    }

    // close menu when navigating
    watch(route, () => {
      close();
    });

    function loggingOut() {
      emit('LOGOUT_CONFIRM');
    }

    return {
      loggingOut,
      close,
      selectedCategoryId,
      categoryTree,
      isLoggedIn,
      isOpen,
    };
  },
});
